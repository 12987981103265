import React, { Component } from 'react';
import './ResultLayout.css';
import { Header, Footer,  FullPageLoader } from '../../Core';
import CustomizedSnackbars from '../../redux/snackbar';
import axios from 'axios';
import history from '../../History';
import { removeLocalStorage } from '../../Utils/localStorage';
import { showLoader, hideLoader } from '../../redux/ducks/application';
import store from '../../redux/configureStore';

const { dispatch } = store;

axios.interceptors.request.use(
    (conf) => {
        if(!conf.headers['Authorization']){
            
        }
        dispatch(showLoader());
        return conf;
    },
    (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(response => {
    dispatch(hideLoader());
    return response;
}, error => {
    dispatch(hideLoader());
    if (!error.response) {
    }
    else if (error.response.status === 401) {
        removeLocalStorage();
        history.push('/')
    }
    else if (error.response.status === 500) {
        removeLocalStorage();
        history.push('/')
    }
    return Promise.reject(error);
});

export default class ResultLayout extends Component {
    render() {
        return (
            <div className="searchresult-header">
                <Header parm={this.props} />
                <div>
                    <CustomizedSnackbars/>
                    <FullPageLoader />
                    {this.props.children}
                </div>
                <Footer parm={this.props}/>
            </div>
        );
    }
}