
import React, { Component } from 'react';
import { Row, Col, Button, Container } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { CONFIG } from '../../../Utils/config';
import axios from 'axios';
import { connect } from 'react-redux';
import { setSnackbar } from '../../../redux/ducks/snackbarReducer';
import { REGIONS } from '../../../Utils/utils';
import './ResetPassword.css';
import Helmet from '../../../Utils/Helmet';

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            error: '',
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event, errors, values) {
        if (errors.length === 0) {

            var url = "Account/resetpassword/";
            var data = {
                // "Token": decodeURIComponent(this.state.passwordResetToken),
                "Token": this.state.passwordResetToken.slice(15),
                'Password': values.Password,
                'ConfirmPassword': values.ConfirmPassword,
            }
            axios.post(CONFIG.API_URL + url, data)
                .then(response => {
                    if (response.status === 200) {
                        var data = response.data;
                        var regionid = data.RegionId;
                        if (regionid === REGIONS.UK) {
                            window.location.href = CONFIG.LSS_UK + "/logins/" + data.SecureID;
                        } else if (regionid === REGIONS.US) {
                            window.location.href = CONFIG.LSS_US + "/logins/" + data.SecureID;
                        }
                    }
                })
                .catch(err => {
                    this.setState({ isLoading: false });
                    if (err.response != null && err.response.status === 400) {
                        const error = err.response.data;
                        this.props.dispatch(setSnackbar(true, "error", error));
                    }
                    else {
                        const error = "Something went wrong.";
                        this.props.dispatch(setSnackbar(true, "error", error));
                    }

                });
        }
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(this.props.location.search);
        var passwordResetToken = urlParams.get('token') ? urlParams.get('token') : '';
        if (passwordResetToken === '') {
            passwordResetToken = this.props.location.pathname;
            // passwordResetToken = this.props.match.params.PasswordResetToken;
        }
        this.setState({
            passwordResetToken
        });
    }

    render() {
        // document.title = CONFIG.PAGE_TITLE + 'Reset Password';

        return (
            <div className="resetpassword-cs">
                <Helmet
                    title='Reset Password'
                    addPostfixTitle={true}
                    keywords="Forgot Password, Reset Password, Password"
                />
                <Container>
                    <Row>
                        <Col sm={5}>
                            <Col sm={12} className="margin-b25">
                                <h1 className="page-title">Reset password</h1>
                                <Row className="padding-t-0">
                                    <Col sm={12}>
                                        <div className="title-separator1"></div>
                                    </Col>
                                </Row>
                            </Col>
                            <div >
                                <AvForm onSubmit={this.handleSubmit.bind(this)} ref={c => (this.form = c)} id="LSS_password_reset_form">
                                    <AvField name="Password" label="" placeholder="Password" type="password" validate={{
                                        required: { value: true, errorMessage: 'Password is required' },
                                        pattern: { value: '^(.{0,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{4,})|(.{1,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{3,})|(.{2,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{2,})|(.{3,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{1,})|(.{4,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{0,})$', errorMessage: 'Passwords are case-sensitive, must be between 6 to 25 characters and contain at least 1 letter and 1 number or special character.' },
                                        minLength: { value: 6, errorMessage: 'Your Password must be 6 characters' },
                                        maxLength: { value: 25, errorMessage: 'Your Password must be 25 characters' }
                                    }} />
                                    <AvField name="ConfirmPassword" label="" placeholder="Confirm Password" type="password" validate={{
                                        required: { value: true, errorMessage: 'Confirm Password is required' },
                                        match: { value: 'Password', errorMessage: 'Mismatch Confirm Password' }
                                    }} />
                                    <Button >Reset Password</Button>
                                </AvForm>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>


        );
    }
}
export default connect()(ResetPassword);