import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { CONFIG } from '../../../Utils/config';
import axios from 'axios';
import vendor_no from '../../../Assets/Img/ventor-list.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEnvelope, faGlobe, faPhoneAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { VENDORTYPE, normalizeInput } from '../../../Utils/utils';
import './Profile.css';
import Helmet from '../../../Utils/Helmet';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vendorId: 0,
            vendorImages: [],
            adsList: [],
            permaLink: ''
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.match.params.permalink) {
            var permaLink = this.props.match.params.permalink;
            this.setState({ permaLink });
            this.getVendorProfile(permaLink);
        }

        // this.getAdsList();
    }

    getAdsList() {
        this.setState({ isLoading: true, error: '' });

        axios.get(CONFIG.API_URL + 'vendor/ads/' + CONFIG.REGION_ID)
            .then(response => {
                if (response.status === 200) {
                    this.setState({ isLoading: false, adsList: response.data });
                }
            })
            .catch(err => {
                if (err.response != null && err.response.status === 400) {
                    const error = err.response.data;
                    this.setState({ error, isLoading: false });
                }
                else {
                    const error = "Something went wrong.";
                    this.setState({ error, isLoading: false });
                }
            });
    }

    getVendorProfile(permaLink) {
        axios.get(CONFIG.API_URL + 'vendor/permalink/' + permaLink + "/" + CONFIG.REGION_ID).then(response => {
            if (response.status === 200) {
                var vendor = response.data;

                var vendorImages = vendor.VendorImages ? Array.from(vendor.VendorImages.split(",")) : [];

                this.setState({ vendor, vendorImages });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    render() {
        const { vendor } = this.state;

        if (vendor) {
            // document.title = CONFIG.PAGE_TITLE + vendor.CompanyName;
            if (vendor.VendorTypeID === VENDORTYPE.Premium) {
                return (
                    <div className={this.props.match.path.indexOf("user") > -1 ? "profile-condition" : "profile-vendordirectory"} >
                        <Helmet
                            title={vendor.CompanyName}
                            addPostfixTitle={true}
                            keywords="Vendor Company, CompanyName, Search, Category"
                        />
                        <Container>
                            <Row>
                                <Col md={12}>
                                    <div className="heading">

                                        <h3>{vendor.CompanyName}</h3>
                                        <div className="leftColor-separator margin-b-15"></div>
                                    </div>
                                    <Row>
                                        <Col sm={7}>
                                            {vendor.Logo ? <div className="profile-logo">
                                                <img className="width-100 height-100" src={'/VendorLogos/' + vendor.Logo} onError={(e) => { e.target.onerror = null; e.target.src = vendor_no }} alt="Vendor" />
                                            </div>
                                                : ''}
                                            {vendor.Description ? <p className="profile-description" dangerouslySetInnerHTML={{ __html: vendor.Description }} /> : <p className="profile-description"></p>}
                                        </Col>
                                        <Col md={5} className="padding-left-remove">
                                            <div className="contact-box">
                                                <h6>Company Info</h6>
                                                <div className="list">
                                                    <div className="list-item padding-bottom-remove">
                                                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                                                        <div className="list-content">
                                                            <p>{vendor.Address1}</p>
                                                            {vendor.Address2 ? <p>{vendor.Address2}</p> : ''}
                                                            <p>{vendor.City} {vendor.StateID ? ", " + vendor.StateID : ' '} {vendor.ZipCode}</p>
                                                            {vendor.CountryCode ? <p>{vendor.CountryCode}</p> : ''}
                                                        </div>
                                                    </div>
                                                    {vendor.Phone ?
                                                        <React.Fragment>
                                                            <div className="list-item">
                                                                <FontAwesomeIcon icon={faPhoneAlt} />
                                                                <div className="list-content">
                                                                    <a href={"tel:" + normalizeInput(vendor.Phone)}>{normalizeInput(vendor.Phone)}</a>
                                                                </div>
                                                            </div>
                                                        </React.Fragment> : ''}
                                                    {vendor.Email ?
                                                        <React.Fragment>
                                                            <div className="list-item">
                                                                <FontAwesomeIcon icon={faEnvelope} />
                                                                <div className="list-content">
                                                                    <a href={"mailto:" + vendor.Email}>{vendor.Email}</a>
                                                                </div>
                                                            </div>
                                                        </React.Fragment> : ''}
                                                    {vendor.Website ?
                                                        <React.Fragment>
                                                            <div className="list-item padding-bottom-remove">
                                                                <FontAwesomeIcon icon={faGlobe} />
                                                                <div className="list-content">
                                                                    <a href={vendor.Website} target="_blank" rel="noopener noreferrer">{vendor.Website}</a>
                                                                </div>
                                                            </div>
                                                        </React.Fragment> : ''}
                                                </div>
                                                <div>
                                                </div>
                                                {vendor.ContactName || vendor.ContactHeadShot || vendor.ContactPhone || vendor.ContactEmail ?
                                                    <div>
                                                        <hr />
                                                        <h6>Contact Info</h6>
                                                        <div className="list">
                                                            <div className="list-item padding-bottom-remove">
                                                                {vendor.ContactName ?
                                                                    <React.Fragment>
                                                                        <FontAwesomeIcon icon={faUser} />
                                                                        <div className="list-content">
                                                                            <p className="font-14">{vendor.ContactName}</p>
                                                                        </div>
                                                                    </React.Fragment>
                                                                    : ''}
                                                            </div>
                                                            {vendor.ContactHeadShot ?
                                                                <React.Fragment>
                                                                    <div className="contact-title">{vendor.ContactHeadShot}</div>
                                                                </React.Fragment> : ''}
                                                            {vendor.ContactPhone ?
                                                                <React.Fragment>
                                                                    <div className="list-item">
                                                                        <FontAwesomeIcon icon={faPhoneAlt} />
                                                                        <div className="list-content">
                                                                            <a href={"tel:" + normalizeInput(vendor.ContactPhone)}>{normalizeInput(vendor.ContactPhone)}</a>
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment> : ''}
                                                            {vendor.ContactEmail ?
                                                                <React.Fragment>
                                                                    <div className="list-item">
                                                                        <FontAwesomeIcon icon={faEnvelope} />
                                                                        <div className="list-content">
                                                                            <a href={"mailto:" + vendor.ContactEmail}>{vendor.ContactEmail}</a>
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment> : ''}

                                                        </div>
                                                        <div>
                                                        </div>
                                                    </div>
                                                    : ''}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                );
            }
            else {
                return (
                    <div className={this.props.match.path.indexOf("user") > -1 ? "profile-condition" : "profile-vendordirectory"} >
                        <Helmet
                            title={vendor.CompanyName}
                            addPostfixTitle={true}
                            keywords="Vendor Company, CompanyName, Search, Category"
                        />
                        <Container>
                            <Row >
                                <Col md={12}>
                                    <div className="heading">

                                        <h3>{vendor.CompanyName}</h3>
                                        <div className="leftColor-separator margin-b-15"></div>
                                    </div>
                                    <Row>
                                        <Col sm={12}>
                                            {vendor.Description ? <p className="profile-description" dangerouslySetInnerHTML={{ __html: vendor.Description }} /> : <p className="profile-description"></p>}
                                        </Col>
                                        <Col md={5} className="padding-left-remove">
                                            <div className="contact-box free-profile-padding background-white border-white">
                                                <h6>Contact Info</h6>
                                                <div className="list">
                                                    <div className="list-item padding-bottom-remove">
                                                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                                                        <div className="list-content">
                                                            <p>{vendor.Address1}</p>
                                                            {vendor.Address2 ? <p>{vendor.Address2}</p> : ''}
                                                            <p>{vendor.City}, {vendor.StateID ? <span>{vendor.StateID},</span> : ''} {vendor.ZipCode}</p>
                                                            {vendor.CountryCode ? <p>{vendor.CountryCode}</p> : ''}
                                                        </div>
                                                    </div>
                                                    {vendor.Phone ?
                                                        <React.Fragment>
                                                            <div className="list-item">
                                                                <FontAwesomeIcon icon={faPhoneAlt} />
                                                                <div className="list-content">
                                                                    <a href={"tel:" + normalizeInput(vendor.Phone)}>{normalizeInput(vendor.Phone)}</a>
                                                                </div>
                                                            </div>
                                                        </React.Fragment> : ''}
                                                </div>
                                                <div>
                                                </div>

                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                )
            }
        }
        else {
            return (<div className={this.props.match.path.indexOf("user") > -1 ? "profile-condition" : "profile-vendordirectory"} >
                <Container> </Container>
            </div>);
        }
    }
}

export default Profile;