import React, { useState } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { Row, Col } from 'reactstrap';

const MailchimpForm = () => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const url = "https://listselfstorage.us11.list-manage.com/subscribe/post?u=f3216c7ba7e1d548bb4550de6&id=795861c92f&f_id=006091e0f0";

  const formatErrorMessage = (message) => {
    // Remove "0 -" from the start of the message, if present
    return message?.startsWith("0 -") ? message.substring(4) : message;
  };

  return (
    <div className='mailchimp_form'>
      <h2>Subscribe to our Newsletter</h2>
      <p>Stay up-to-date on new listings, industry news, market insights, interviews and more!</p>
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <Row className='mailchimp_row'>
            <Col md={4}>
              <div>
                <label htmlFor="email"><span className='required-icon'>*</span> Email Address</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {status === 'error' && <div className='mailchimp_error'>{formatErrorMessage(message)}</div>}
              </div>
            </Col>
            <Col md={4}>
              <div>
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
            </Col>
            <Col md={4}>
              <div>
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </Col>
            <Col md={12}>{status === 'success' && <div className='mailchimp_success'>Thank you for subscribing!</div>}</Col>
            <button
              onClick={() => subscribe({ EMAIL: email, FNAME: firstName, LNAME: lastName })}
              className='sign_me_up'
            >
              Sign me up!
            </button>
            {/* {status === 'sending' && <div>Sending...</div>} */}
            
          </Row>
        )}
      />
    </div>
  );
};

export default MailchimpForm;
