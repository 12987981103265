import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { CONFIG } from '../../../Utils/config';
import { VENDORTYPE, substringStripHtmlTag } from '../../../Utils/utils';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import vendor_no from '../../../Assets/Img/ventor-list.png';
import VendorAdTile from '../../Shared/VendorAdTile/VendorAdTile';
import './VendorList.css';
import Helmet from '../../../Utils/Helmet';

export default class VendorList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            error: '',
            adsList: [],
            pageCount: 0,
            parentCategoryName: '',
            childCategoryID: 0,
            childCategoryName: '',
            keyword: '',
            pagination: {},
            pageNum: 0
        };
    }

    componentDidMount() {
        var childCategoryName = '';
        var parentCategoryName = '';
        var childCategoryID = '';
        var keyword = '';

        if (this.props.match.params.parentCategoryName) {
            parentCategoryName = this.props.match.params.parentCategoryName;
        }
        if (this.props.match.params.childCategoryID) {
            childCategoryID = this.props.match.params.childCategoryID;
        }
        if (this.props.match.params.childCategoryName) {
            childCategoryName = this.props.match.params.childCategoryName;
        }
        if (this.props.match.params.keyword) {
            keyword = decodeURIComponent(this.props.match.params.keyword);
            keyword = keyword.replaceAll("JQ==", "%");
        }
        this.setState({ childCategoryID, parentCategoryName, childCategoryName, keyword });

        window.scrollTo(0, 0);

        var searchCriteria = {
            RegionID: CONFIG.REGION_ID,
            Keyword: keyword,
            CategoryID: childCategoryID,

            Page: 1,
            PageLength: CONFIG.PAGE_LENGTH,
            SortBy: '',
            SortOrder: ''
        }

        this.setState({ searchCriteria }, () => {
            this.getVendorsList(searchCriteria);
        });

        this.getAdsList();
    }

    getAdsList() {
        this.setState({ isLoading: true, error: '' });

        axios.get(CONFIG.API_URL + 'vendor/ads/' + CONFIG.REGION_ID)
            .then(response => {
                if (response.status === 200) {
                    this.setState({ isLoading: false, adsList: response.data });
                }
            })
            .catch(err => {
                if (err.response != null && err.response.status === 400) {
                    const error = err.response.data;
                    this.setState({ error, isLoading: false });
                }
                else {
                    const error = "Something went wrong.";
                    this.setState({ error, isLoading: false });
                }
            });
    }

    getVendorsList(params) {
        this.setState({ isLoading: true, error: '' });

        axios.post(CONFIG.API_URL + 'vendor/search', params)
            .then(response => {
                if (response.status === 200) {
                    let vendors = response.data;
                    this.setState({
                        isLoading: false,
                        vendorList: vendors,
                        category: response.data.Category,
                        pagination: response.data.Pagination,
                        pageNum: Math.ceil(response.data.Pagination.TotalRecords / 10)
                    });
                }
            })
            .catch(err => {
                if (err.response != null && err.response.status === 400) {
                    const error = err.response.data;
                    this.setState({ error, isLoading: false });
                }
                else {
                    const error = "Something went wrong.";
                    this.setState({ error, isLoading: false });
                }

            });
    }

    handlePageTrack = (event, pageCount) => {
        const { searchCriteria } = this.state;
        searchCriteria.Page = pageCount;
        this.setState({ searchCriteria }, () => {
            window.scrollTo(0, 0);
            this.getVendorsList(searchCriteria);
        });
    };

    vendorProfile(permalink) {
        const { from } = { from: { pathname: "/vendors/profile/" + permalink } };
        this.props.history.push(from);
    }

    render() {
        const { vendorList, adsList, keyword, category, pageNum } = this.state;

        // document.title = CONFIG.PAGE_TITLE + (keyword ? keyword : category ? category.CategoryName : '');
        let categoryName = (keyword ? keyword : category ? category.CategoryName : '');
        var paidListing = vendorList && vendorList.Vendors ? vendorList.Vendors.filter(vendor => vendor.VendorTypeID === VENDORTYPE.Premium) : [];
        var freeListing = vendorList && vendorList.Vendors ? vendorList.Vendors.filter(vendor => vendor.VendorTypeID === VENDORTYPE.Free) : [];
        return (
            <div className="section-cendordirectory padding-t-25">
                <Helmet
                    title={categoryName}
                    addPostfixTitle={true}
                    keywords="Category Name, Category"
                />
                <Container fluid>
                    <Row>
                        <Col sm={2} className="remove-underline">
                            {
                                adsList.length > 0 ?
                                    <VendorAdTile key={adsList[0].AdvertisementId} ad={adsList[0]} /> : ''
                            }

                            {
                                adsList.length > 1 ?
                                    <VendorAdTile key={adsList[1].AdvertisementId} ad={adsList[1]} /> : ''
                            }

                            {
                                adsList.length > 2 ?
                                    <VendorAdTile key={adsList[2].AdvertisementId} ad={adsList[2]} /> : ''
                            }

                            {
                                adsList.length > 3 ?
                                    <VendorAdTile key={adsList[3].AdvertisementId} ad={adsList[3]} /> : ''
                            }
                        </Col>
                        <Col sm={8}>
                            <div className="category-vendor">
                                <div className="heading">
                                    <h3>{keyword ? <span>{keyword}</span> : ''}</h3>
                                    <h3>{category ? <span>{category.CategoryName}</span> : ''}</h3>
                                    <div className="leftColor-separator margin-b-15"></div>
                                </div>
                                <div className="overflow-scroll-table br-0">
                                    {vendorList ? vendorList.Vendors && (paidListing.length > 0 || freeListing.length > 0) ?
                                        <p className="total-vendorlist-home">Total Vendors: {vendorList.Pagination.TotalRecords}</p>
                                        :
                                        <Col md={12} className="text-align-center no-records-found">No records found</Col>
                                        : <Col md={12} className="text-align-center no-records-found"></Col>
                                    }
                                    <React.Fragment>
                                        {paidListing.length > 0 ?
                                            <React.Fragment>
                                                <div className="paid-profilelist">
                                                    <div>
                                                        {paidListing.map((record) =>
                                                            <div key={record.VendorID} className="list-background">
                                                                <span className="display-content" onClick={this.vendorProfile.bind(this, record.Permalink)}>
                                                                    {record.Logo ?
                                                                        <div className="vendor-list-img">
                                                                            <img src={'/VendorLogos/' + record.Logo} alt="img" onError={(e) => { e.target.onerror = null; e.target.src = vendor_no }} className="width-100 height-100"></img>
                                                                        </div> : ''
                                                                    }
                                                                    <div className="vendor-content">
                                                                        <h6>{record.CompanyName}</h6>
                                                                        <p className="para-margin-b">{substringStripHtmlTag(record.Description, 250)}</p>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                            : ''}
                                        {freeListing.length > 0 ?
                                            <React.Fragment>
                                                <div className="free-profilelist paid-profilelist">
                                                    <div>
                                                        {freeListing.map((record) =>
                                                            <div key={record.VendorID} className="list-background background-white">
                                                                <span className="display-content" onClick={this.vendorProfile.bind(this, record.Permalink)}>
                                                                    <div className="vendor-content">
                                                                        <h6>{record.CompanyName}</h6>
                                                                        <p className="para-margin-b">{substringStripHtmlTag(record.Description, 250)}</p>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                            : ''}
                                        {vendorList && vendorList.Vendors && (paidListing.length > 0 || freeListing.length > 0) ?
                                            <Table className="vendor-footer">
                                                <TableBody>
                                                    <TableFooter>
                                                        <TableRow>
                                                            <TableCell colspan="7"><Pagination className="seaarch-pagination table-pagination-model" count={pageNum} showFirstButton showLastButton onChange={this.handlePageTrack} />
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableFooter>
                                                </TableBody>
                                            </Table>
                                            : ''}
                                    </React.Fragment>
                                </div>
                            </div>
                        </Col>
                        <Col sm={2} className="remove-underline">
                            {
                                adsList.length > 4 ?
                                    <VendorAdTile key={adsList[4].AdvertisementId} ad={adsList[4]} /> : ''
                            }

                            {
                                adsList.length > 5 ?
                                    <VendorAdTile key={adsList[5].AdvertisementId} ad={adsList[5]} /> : ''
                            }

                            {
                                adsList.length > 6 ?
                                    <VendorAdTile key={adsList[6].AdvertisementId} ad={adsList[6]} /> : ''
                            }
                            {
                                adsList.length > 7 ?
                                    <VendorAdTile key={adsList[7].AdvertisementId} ad={adsList[7]} /> : ''
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
