import React, { Component } from 'react';
import { Row, Container, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { CONFIG } from './../../Utils/config';
import { REGIONS } from '../../Utils/utils';
import './AboutUs.css';
import Helmet from '../../Utils/Helmet';

class AboutUs extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

        return (
            <div className="about-us-page">
                <Helmet
                    title='About Us'
                    addPostfixTitle={true} 
                    keywords="About Us, Info@ListSelfStorage.com, US/Canada Phone: 313-484-4670, UK/Europe Phone: 020 3048 3123"
                />
                <Container>
                    <Row>
                        <Col className="p-0">
                            <h3>About Us</h3>
                            <div className="leftColor-separator margin-b-3"></div>
                            <p>List Self Storage is the world’s premier platform for buying and selling self storage facilities in the USA and Europe. Since launching in 2015, List Self Storage has hosted over $5 billion in self storage, conversion and development properties for sale with cooperation from the brokerage community. <a href="http://listselfstorage.co.uk/">ListSelfStorage.co.uk</a>, our United Kingdom-focused site, launched on May of 2021.</p>
                            <p>List Self Storage is committed to providing resources for users in the form of video recordings from industry experts, educational articles, case studies and a searchable vendor directory via our “Vendors” page. As a part of the StorTrack suite of products, List Self Storage also offers real-time market data.</p>
                            <p>StorTrack is focused on providing the industry with business intelligence and opportunity analysis. Over 80% of medium and large self storage operators (managing 10+ facilities) in the USA and Canada use StorTrack products to support decision-making and business optimization.</p>
                            <p>For more information on any of our products, please contact us at&nbsp;
                            {CONFIG.REGION_ID === REGIONS.US ?
                                    <a href="mailto:Info@ListSelfStorage.com">Info@ListSelfStorage.com</a>
                                    : CONFIG.REGION_ID === REGIONS.UK ?
                                        <a href="mailto:Info@ListSelfStorage.co.uk">Info@ListSelfStorage.co.uk</a>
                                        : ''}
                                .</p>
                            <p>
                                <b>US/Canada</b><br />
                                Phone: 313-484-4670
                            </p>
                            <p>
                                <b>UK/Europe</b><br />
                                Phone: 020 3048 3123
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default connect()(AboutUs);