import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import moment from 'moment';
import { CONFIG } from '../../../Utils/config';
import { substringStripHtmlTag, thumbnailPropertyData, getPropertyTypeClass, getPropertyStatusClass
    , getPropertyStatus, getDateFormat, getFormattedInt, PRICEOPTION, REGIONS } from '../../../Utils/utils';

export class ListingTile extends React.Component {
    
    render() {
        const { property, isHome } = this.props;
        return (
            <div className="home-property">
                <Link target={isHome ? "_blank" : "_top"} to={"/property/" + property.Permalink}>
                    <div className="carousel-root">
                        <div className="img-home-property" style={{ backgroundImage: `url(${(CONFIG.REGION_ID !== REGIONS.UK ? CONFIG.LSS_US : CONFIG.LSS_UK) + '/PropertyImages/' + property.PrimaryImage})` }}>
                            {/* <img src={'/PropertyImages/' + property.PrimaryImage} onError={(e) => { e.target.onerror = null; e.target.src = vendor_no }} className="width-100 height-100" /> */}
                        </div>
                    </div>
                    <div className="tags-tss">
                        <div className={getPropertyTypeClass(property.PropertyTypeId)}>{property.PropertyType}</div>
                        <div className={getPropertyStatusClass(property.PropertyStatusId)}>{getPropertyStatus(property.PropertyStatusId)}</div>
                    </div>
                    <Row className="content-property">
                        <Col sm={12} className="padding-8" style={{ marginBottom: '5px' }}>
                            <h5 title={property.PropertyName}>{substringStripHtmlTag(property.PropertyName, 40)}</h5>
                            <h6>{property.City}{CONFIG.REGION_ID !== REGIONS.UK ? property.StateId ? ", " + property.StateId : '' : ''}</h6>
                        </Col>
                        <Col sm={12} className="padding-8">
                            {property.AlternatePriceOptionID ?
                                <p className=""><span className="call-forvalue"> {property.AlternatePriceOptionName}{(property.AlternatePriceOptionID === 5 || property.AlternatePriceOptionID === 6) && property.AlternatePriceOptionDate !== null ? ": " + moment(property.AlternatePriceOptionDate).format(getDateFormat()) : ''}</span></p>
                                :
                                <p className=""><span className="rate-p"> {property.Currency}{getFormattedInt(property.Price)}</span></p>
                            }
                        </Col>
                        <Col sm={12} className="padding-right-remove padding-l-8">
                            {thumbnailPropertyData(property)}
                        </Col>
                    </Row>
                    <div className="clear"></div>
                </Link>
            </div>
        );
    }
}

export default connect()(ListingTile);