
import React, { Component } from 'react';
import { Row, Col, Button, Container } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { CONFIG } from '../../../Utils/config';
import axios from 'axios';
import './ForgotPassword.css';
import { connect } from 'react-redux';
import { showLoader, hideLoader } from '../../../redux/ducks/application';
import Helmet from '../../../Utils/Helmet';

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isSuccess: false,
            error: '',
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event, errors, values) {
        if (errors.length === 0) {
            this.props.dispatch(showLoader());
            this.setState({ isSuccess: false, error: '' });
            const data = {
                'UserName': values.Email,
                'RegionId': CONFIG.REGION_ID
            }

            axios.post(CONFIG.API_URL + 'Account/forgotpassword/', data)
                .then(response => {
                    if (response.status === 200) {
                        this.props.dispatch(hideLoader());
                        this.setState({  isSuccess: true });

                    }
                })
                .catch(err => {
                    this.props.dispatch(hideLoader());
                    if (err.response != null && err.response.status === 400) {
                        var error = err.response.data;
                        if (error === "INACTIVE-USER") {
                            error = "Your account is inactive.";
                        }
                        this.setState({ error });
                    }
                    else {
                        const error = "Something went wrong.";
                        this.setState({ error });
                    }

                });
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        // document.title = CONFIG.PAGE_TITLE + 'Forgot Password';
        const { error, isSuccess } = this.state;
        return (
            <div className="forgotpage">
               <Helmet
                    title='Forgot Password'
                    addPostfixTitle={true} 
                    keywords="Forgot Password, Reset Password, Password"
                />
                <Container>
                    <Row>
                        <Col sm={5}>
                            <Col sm={12} className="margin-b25">
                                <h1 className="page-title">Forgot password</h1>
                                <Row className="padding-t-0">
                                    <Col sm={12}>
                                        <div className="title-separator1"></div>
                                    </Col>
                                </Row>
                            </Col>

                            {isSuccess ?
                                <span className="success">Password reset link has been sent to your email.</span>
                                : ''}

                            <div>
                                {/* <span className="left-border"></span> */}

                                <AvForm onSubmit={this.handleSubmit.bind(this)} ref={c => (this.form = c)} id="LSS_password_reset_login_form">
                                    <AvField name="Email" label="" placeholder="Email" type="email" maxLength="255" validate={{
                                        required: { value: true, errorMessage: 'Email is required' },
                                        pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$/, errorMessage: 'Email is invalid' }
                                    }} />
                                    <Button>Submit</Button>
                                    {error !== '' ? <span className="forgot-error">{error}</span> : ''}
                                </AvForm>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
export default connect()(ForgotPassword);