import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import axios from "axios";
import { setSnackbar } from '../../../redux/ducks/snackbarReducer';
import { CONFIG } from '../../../Utils/config';
import { getSecureID, getRoleID, getSTUserStatus } from '../../../Utils/localStorage';
import mvp from '../../../Assets/Img/mvp.png';
import lss from '../../../Assets/Img/lss-user.png';
import watchlist from '../../../Assets/Img/watchlist-logo.png';
import { Link } from "react-router-dom";
import './DashBoard.css';

class DashBoard extends Component {
    constructor(props) {
        super(props);

        this.getMVPURL = this.getMVPURL.bind(this);
        this.updateReportUserStatus = this.updateReportUserStatus.bind(this);
    }

    getMVPURL() {
        if((getRoleID() === 4 || getRoleID() === 5) && getSTUserStatus() !== 1) {
            this.updateReportUserStatus();
        }
        else {        
            window.top.location.href = process.env.REACT_APP_MVP_URL + "logins/" + getSecureID();
        }
    }

    updateReportUserStatus() {
        let token = localStorage.getItem('accessKey');
        axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;

        axios.post(CONFIG.API_URL + 'marketreport/update/userstatus').then(response => {
            if (response.status === 200) {
                    window.top.location.href = process.env.REACT_APP_MVP_URL + "logins/" + getSecureID(); 
                }
            })
            .catch(err => {
                if (err.response !== null && err.response !== undefined) {
                    this.props.dispatch(setSnackbar(true, "error", err.response.data));
                }
                else {
                    this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                }
            });
    }

    render() {
        return (
            <div className="usermanagement">
                <Container fluid>
                    <Row className="width-100 margin-0">
                        <Col sm={5} className="margin-r-0">
                            <Link onClick={this.getMVPURL.bind(this)}><div className="user-management-box">
                                <h6>Go to Explorer</h6>
                                <div className="img-logo-usermanege text-center" style={{width: '100%'}}>
                                    <img src={mvp} alt="MVP" />
                                </div>
                                <div className="um-content">
                                    <p>View and analyze market data on any market in the US, Canada and
    beyond. Simply add credits to be able to add and view market data.</p>
                                </div>
                            </div></Link>
                        </Col>
                        <Col sm={5}>
                            <Link to="/user/properties"><div className="user-management-box">
                                <h6 className="">List/Manage Properties</h6>
                                <div className="img-logo-usermanege-lss">
                                    <img src={lss} className="width-100 height-100" alt="List/Manage Properties" />
                                </div>
                                <div className="um-content">
                                    <p>Add, edit and manage property listing on ListSelfStorage.com</p>
                                </div>
                            </div></Link>
                        </Col>
                        <Col sm={5} className="margin-r-0">
                            <Link to="/user/vendors"><div className="user-management-box">
                                <h6 className="">Vendor Directory</h6>
                                <div className="img-logo-usermanege-lss">
                                    <img src={lss} className="width-100 height-100" alt="Vendor Directory" />
                                </div>
                                <div className="um-content">
                                    <p>List and promote your company as a vendor or broker to
    the self-storage industry in our vendor directory.</p>
                                </div>
                            </div></Link>
                        </Col>
                        <Col sm={5}>
                            <Link to="/user/watchlist"><div className="user-management-box">
                                <h6 className="">View your WatchList</h6>
                                <div className="img-logo-usermanege-watchlist">
                                    <img src={watchlist} className="width-100 height-100" alt="View your WatchList" />
                                </div>
                                <div className="um-content">
                                    <p>Create a selection of properties on List Self Storage that you are interested in</p>
                                </div>
                            </div></Link>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default DashBoard;

