import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { CONFIG } from './../../../Utils/config';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import './VendorCategories.css';
import Helmet from '../../../Utils/Helmet';


export default class VendorCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.isOpen,
      vendorID: props.vendorID,
      isLoading: false,
      treeData: [],
      selectedCategoryID: [],
      selectedCategoryName: [],
      childsParentID: 0
    };
  }

  componentDidMount() {
    const { isOpen, vendorID } = this.state;
    var selectedCategoryName = [];
    var selectedCategoryID = [];

    if (isOpen) {
      this.getTreeData(vendorID);
    }

    if (this.props.VendorCategoryIDs) {
      selectedCategoryID = Array.from(this.props.VendorCategoryIDs.split(","), Number);
    }

    if (this.props.vendorCategoryNames) {
      selectedCategoryName = Array.from(this.props.vendorCategoryNames.split(","));
    }

    this.setState({ selectedCategoryID, selectedCategoryName });
  }

  toggleClose = () => {
    this.setState({ isOpen: false, isLoading: false }, () => {
      this.props.parentMethod(undefined, '');
    });
  }

  getTreeData(vendorID) {
    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }
    axios.get(CONFIG.API_URL + 'vendor/category/tree/' + vendorID + "/" + CONFIG.REGION_ID)
      .then(res => {
        let treeData = res.data;

        this.setState({ treeData });
      })
      .catch((err) => {

      })
  }

  selectCategory(child) {
    var selectedCategoryName = this.state.selectedCategoryName;
    var selectedCategoryID = this.state.selectedCategoryID;
    var indexOfselectedCategoryID = selectedCategoryID.indexOf(child.id);

    if (indexOfselectedCategoryID > -1) {
      selectedCategoryID.splice(indexOfselectedCategoryID, 1);
      selectedCategoryName.splice(indexOfselectedCategoryID, 1);
    } else {
      selectedCategoryID.push(child.id);
      selectedCategoryName.push(" " + child.name);
    }

    this.setState({ selectedCategoryID, selectedCategoryName });
  }

  onOKClick() {
    const { selectedCategoryName, selectedCategoryID } = this.state;

    this.setState({ isOpen: false }, () => {
      this.props.parentMethod(selectedCategoryID.toString(), selectedCategoryName.toString());
    });
  }

  getChild(id) {
    const { childsParentID } = this.state;
    if (childsParentID !== 0 && childsParentID === id) {
      this.setState({ childsParentID: 0 });
    } else {
      this.setState({ childsParentID: id });
    }
  }

  render() {
    // document.title = CONFIG.PAGE_TITLE + 'Vendor Categories';
    const { treeData, isOpen, childsParentID, selectedCategoryID } = this.state;

    return (
      <div>
        <Helmet
          title='Vendor Categories'
          addPostfixTitle={true}
          keywords="Vendor Categories"
        />
        <div>
          <Modal size="md" backdrop="static" isOpen={isOpen} toggle={this.toggleClose.bind(this)} className="vendor-categories">
            <ModalHeader toggle={this.toggleClose.bind(this)}>
              Select Category
            </ModalHeader>
            <ModalBody>
              <div className="table-model parent-category table-header-blue scroll-model-table">
                {treeData.length > 0 ?
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="date-width">Category Name</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {treeData.map(parent =>

                        <React.Fragment>
                          {parent.isleaf ?
                            <TableRow className="categories-vendor2">
                              <React.Fragment>
                                <p id={parent.id}>
                                  <input type="checkbox" name={"checkbox-" + parent.id} id={parent.id} onClick={this.selectCategory.bind(this, parent)} checked={selectedCategoryID.indexOf(parent.id) > -1 ? true : false} />
                                  <Link onClick={this.selectCategory.bind(this, parent)}>{parent.name}</Link>
                                </p>
                              </React.Fragment>
                            </TableRow>
                            :
                            <TableRow className="categories-vendor" id={parent.id} key={parent.id}>
                              <p onClick={() => this.getChild(parent.id)}><FontAwesomeIcon icon={(childsParentID === parent.id || parent.isexpanded) ? faCaretDown : faCaretRight} />{parent.name}</p>
                            </TableRow>
                          }
                          {parent.children ?
                            <TableRow className={(childsParentID === parent.id || parent.isexpanded) ? 'childEnable vendor-category-two' : 'childDisable'}>
                              {parent.children.length > 0 ? parent.children.map(child =>
                                <React.Fragment>
                                  <p id={child.id}>
                                    <input type="checkbox" name={"checkbox-" + child.id} id={child.id} onClick={this.selectCategory.bind(this, child)} checked={selectedCategoryID.indexOf(child.id) > -1 ? true : false} />
                                    <Link onClick={this.selectCategory.bind(this, child)}> {child.name}</Link>
                                  </p>
                                </React.Fragment>
                              ) : <p className="no-data">no data</p>
                              }
                            </TableRow> : ''
                          }
                        </React.Fragment>
                      )}
                    </TableBody>
                  </Table> : <TableRow>No categories found</TableRow>}
              </div>
              <input type="button" className="ok-btn float-right" onClick={this.onOKClick.bind(this)} value="OK" />
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}
