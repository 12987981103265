import React, { Component } from 'react';
import { Row, Col, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Link } from "react-router-dom";
import { CONFIG } from '../../../Utils/config';
import { getUserType, getUserID } from '../../../Utils/localStorage';
import { normalizeInput, USERTYPES, VENDORTYPE, REGIONS } from '../../../Utils/utils';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPencilAlt, faFileUpload } from '@fortawesome/free-solid-svg-icons';
import deleteicon from '../../../Assets/Img/delete.png';
import axios from 'axios';
import { AssignVendor } from '../../Admin/Shared/AssignVendor';
import { VendorCategories } from '../VendorCategories';
import { setSnackbar } from '../../../redux/ducks/snackbarReducer';
import { showLoader, hideLoader } from '../../../redux/ducks/application';
import './VendorProfile.css';
import vendor_no from '../../../Assets/Img/ventor-list.png';
import { Editor } from "@tinymce/tinymce-react";
import Dropzone from 'react-dropzone';
import ReactCrop from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';
import Helmet from '../../../Utils/Helmet';

class VendorProfile extends Component {


    static defaultProps = {
        allowedTypes: ['image/jpeg', ' image/jpg', ' image/png', ' image/svg'],
        crop: {
            aspect: 400 / 11,
            width: 200,
            keepSelection: true
        },
        maxFileSize: 15000,
    }

    constructor(props) {
        super(props);
        this.state = {
            vendorModal: false,
            vendorCategoryModal: false,
            userType: '',
            vendorTypes: [],
            stateList: [],
            countryList: [],
            vendorImages: [],
            vendor: this.initializeVendorObject(),
            vendorNonEdit: {},
            errors: {},
            isAdd: true,
            descriptionCount: 0,
            imageToCrop: null,
            imageToCropList: [],
            open: false,
            crop: this.props.crop,
            uploadError: false,
            type: '',
            DeletedImages: [],
            DeletedLogo: [],
            WarningCss: "counter-box last-box-border",
        };
        this.uploadVendorLogo = this.uploadVendorLogo.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.parentVendorMethod = this.parentVendorMethod.bind(this);
        this.parentVendorCategoriesMethod = this.parentVendorCategoriesMethod.bind(this);
        this.onDescriptionChange = this.onDescriptionChange.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getLookupVendorTypes();
        if (CONFIG.REGION_ID !== REGIONS.UK) {
            this.getLookupCountry();
            this.getLookupState();
        }
        var userType = getUserType();
        var userID = getUserID();

        const { vendor } = this.state;
        var vendorNonEdit = vendor;
        var isAdd = true;
        if (this.props.match.params.vendorId) {

            vendor.VendorID = this.props.match.params.vendorId;
            isAdd = false;
            this.getVendorProfile(vendor.VendorID);
        } else {
            if (userType === USERTYPES.Broker) {
                vendor.VendorUserID = userID;
            }
            vendorNonEdit = vendor;
        }

        this.setState({ vendor, userType, userID, vendorNonEdit, isAdd });
    }

    componentWillReceiveProps(newprops) {
        var props = this.props;
        if (newprops.match) {
            if (newprops.match.params.vendorId === props.match.params.vendorId) {
            }
        }
    }

    initializeVendorObject() {
        var vendor = {
            VendorID: 0,
            CompanyName: '',
            Address1: '',
            Address2: '',
            City: '',
            StateID: '',
            CountryID: CONFIG.REGION_ID === REGIONS.US ? CONFIG.US_CountryId : '',
            ZipCode: '',
            VendorUserID: 0,
            VendorTypeID: 1,
            isInvoiceMe: false,
            Logo: '',
            Phone: '',
            Email: '',
            Website: '',
            Description: '',
            FirstName: '',
            LastName: '',
            ContactHeadShot: '',
            ContactEmail: '',
            ContactPhone: '',
            IsVendorCategoryUpdated: false,
            VendorCategoryIDs: '',
            VendorCategories: '',
            IsVendorImageUpdated: false,
            VendorUserName: '',
            IsInvoiceMe: false,
        }
        this.setState({ vendor });
        return vendor;
    }

    copyToNonEditVendor(Vendor) {
        var vendorNonEdit = {
            NVendorID: Vendor.VendorID,
            NCompanyName: Vendor.CompanyName,
            NAddress1: Vendor.Address1,
            NAddress2: Vendor.Address2,
            NCity: Vendor.City,
            NStateID: Vendor.StateID,
            NCountryID: Vendor.CountryID,
            NZipCode: Vendor.ZipCode,
            NVendorUserID: Vendor.VendorUserID,
            NVendorTypeID: Vendor.VendorTypeID,
            NLogo: Vendor.Logo,
            NPhone: Vendor.Phone,
            NEmail: Vendor.Email,
            NWebsite: Vendor.Website,
            NDescription: Vendor.Description,
            NFirstName: Vendor.FirstName,
            NLastName: Vendor.LastName,
            NContactHeadShot: Vendor.ContactHeadShot,
            NContactEmail: Vendor.ContactEmail,
            NContactPhone: Vendor.ContactPhone,
            NVendorCategoryIDs: Vendor.VendorCategoryIDs,
            NVendorCategories: Vendor.VendorCategories,
            NVendorUserName: Vendor.VendorUserName,
            NVendorImages: Vendor.VendorImages
        }
        return vendorNonEdit;
    }

    copyToVendor(vendorNonEdit) {
        var vendor = {
            VendorID: vendorNonEdit.NVendorID,
            CompanyName: vendorNonEdit.NCompanyName,
            Address1: vendorNonEdit.NAddress1,
            Address2: vendorNonEdit.NAddress2,
            City: vendorNonEdit.NCity,
            StateID: vendorNonEdit.NStateID,
            CountryID: vendorNonEdit.NCountryID,
            ZipCode: vendorNonEdit.NZipCode,
            VendorUserID: vendorNonEdit.NVendorUserID,
            VendorTypeID: vendorNonEdit.NVendorTypeID,
            Logo: vendorNonEdit.NLogo,
            Phone: vendorNonEdit.NPhone,
            Email: vendorNonEdit.NEmail,
            Website: vendorNonEdit.NWebsite,
            Description: vendorNonEdit.NDescription,
            FirstName: vendorNonEdit.NFirstName,
            LastName: vendorNonEdit.NLastName,
            ContactHeadShot: vendorNonEdit.NContactHeadShot,
            ContactEmail: vendorNonEdit.NContactEmail,
            ContactPhone: vendorNonEdit.NContactPhone,
            IsVendorCategoryUpdated: false,
            VendorCategoryIDs: vendorNonEdit.NVendorCategoryIDs,
            VendorCategories: vendorNonEdit.NVendorCategories,
            IsVendorImageUpdated: false,
            VendorUserName: vendorNonEdit.NVendorUserName,
            IsInvoiceMe: false,
        }
        return vendor;
    }

    getLookupVendorTypes() {
        axios.get(CONFIG.API_URL + 'lookup/vendor/types').then(response => {
            if (response.status === 200) {
                this.setState({ vendorTypes: response.data });
            }
        })
            .catch(err => {
            });
    }

    getLookupState() {
        axios.get(CONFIG.API_URL + 'lookup/state/' + CONFIG.US_CountryId).then(response => {
            if (response.status === 200) {
                this.setState({ stateList: response.data });
            }
        })
            .catch(err => {
            });
    }

    getLookupCountry() {
        axios.get(CONFIG.API_URL + 'lookup/country/' + CONFIG.REGION_ID).then(response => {
            if (response.status === 200) {
                this.setState({ countryList: response.data });
            }
        }).catch(err => {
            this.setState({});
        })
    }

    getVendorProfile(vendorId) {
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.get(CONFIG.API_URL + 'vendor/' + vendorId + "/" + CONFIG.REGION_ID).then(response => {
            if (response.status === 200) {
                var vendor = response.data;
                var vendorImages = vendor.VendorImages ? Array.from(vendor.VendorImages.split(",")) : [];
                var FirstName = '';
                var LastName = '';
                if (vendor.ContactName) {
                    FirstName = vendor.ContactName.split(" ")[0];
                    LastName = vendor.ContactName.split(" ")[1] ? vendor.ContactName.split(" ")[1] : '';
                }
                vendor.FirstName = FirstName;
                vendor.LastName = LastName;
                var vendorNonEdit = this.copyToNonEditVendor(vendor);

                this.setState({ vendor, vendorImages, vendorNonEdit });
            }
            if(response.status === 204){
                this.props.dispatch(setSnackbar(true, "error", "Invalid Request"));
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    onWebsiteChange(e) {
        const { vendor, errors, userType } = this.state;

        vendor.Website = e.target.value;
        if (userType !== USERTYPES.Admin) {
            if (e.target.value.trim() !== "") {
                errors["webSiteError"] = "";
            } else {
                errors["webSiteError"] = "Website is required";
            }
        }
        this.setState({ vendor, errors });
    }

    onInvoiceChange(e) {
        const { vendor, errors } = this.state;
        vendor.IsInvoiceMe = !vendor.IsInvoiceMe;
        if (vendor.IsInvoiceMe) {
            errors["invoiceMeError"] = "";
        }
        this.setState({ vendor, errors });
    }

    onStateChange(e) {
        const { vendor, errors } = this.state;
        vendor.StateID = e.target.value;
        errors["stateError"] = "";
        if (vendor.StateID === "") {
            errors["stateError"] = "State is required";
        }
        this.setState({ vendor, errors });
    }

    onCountryChange(e) {
        const { vendor, errors } = this.state;
        vendor.CountryID = e.target.value;
        errors["countryError"] = "";
        if (vendor.CountryID === "0") {
            errors["countryError"] = "Country is required";
        }
        this.setState({ vendor, errors });
    }

    onVendorTypeChange() {
        const { vendor, userType, isAdd, vendorNonEdit } = this.state;
        var isInvoiceMe = this.state.isInvoiceMe;

        var vendorImages = this.state.vendorImages;

        if (vendor.VendorTypeID === VENDORTYPE.Free) {
            if (userType !== USERTYPES.Admin) {
                if (isAdd) {
                    isInvoiceMe = true;
                } else {
                    if (vendorNonEdit.NVendorTypeID !== VENDORTYPE.Premium) {
                        isInvoiceMe = true;
                    }
                }
            }
            vendor.VendorTypeID = VENDORTYPE.Premium;
        } else if (vendor.VendorTypeID === VENDORTYPE.Premium) {

            vendor.VendorTypeID = VENDORTYPE.Free;
        }
        vendor.Logo = "";
        vendorImages = [];
        vendor.Email = '';
        vendor.Website = '';
        vendor.FirstName = '';
        vendor.LastName = '';
        vendor.ContactHeadShot = '';
        vendor.ContactEmail = '';
        vendor.ContactPhone = '';
        vendor.IsInvoiceMe = false;
        this.setState({ vendor, vendorImages, isInvoiceMe });
    }

    handlePhoneChange({ target: { value } }) {
        const { vendor, errors } = this.state;
        vendor.Phone = normalizeInput(value, vendor.Phone);

        if (value === "") {
            errors["phoneError"] = "Phone is required";
        } else {
            errors["phoneError"] = "";
        }

        this.setState({ vendor, errors });
    };


    handleContactPhoneChange({ target: { value } }) {
        const { vendor, errors, userType } = this.state;
        vendor.ContactPhone = normalizeInput(value, vendor.ContactPhone);
        if (userType !== USERTYPES.Admin) {
            if (value === "") {
                errors["contactPhoneError"] = "Phone is required";
            } else {
                errors["contactPhoneError"] = "";
            }
        }

        this.setState({ vendor, errors });
    };

    emailChange(e, type) {
        const { vendor, errors, userType } = this.state;
        errors["emailError"] = "";
        errors["contactEmailError"] = "";

        if (userType !== USERTYPES.Admin) {
            if (type === "Email") {
                if (e.target.value === "") {
                    errors["emailError"] = "Email is required";
                }
                vendor.Email = e.target.value;
            }
            if (type === "ContactEmail") {
                if (e.target.value === "") {
                    errors["contactEmailError"] = "Email is required";
                }
                vendor.ContactEmail = e.target.value;
            }
        }
        this.setState({ vendor, errors });
    }

    onKeyChange(e) {
        let vendor = this.state.vendor;
        let errors = this.state.errors;

        vendor[e.target.name] = e.target.value;
        if (e.target.value.trim() !== "") {
            errors[e.target.name] = "";
        }
        this.setState({
            vendor, errors
        });
    }

    onDescriptionChange(articleDescription, editor) {
        const { vendor } = this.state;

        vendor.Description = articleDescription
        var descriptionCount = editor.plugins.wordcount.getCount();
        this.setState({ vendor, descriptionCount });
    }

    saveVendor(event, errors, values) {
        this.props.dispatch(showLoader());
        var isValid = false;
        const { vendor, vendorImages, userID, DeletedImages, DeletedLogo } = this.state;
        isValid = this.validateVendor(values, vendor);


        if (isValid && errors.length === 0) {
            var contactname = values.LastName ? values.FirstName.trim() + ' ' + values.LastName.trim() : values.FirstName ? values.FirstName.trim() : '';

            const data = {
                RegionId: CONFIG.REGION_ID,
                VendorID: vendor.VendorID,
                VendorEmail: vendor.VendorEmail,
                VendorUserName: vendor.VendorUserName,
                VendorUserID: vendor.VendorUserID === "" ? userID : vendor.VendorUserID,//Admin add vendor userID is admin`s.
                VendorTypeID: vendor.VendorTypeID,
                CompanyName: values.CompanyName,
                Address1: values.Address1,
                Address2: values.Address2,
                City: values.City,
                StateID: vendor.StateID,
                CountryID: vendor.CountryID,
                ZipCode: values.ZipCode,
                Phone: values.Phone,
                Description: vendor.Description,
                Email: values.Email,
                Website: values.Website,
                Logo: vendor.Logo,
                ContactName: contactname,
                ContactHeadShot: values.ContactHeadShot,
                ContactEmail: values.ContactEmail,
                ContactPhone: values.ContactPhone,
                VendorCategoryIDs: vendor.VendorCategoryIDs,
                IsInvoiceMe: vendor.IsInvoiceMe,

                IsVendorCategoryUpdated: vendor.IsVendorCategoryUpdated,
                IsVendorImageUpdated: vendor.IsVendorImageUpdated,
                VendorImages: vendorImages ? vendorImages.toString() : '',
                DeletedImages: DeletedImages.toString(),
                DeletedLogo: DeletedLogo.toString(),
            }

            let token = localStorage.getItem('accessKey');
            if (token) {
                axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
            }

            axios.post(CONFIG.API_URL + 'vendor', data)
                .then(response => {
                    if (response.status === 200) {
                        if (vendor.VendorID > 0) {
                            this.props.dispatch(setSnackbar(true, "success", "Vendor Profile updated successfully"));
                        } else {
                            this.props.dispatch(setSnackbar(true, "success", "Vendor Profile created successfully"));
                        }
                        this.cancelVendor();
                    }
                })
                .catch(err => {
                    if (err.response !== null && err.response !== undefined) {
                        this.props.dispatch(setSnackbar(true, "error", err.response.data));
                    }
                    else {
                        this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                    }
                });
        }
        else
            this.props.dispatch(hideLoader());
    }

    validateVendor(values, vendor) {
        const { userType, descriptionCount, isInvoiceMe, isAdd } = this.state;

        var errorCount = 0;
        var descriptionWordCount = 50;
        var errors = {};
        if (vendor.VendorUserID == 0) {
            this.setState({ WarningCss: "counter-box last-box-border WarningBox" });
            errors["vendorUserError"] = "Vender is required";
            errorCount = errorCount + 1;
            window.scrollTo(0, 0);
        }

        if (vendor.VendorCategoryIDs === "" || vendor.VendorCategoryIDs === null) {
            errors["categoryError"] = "Category is required";
            errorCount = errorCount + 1;
        }

        if (!vendor.Phone) {
            errors["phoneError"] = "Phone is required";
            errorCount = errorCount + 1;
        }
        if (CONFIG.REGION_ID !== REGIONS.UK) {
            if (vendor.StateID === "") {
                errors["stateError"] = "State is required";
                errorCount = errorCount + 1;
            }
            if (vendor.CountryID === "0") {
                errors["countryError"] = "Country is required";
                errorCount = errorCount + 1;
            }
        }

        if (vendor.VendorTypeID === VENDORTYPE.Premium && userType !== USERTYPES.Admin) {

            descriptionWordCount = 250;

            if (!values.FirstName) {
                errors["FirstName"] = "First Name is required";
                errorCount = errorCount + 1;
            }
            if (!values.LastName) {
                errors["LastName"] = "Last Name is required";
                errorCount = errorCount + 1;
            }
            if (!values.ContactHeadShot) {
                errors["ContactHeadShot"] = "Job Titile is required";
                errorCount = errorCount + 1;
            }
            if (!values.ContactEmail) {
                errors["contactEmailError"] = "Email is required";
                errorCount = errorCount + 1;
            }
            if (!values.Website) {
                errors["webSiteError"] = "Website is required";
                errorCount = errorCount + 1;
            }
            if (!vendor.ContactPhone) {
                errors["contactPhoneError"] = "Phone is required";
                errorCount = errorCount + 1;
            }

            if (!vendor.Email) {
                errors["emailError"] = "Email is required";
                errorCount = errorCount + 1;
            }

            if (isAdd) {

                if (vendor.Description === "" || descriptionCount === 0) {
                    errors["Description"] = "Description is required";
                    errorCount = errorCount + 1;
                }
            } else {
                if (vendor.Description === "") {
                    errors["Description"] = "Description is required";
                    errorCount = errorCount + 1;
                }
            }

            if (isInvoiceMe) {
                if (!vendor.IsInvoiceMe) {
                    errors["invoiceMeError"] = "Invoice is required";
                    errorCount = errorCount + 1;
                }
            }

            if (vendor.Description) {
                if (descriptionCount > descriptionWordCount) {
                    errors["Description"] = "Description should not exceed " + descriptionWordCount + " words";
                    errorCount = errorCount + 1;
                }
            }

        } else {
            // if (vendor.VendorCategoryIDs !== "") {
            //     var split = vendor.VendorCategoryIDs.split(",");

            //     if (split.length > 1) {
            //         errors["categoryError"] = 'Category cannot be selected more than one';
            //         errorCount = errorCount + 1;
            //     }
            // }
            if (userType !== USERTYPES.Admin) {
                if (vendor.Description) {
                    // var wordcount = tinymce.activeEditor.plugins.wordcount;

                    if (descriptionCount > descriptionWordCount) {
                        //errors["Description"] = "Description should not exceed " + descriptionWordCount + " words";
                        errors["Description"] = "Upgrade your listing to add description with more than " + descriptionWordCount + " words!";

                        errorCount = errorCount + 1;
                    }
                }
            }
        }

        this.setState({ errors });
        return errorCount > 0 ? false : true;
    }

    cancelVendor() {
        const { userType } = this.state;
        var path = "";
        if (userType === USERTYPES.Broker) {
            path = "/user/vendors";
        } else {
            path = "/admin/vendors";
        }

        const { from } = { from: { pathname: path } };
        this.props.history.push(from);
    }

    resetVendorForm() {
        const { vendorNonEdit } = this.state;
        var vendor = this.state.vendor;
        var vendorImages = [];
        if (this.props.match.params.vendorId) {
            if (vendorNonEdit) {
                vendor = this.copyToVendor(vendorNonEdit);
                vendorImages = vendorNonEdit.NVendorImages ? Array.from(vendorNonEdit.NVendorImages.split(",")) : [];
            }
        } else {
            vendor = this.initializeVendorObject();
        }
        this.vendorForm && this.vendorForm.reset();
        this.setState({ vendor, vendorImages, errors: {}, imageToCrop: null, imageToCropList: [], open: false, crop: this.props.crop, uploadError: false, type: '' });
    }

    removeVendorLogo() {
        const { vendor, DeletedLogo } = this.state;
        DeletedLogo.push(vendor.Logo);
        vendor.Logo = '';
        this.setState({ vendor, DeletedLogo });
    }

    removeVendorImages(image) {

        var vendorImages = this.state.vendorImages;
        const { vendor, DeletedImages } = this.state;
        vendor.IsVendorImageUpdated = true;
        DeletedImages.push(image);

        var indexOfVendorImages = vendorImages.indexOf(image);
        if (indexOfVendorImages > -1) {
            vendorImages.splice(indexOfVendorImages, 1);
        } else {
            vendorImages.push(image);
        }
        this.setState({ vendorImages, vendor, DeletedImages });
    }

    uploadVendorLogo(file) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }

        if (!CONFIG.ISDEV) {
            axios.post(CONFIG.API_URL + 'vendor/upload/logo', formData).then(response => {
                if (response.status === 200) {
                    const { vendor } = this.state;
                    vendor.Logo = response.data;
                    this.setState({ vendor });
                }
            });
        } else {
            axios.get(CONFIG.API_URL + 'home/upload/image/test').then(response => {
                if (response.status === 200) {
                    const { vendor } = this.state;
                    vendor.Logo = response.data;
                    this.setState({ vendor });
                }
            });
        }
    }

    uploadVendorImages(file) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }

        if (!CONFIG.ISDEV) {
            axios.post(CONFIG.API_URL + 'vendor/upload/image', formData).then(response => {
                if (response.status === 200) {
                    var result = response.data;
                    var vendorImages = this.state.vendorImages;

                    vendorImages.push(result);
                    this.setState({ vendorImages });
                }
            });
        } else {
            axios.get(CONFIG.API_URL + 'home/upload/image/test').then(response => {
                if (response.status === 200) {
                    var result = response.data;
                    var vendorImages = this.state.vendorImages;

                    vendorImages.push(result);
                    this.setState({ vendorImages });
                }
            });
        }
    }

    toggleVendorModal() {
        this.setState(state => ({ vendorModal: !state.vendorModal }));
        this.setState({ WarningCss: "counter-box last-box-border" });
        let errors = {};
        errors["vendorUserError"] = "";
        this.setState({ errors });

    }

    toggleVendorCategoryModal() {
        this.setState(state => ({ vendorCategoryModal: !state.vendorCategoryModal }));
    }

    parentVendorMethod(id, firstname) {
        var vendorModal = false;
        const { vendor } = this.state;

        if (id !== 0) {

            vendor.VendorUserID = id;
            vendor.VendorUserName = firstname;

        }
        this.setState({ vendorModal, vendor });

    }

    parentVendorCategoriesMethod(ids, names) {
        var vendorCategoryModal = false;
        const { vendor, errors } = this.state;
        vendor.IsVendorCategoryUpdated = true;

        if (ids !== undefined) {
            if (ids) {
                vendor.VendorCategoryIDs = ids;
                vendor.VendorCategories = names;
                errors["categoryError"] = "";
            } else {
                vendor.VendorCategoryIDs = '';
                vendor.VendorCategories = '';
                errors["categoryError"] = "Category is required";
            }
        }
        this.setState({ vendorCategoryModal, vendor, errors });
    }

    saveImage(type, file) {
        this.setState({ type });

        this.setState({ crop: this.props.crop }, () => {
            if (file.length > 0) {
                file = file[0];
                const i = new Image()
                i.onload = () => {
                    let reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload = () => {
                        let lastUploadedFile = {
                            name: file.name,
                            contentType: file.type,
                            content: reader.result,
                            preview: URL.createObjectURL(file),
                            width: i.width,
                            height: i.height,
                            naturalWidth: i.naturalWidth,
                            naturalHeight: i.naturalHeight
                        };

                        let imgWidth = lastUploadedFile.width <= CONFIG.VendorLogoWidth ? lastUploadedFile.width : CONFIG.VendorLogoWidth;
                        let imgHeight = lastUploadedFile.height <= CONFIG.VendorLogoHeight ? lastUploadedFile.height : CONFIG.VendorLogoHeight;

                        this.setState({
                            crop: { width: imgWidth, height: imgHeight, aspect: this.props.crop.aspect, keepSelection: this.props.keepSelection },
                            uploadError: false
                        });

                        if (this.state.imageToCrop) {
                            let { imageToCropList } = this.state;
                            imageToCropList.push(lastUploadedFile);
                            this.setState({ imageToCropList: imageToCropList });
                        } else {
                            this.setState({ imageToCrop: lastUploadedFile, open: true });
                        }
                    };

                    reader.onabort = () => {
                        this.setState({
                            uploadError: true
                        });
                    };

                    reader.onerror = () => {
                        this.setState({
                            uploadError: true
                        });
                    }
                }
                i.src = URL.createObjectURL(file)
            } else {
                this.setState({
                    uploadError: true
                });
            }
        });
    }

    renderAcceptedFileTypes = () => {
        let typesStr = '';
        if (this.props.allowedTypes) {
            this.props.allowedTypes.forEach((type /*, index*/) => {
                typesStr += `${type}, `;
            });
        }
        return typesStr;
    };

    /** Cropper Methods Start **/
    onImageLoaded = (image) => {
        this.imageRef = image;
    };

    onChange = (pixel, crop) => {
        if (crop.height === 0 || crop.width === 0) {
            return;
        }
        this.setState({ crop: crop, pixelCrop: pixel });
    };

    onAccept = () => {
        const { pixelCrop } = this.state;
        this.makeClientCrop(pixelCrop);
    }

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const { imageToCrop, type } = this.state;
            const blob = await this.getCroppedImg(
                this.imageRef,
                crop,
                imageToCrop.name
            );
            var lthis = this;

            var croppedImageUrl = window.URL.createObjectURL(blob);
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
                var base64data = reader.result;
                var formData = {
                    Base64StringFile: base64data.split(",")[1],
                    Name: imageToCrop.name,
                    RegionID: CONFIG.REGION_ID
                }

                let token = localStorage.getItem('accessKey');

                if (token) {
                    axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                }

                if (!CONFIG.ISDEV) {
                    if (type === "logo") {
                        axios.post(CONFIG.API_URL + 'vendor/upload/logo', formData).then(response => {
                            if (response.status === 200) {
                                const { vendor } = lthis.state;
                                vendor.Logo = response.data;
                                lthis.setState({ croppedImageUrl, vendor, imageToCrop: null, imageToCropList: [], open: false, crop: lthis.props.crop });
                            }
                        });
                    } else {
                        axios.post(CONFIG.API_URL + 'vendor/upload/image', formData).then(response => {
                            if (response.status === 200) {
                                var result = response.data;
                                const { vendor, vendorImages } = lthis.state;
                                vendor.IsVendorImageUpdated = true;

                                vendorImages.push(result);
                                lthis.setState({ croppedImageUrl, vendor, vendorImages, imageToCrop: null, imageToCropList: [], open: false, crop: lthis.props.crop });
                            }
                        });
                    }
                } else {
                    axios.get(CONFIG.API_URL + 'home/upload/image/test').then(response => {
                        if (response.status === 200) {
                            if (type === "logo") {
                                const { vendor } = this.state;
                                vendor.Logo = response.data;
                                this.setState({ croppedImageUrl, vendor, imageToCrop: null, imageToCropList: [], open: false, crop: this.props.crop });
                            } else {
                                var result = response.data;
                                const { vendor, vendorImages } = this.state;
                                vendor.IsVendorImageUpdated = true;

                                vendorImages.push(result);
                                this.setState({ croppedImageUrl, vendor, vendorImages, imageToCrop: null, imageToCropList: [], open: false, crop: this.props.crop });
                            }
                        }
                    });
                }
            }
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(blob);
            }, 'image/jpeg');
        });
    }

    onCancel = () => {
        this.setState({ imageToCrop: null, imageToCropList: [], open: false, crop: this.props.crop });
    };
    /** Cropper Methods End **/

    render() {
        const { vendor, stateList, countryList, vendorModal, vendorCategoryModal, vendorImages, userType,
            errors, isAdd, isInvoiceMe, imageToCrop, open, crop, type } = this.state;

        var title = vendor.VendorID > 0 ? "Edit Company Profile" : "Add New Company ";
        // document.title = CONFIG.PAGE_TITLE + " " + title;

        return (
            isAdd || (!isAdd && vendor.CompanyName) ?
                <div>
                    <Helmet
                        title={title}
                        addPostfixTitle={true}
                        keywords="Edit Company Profile, Add New Company, Company"
                    />
                    <div className="vendor-profile-header form-back-shadow margin-t-15">
                        <Row>
                            <Col sm={12}>
                                <Row>
                                    <h6>{title}</h6>
                                    {userType === USERTYPES.Admin ?
                                        <div className={this.state.WarningCss}>
                                            <h5>Contact</h5>
                                            <h4 className="cursor-pointer"> {vendor.VendorUserID > 0 ? <Link onClick={this.toggleVendorModal.bind(this)}><Label className="cursor-pointer">{vendor.VendorUserName}</Label> </Link> : 'No vendor found'}</h4>
                                            <p className="float-right"><Link onClick={this.toggleVendorModal.bind(this)}><FontAwesomeIcon icon={faEdit} /> {vendor.VendorUserID > 0 ? "Change" : "Assign Vendor "}</Link></p>
                                            {errors.vendorUserError ? <p><span className="vendor-profile-error">{errors.vendorUserError}</span> </p> : ''}
                                        </div>
                                        : ''}
                                </Row>
                            </Col>
                        </Row>
                    </div>

                    {vendorModal ? <AssignVendor isOpen={vendorModal} parentMethod={this.parentVendorMethod} userID={vendor.VendorUserID} /> : ''}
                    {vendorCategoryModal ? <VendorCategories isOpen={vendorCategoryModal} parentMethod={this.parentVendorCategoriesMethod} vendorID={vendor.VendorID} vendorCategoryNames={vendor.VendorCategories} VendorCategoryIDs={vendor.VendorCategoryIDs} /> : ''}
                    <div className="form-back-shadow add-new-company">
                        <div className="filters-search">
                            <h5>Company Profile</h5>
                            <AvForm model={vendor} onSubmit={this.saveVendor.bind(this)} ref={c => (this.vendorForm = c)} id="LSS_company_profile_form">
                                <Row>
                                    <Col sm={6}>
                                        <div>
                                            <AvField name="CompanyName" label="Company Name:*" type="text" maxLength="100"
                                                value={vendor.CompanyName}
                                                validate={{
                                                    required: { value: true, errorMessage: 'Company Name is required' },
                                                    maxLength: { value: 100 }
                                                }} />
                                        </div>
                                        <div>
                                            <AvField name="Address1" label="Address Line 1:*" type="text" maxLength="1000"
                                                value={vendor.Address1}
                                                validate={{
                                                    required: { value: true, errorMessage: 'Address Line1 is required' },
                                                    maxLength: { value: 1000 }
                                                }} />
                                        </div>
                                        <div>
                                            <AvField name="Address2" label="Address Line 2:" type="text" maxLength="1000"
                                                value={vendor.Address2} />
                                        </div>
                                        <div>
                                            <AvField name="City" label={CONFIG.REGION_ID !== REGIONS.UK ? "City:*" : "Town/City:*"} type="text" maxLength="100"
                                                value={vendor.City}
                                                validate={{
                                                    required: { value: true, errorMessage: CONFIG.REGION_ID === REGIONS.UK ? "Town/City is required" : 'City is required' },
                                                    maxLength: { value: 100 }
                                                }} />
                                        </div>
                                        {CONFIG.REGION_ID !== REGIONS.UK ?
                                            <React.Fragment>
                                                <div>
                                                    <AvField type="select" name="StateID" value={vendor.StateID}
                                                        onChange={(e) => this.onStateChange(e)}
                                                        label="State:*"
                                                    >
                                                        <option value="">--Select--</option>
                                                        {stateList ? stateList.map(n => {
                                                            return (
                                                                <option key={n.Id} className={n.Id === '' ? "optHead" : ''}
                                                                    disabled={n.Id === '' ? true : false}
                                                                    value={n.Id}>
                                                                    {n.Id}
                                                                </option>
                                                            );
                                                        }) : ''}
                                                    </AvField>
                                                    <span className="errorMessage margin-t-7"> {errors.stateError}</span>
                                                </div>
                                                <div>
                                                    <AvField type="select" name="CountryID" value={vendor.CountryID}
                                                        onChange={(e) => this.onCountryChange(e)}
                                                        label="Country:*" disabled
                                                    >
                                                        <option value="0">--Select--</option>
                                                        {countryList ? countryList.map(n => {
                                                            return (
                                                                <option key={n.Id} className={n.Id === '' ? "optHead" : ''}
                                                                    disabled={n.Id === '' ? true : false}
                                                                    value={n.Id}>
                                                                    {n.Code}
                                                                </option>
                                                            );
                                                        }) : ''}
                                                    </AvField>
                                                    <span className="errorMessage margin-t-7"> {errors.countryError}</span>
                                                </div>
                                            </React.Fragment>
                                            : ''}
                                        <div>
                                            <AvField name="ZipCode" label={CONFIG.REGION_ID === REGIONS.UK ? "Postcode:*" : "Zip Code:*"} type="text" maxLength="20"
                                                value={vendor.ZipCode}
                                                validate={{
                                                    required: { value: true, errorMessage: CONFIG.REGION_ID === REGIONS.UK ? "Postcode is required" : 'Zip Code is required' },
                                                    maxLength: { value: 20 }
                                                }} />
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        {vendor.VendorTypeID === VENDORTYPE.Premium ?
                                            <div>
                                                <Label className="margin-b-3">Logo:</Label>
                                                <div className="logo-vendor-size">
                                                    {!vendor.Logo ?
                                                        <React.Fragment>
                                                            <div>
                                                                {open ?
                                                                    <Modal isOpen={open} className="image-cropper">
                                                                        <ModalHeader>Image cropper
                                                                        </ModalHeader>
                                                                        <ModalBody>
                                                                            <div basic style={{ margin: "0px" }} textAlign="center">
                                                                                <ReactCrop src={imageToCrop.preview} crop={crop}
                                                                                    onImageLoaded={this.onImageLoaded}
                                                                                    onChange={this.onChange} />
                                                                            </div>
                                                                        </ModalBody>
                                                                        <ModalFooter>
                                                                            <Button className="reset-btn margin-r-0" onClick={() => { this.onCancel() }}>Cancel</Button>
                                                                            <Button className="accept-btn" onClick={this.onAccept}>Accept</Button>
                                                                        </ModalFooter>
                                                                    </Modal>
                                                                    : ''
                                                                }
                                                            </div>
                                                            {this.state.uploadError && type === 'logo' ? (
                                                                <p className="v-marg-error">
                                                                    <p className="margin-b-3 error-msg"><em> Accepted file types: {this.props.allowedTypes.toString()}</em></p>

                                                                    <p className="margin-b-3 error-msg"><em>File size must not exceed <b>{this.props.maxFileSize} KB.</b></em></p>
                                                                </p>
                                                            ) : null}
                                                            <Dropzone
                                                                disableClick={true}
                                                                onDrop={this.saveImage.bind(this, 'logo')}
                                                                className='dropzone'
                                                                activeClassName='active-dropzone'
                                                                multiple={false}
                                                                maxSize={this.props.maxFileSize * 1000}
                                                                accept={this.renderAcceptedFileTypes()}
                                                            >
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <section>
                                                                        <div {...getRootProps()} className="drop-file-select margin-b-15">
                                                                            <input {...getInputProps()} />
                                                                            <p><FontAwesomeIcon icon={faFileUpload} /> Drag and drop files here or click to select files</p>
                                                                        </div>
                                                                    </section>
                                                                )}
                                                            </Dropzone>
                                                        </React.Fragment>
                                                        :
                                                        <React.Fragment>
                                                            <div className="vendor-logo">
                                                                <img className="" src={'/VendorLogos/' + vendor.Logo} onError={(e) => { e.target.onerror = null; e.target.src = vendor_no }} alt="Vendor Logos" />
                                                            </div>
                                                            <div className="type-container" onClick={() => this.removeVendorLogo()}><img src={deleteicon} alt="Delete and Submit" /></div>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </div>
                                            : ''}
                                        <div className="height-67">
                                            <Label>Category:*</Label>
                                            <Row className="displaycategories">
                                                <Col sm={3} className="padding-remove">
                                                    <p className="margin-b-0 categoriesbtn">
                                                        <Link onClick={this.toggleVendorCategoryModal.bind(this)}><FontAwesomeIcon icon={faPencilAlt} /> Assign Category</Link>
                                                    </p>
                                                </Col>
                                                <Col sm={9} className="padding-remove">
                                                    <h4 className="vendorname-categories cursor-pointer">{vendor.VendorCategories ? <Link className="cursor-pointer" onClick={this.toggleVendorCategoryModal.bind(this)}><Label className="cursor-pointer">{vendor.VendorCategories}</Label></Link> : <span>No categories</span>}</h4>
                                                </Col>

                                            </Row>
                                            {errors.categoryError ? <span className="vendor-profile-categories">{errors.categoryError}</span> : ''}
                                        </div>
                                        <div>
                                            <AvField name="Phone" label="Phone:*" placeholder={CONFIG.REGION_ID !== REGIONS.UK ? "xxx-xxx-xxxx" : "xxx xxxx xxxx"} type="tel"
                                                value={vendor.Phone}
                                                maxLength={CONFIG.REGION_ID !== REGIONS.UK ? "12" : "13"}
                                                validate={
                                                    {
                                                        tel: {
                                                            pattern: CONFIG.REGION_ID !== REGIONS.UK ? /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/ : /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{4}[\s.-]\d{4}$/, errorMessage: 'Phone is invalid'
                                                        }
                                                    }}
                                                onChange={(e) => this.handlePhoneChange(e)} />
                                            <span className="errorMessage margin-t-7">{errors.phoneError}</span>
                                        </div>
                                        {vendor.VendorTypeID === VENDORTYPE.Premium ?
                                            <React.Fragment>
                                                <div>
                                                    <AvField name="Email" label={userType !== USERTYPES.Admin ? "Email:*" : "Email"} type="text"
                                                        maxLength="100"
                                                        value={vendor.Email}
                                                        onChange={(e) => { this.emailChange(e, "Email") }}
                                                        validate={{
                                                            pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$/, errorMessage: 'Email is invalid' }
                                                        }} />
                                                    <span className="errorMessage margin-t-7">{errors.emailError}</span>
                                                </div>
                                                <div>
                                                    <AvField name="Website" label={userType !== USERTYPES.Admin ? "Website:*" : "Website:"} type="text"
                                                        maxLength="100"
                                                        value={vendor.Website}
                                                        onChange={(e) => this.onWebsiteChange(e)}
                                                        validate={{
                                                            pattern: { value: '(https?|ftp)://(www\d?|[a-zA-Z0-9]+)?\.[a-zA-Z0-9-]+(\:|\.)([a-zA-Z0-9.]+|(\d+)?)([/?:].*)?', errorMessage: 'Website is invalid' }
                                                        }} />
                                                    <span className="errorMessage margin-t-7">{errors.webSiteError}</span>
                                                </div>
                                                {isInvoiceMe ?
                                                    <div>
                                                        <AvField name="IsInvoiceMe" lable="Invoice Me:*" label="Invoice Me:*" type="checkbox"
                                                            value={vendor.IsInvoiceMe}
                                                            onChange={(e) => { this.onInvoiceChange(e) }}
                                                        />
                                                        <span className="errorMessage margin-0">{errors.invoiceMeError}</span>
                                                    </div> : ''}
                                            </React.Fragment> : ''}
                                    </Col>
                                    <Col sm={12}>
                                        {vendor.VendorTypeID === VENDORTYPE.Premium ?
                                            <div>
                                                <Label className="margin-b-3">Display Image:</Label>
                                                <div className="multiple-background-vendors">
                                                    <span className="errorMessage error-block">{errors.imagesError}</span>
                                                    {vendorImages.length < 3 ?
                                                        <React.Fragment>
                                                            <div>
                                                                {open ?
                                                                    <Modal isOpen={open} className="image-cropper">
                                                                        <ModalHeader>Image cropper
                                                                        </ModalHeader>
                                                                        <ModalBody>
                                                                            <div basic style={{ margin: "0px" }} textAlign="center">
                                                                                <ReactCrop src={imageToCrop.preview} crop={crop}
                                                                                    onImageLoaded={this.onImageLoaded}
                                                                                    onChange={this.onChange} />
                                                                            </div>
                                                                        </ModalBody>
                                                                        <ModalFooter>
                                                                            <Button className="reset-btn margin-r-0" onClick={() => { this.onCancel() }}>Cancel</Button>
                                                                            <Button className="accept-btn" onClick={this.onAccept}>Accept</Button>
                                                                        </ModalFooter>
                                                                    </Modal>
                                                                    : ''
                                                                }
                                                            </div>
                                                            {this.state.uploadError && type === 'display' ? (
                                                                <p className="margin-b-9">
                                                                    <p className="margin-b-3 error-msg"><em> Accepted file types: {this.props.allowedTypes.toString()}</em></p>

                                                                    <p className="margin-b-3 error-msg"><em>File size must not exceed <b>{this.props.maxFileSize} KB.</b></em></p>
                                                                </p>
                                                            ) : null}
                                                            <Dropzone
                                                                disableClick={true}
                                                                onDrop={this.saveImage.bind(this, 'display')}
                                                                className='dropzone'
                                                                activeClassName='active-dropzone'
                                                                multiple={false}
                                                                maxSize={this.props.maxFileSize * 1000}
                                                                accept={this.renderAcceptedFileTypes()}
                                                            >
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <section>
                                                                        <div {...getRootProps()} className="drop-file-select">
                                                                            <input {...getInputProps()} />
                                                                            <p><FontAwesomeIcon icon={faFileUpload} /> Drag and drop files here or click to select files</p>
                                                                        </div>
                                                                    </section>
                                                                )}
                                                            </Dropzone>
                                                        </React.Fragment>
                                                        : ''}
                                                    <Row>
                                                        {vendorImages.length > 0 && vendorImages.map(image => {
                                                            return (
                                                                <React.Fragment>
                                                                    <Col sm={2}>
                                                                        <div className="display-size">
                                                                            <img src={'/VendorImages/' + image} onError={(e) => { e.target.onerror = null; e.target.src = vendor_no }} className="width-100 height-100" alt="Vendor Images" />
                                                                        </div>
                                                                        <div className="type-container" onClick={() => this.removeVendorImages(image)}><img src={deleteicon} alt="Delete and Submit" /></div>
                                                                    </Col>
                                                                </React.Fragment>)
                                                        })
                                                        }
                                                    </Row>
                                                </div>
                                            </div> : ''}
                                        <div className="description-vendor">
                                            <Label className="add-listing-lable">{vendor.VendorTypeID === VENDORTYPE.Premium && userType !== USERTYPES.Admin ? "Description:*" : "Description:"}</Label>
                                            <Editor
                                                value={vendor.Description}
                                                apiKey={CONFIG.TINY_MCE_KEY}
                                                init={{
                                                    height: 300,
                                                    width: 500,
                                                    menubar: true,
                                                    plugins: ["link", "wordcount"],

                                                }}
                                                onEditorChange={this.onDescriptionChange}
                                            />
                                            <p><span className="errorMessage margintop-9px">{errors.Description}</span></p>
                                        </div>
                                    </Col>
                                    {vendor.VendorTypeID === VENDORTYPE.Premium ?
                                        <React.Fragment>
                                            <Col sm={12}>
                                                <h5>Contact Details</h5>
                                            </Col>
                                            <Col sm={6}>
                                                <div>
                                                    <AvField name="FirstName" label={userType !== USERTYPES.Admin ? "First Name:*" : "First Name:"} type="text" maxLength="50" value={vendor.FirstName} onChange={(e) => this.onKeyChange(e)} />
                                                    <span className="errorMessage margin-t-7">{errors.FirstName}</span>
                                                </div>
                                                <div>
                                                    <AvField name="LastName" label={userType !== USERTYPES.Admin ? "Last Name:*" : "Last Name:"} type="text" maxLength="25" value={vendor.LastName} onChange={(e) => this.onKeyChange(e)} />
                                                    <span className="errorMessage margin-t-7">{errors.LastName}</span>
                                                </div>
                                            </Col>
                                            <Col sm={6}>
                                                <div>
                                                    <AvField name="ContactHeadShot" label={userType !== USERTYPES.Admin ? "Job Title:*" : "Job Title:"} type="text" maxLength="1000" value={vendor.ContactHeadShot} onChange={(e) => this.onKeyChange(e)} />
                                                    <span className="errorMessage margin-t-7">{errors.ContactHeadShot}</span>
                                                </div>
                                                <div>
                                                    <AvField name="ContactEmail" label={userType !== USERTYPES.Admin ? "Email:*" : "Email:"} type="text" value={vendor.ContactEmail} maxLength="100"
                                                        onChange={(e) => { this.emailChange(e, "ContactEmail") }}
                                                        validate={{
                                                            pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$/, errorMessage: 'Email is invalid' }
                                                        }} />
                                                    <span className="errorMessage margin-t-7">{errors.contactEmailError}</span>
                                                </div>
                                            </Col>
                                            <Col sm={6}>
                                                <div>
                                                    <AvField name="ContactPhone" label={userType !== USERTYPES.Admin ? "Phone:*" : "Phone:"} placeholder={CONFIG.REGION_ID !== REGIONS.UK ? "xxx-xxx-xxxx" : "xxx xxxx xxxx"} type="tel"
                                                        maxLength={CONFIG.REGION_ID !== REGIONS.UK ? "12" : "13"}
                                                        value={vendor.ContactPhone}
                                                        validate={
                                                            {
                                                                tel: { pattern: CONFIG.REGION_ID !== REGIONS.UK ? /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/ : /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{4}[\s.-]\d{4}$/, errorMessage: 'Phone is invalid' }
                                                            }} onChange={(e) => this.handleContactPhoneChange(e)} />
                                                    <span className="errorMessage margin-t-7">{errors.contactPhoneError}</span>
                                                </div>
                                            </Col>
                                        </React.Fragment>
                                        : ''}
                                    <Col sm={12} className="text-align-right">
                                        <Link className="freepaid-link" onClick={this.onVendorTypeChange.bind(this)}>{vendor.VendorTypeID === VENDORTYPE.Premium ? "Click here to downgrade" : "Want to see your profile above the scroll? Click here to upgrade!"}</Link>
                                        <Button className="reset-btn" onClick={this.resetVendorForm.bind(this)}>Clear</Button>
                                        <Button className="save-btn" >Submit</Button>
                                        <Button className="cancle-btn" onClick={this.cancelVendor.bind(this)}>Cancel</Button>
                                    </Col>
                                </Row>
                            </AvForm>
                        </div>
                    </div>

                </div >
                : ''
        );
    }
}
export default connect()(VendorProfile);
