const initialState = {
    isLoading: false
};

export default (state = initialState, action) => {
    const { isLoading } = action;
    switch (action.type) {
        case "SHOW_LOADER":
            return { ...state, isLoading };

        case "HIDE_LOADER":
            return { ...state, isLoading };
        default:
            return state;
    }
}

export const showLoader = () => 
    ({
        type: "SHOW_LOADER",
        isLoading: true
    });

export const hideLoader = () =>
    ({
        type: "HIDE_LOADER",
        isLoading: false
    });

