import { createStore, combineReducers } from "redux";
import snackbarReducer from './ducks/snackbarReducer';
import application from './ducks/application';

const reducer = combineReducers({
    snackbar: snackbarReducer,
    application

});

const store = createStore(reducer);

export default store;