import React, { Component } from 'react';
import './AdminHeader.css';

export default class AdminHeader extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div>
            </div>
        );
    }
}