import React from 'react';
import { Row, Col, Table } from 'reactstrap';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { CONFIG } from '../../../Utils/config';
import { CURRENCY, getFormattedFloat, getFormattedInt, REGIONS } from '../../../Utils/utils';
import dashboard from '../../../Assets/Img/dashboard.png';
import knowndev from '../../../Assets/Img/knowndev.png';
import totalfeet from '../../../Assets/Img/totalfeet.png';
import housetotal from '../../../Assets/Img/house-total.png';
import avgrate from '../../../Assets/Img/avg-rate.png';
import capita from '../../../Assets/Img/capita.png';
import population from '../../../Assets/Img/population.png';
import mediaincome from '../../../Assets/Img/medianincome.png';
import telescope from '../../../Assets/Img/telescope.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Collapse from '@material-ui/core/Collapse';
import { faPlus, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faMinus } from '@fortawesome/free-solid-svg-icons';

export class ListingMarketData extends React.Component {

    render() {
        const { property, propertyMI, mvpRadius, miRadiusList, marketSec } = this.props;
        return (
            <React.Fragment>
                <h5 className="property-heading">{property.IsCityBased ? property.City + ' ' : ''}<span className="events-none" onClick={() => this.props.expandSection('market')}>Market Data</span>
                    {property.IsCityBased ? ''
                        :
                        <select type="select" name="MIRadius" value={mvpRadius}
                            onChange={(e) => this.props.onMIRadiusChange(e, property)} className="select-distance">
                            {miRadiusList ? miRadiusList.map(n => {
                                return (
                                    <option key={n.ID} value={n.ID}>{n.Name}</option>
                                );
                            }) : ''}
                        </select>
                    }
                    <i className="powered-by">Powered by <a target="_blank" href={process.env.REACT_APP_STORTRACK_URL + 'products/explorer.html'} rel="noopener noreferrer" title="Learn about development activity, market trends, historical pricing and more">StorTrack's Explorer</a></i>
                    <div className="button-with-icon">
                        <Link onClick={this.props.FAQModel.bind(this)} className="view-property-market">Explore Market Data for this property</Link>
                    </div>
                    <div className="clear"></div>
                    {marketSec ? <FontAwesomeIcon icon={faChevronUp} onClick={() => this.props.expandSection('market')}/> : <FontAwesomeIcon icon={faChevronDown} onClick={() => this.props.expandSection('market')}/>}
                </h5>
                <Collapse in={marketSec} timeout="auto" unmountOnExit>
                    <Table bordered className="market-data-table">
                        <tbody>
                            <tr>
                                <td className="market-data-label">Stores</td>
                                <td className="market-data-value">{propertyMI.MVPNumberOfCompetitors ? getFormattedInt(propertyMI.MVPNumberOfCompetitors) : 'N/A'}</td>
                                <td className="market-data-label">Sq. Ft. Per Capita</td>
                                <td className="market-data-value">{propertyMI.MVPSqftCapita ? getFormattedFloat(propertyMI.MVPSqftCapita) : 'N/A'}</td>
                            </tr>
                            <tr>
                                <td className="market-data-label">Total Sq. Ft.</td>
                                <td className="market-data-value">{propertyMI.TotalSqFt ? getFormattedFloat(propertyMI.TotalSqFt) : 'N/A'}</td>
                                <td className="market-data-label">Population</td>
                                <td className="market-data-value">{propertyMI.MVPPopulation ? getFormattedFloat(propertyMI.MVPPopulation) : 'N/A'}</td>
                            </tr>
                            <tr>
                                <td className="market-data-label">Rentable Sq. Ft.</td>
                                <td className="market-data-value">{propertyMI.TotalRentableSqFt ? getFormattedFloat(propertyMI.TotalRentableSqFt) : 'N/A'}</td>
                                <td className="market-data-label">Median Income</td>
                                <td className="market-data-value">{propertyMI.MVPMedianHouseHoldIncome ? (CONFIG.REGION_ID === REGIONS.UK ? CURRENCY.UK : CURRENCY.US) + getFormattedFloat(propertyMI.MVPMedianHouseHoldIncome) : 'N/A'}</td>
                            </tr>
                            <tr>
                                <td className="market-data-label">Avg Rate/Sq. Ft.</td>
                                <td className="market-data-value">{propertyMI.MVPMarketPSFAverage ? (CONFIG.REGION_ID === REGIONS.UK ? CURRENCY.UK : CURRENCY.US) + getFormattedFloat(propertyMI.MVPMarketPSFAverage) : 'N/A'}</td>
                                <td className="market-data-label">Developments</td>
                                <td className="market-data-value">{propertyMI.MVPKnownApprovedDevelopments ? getFormattedInt(propertyMI.MVPKnownApprovedDevelopments) : 'N/A'}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Collapse>
            </React.Fragment>
        );
    }
}

export default connect()(ListingMarketData);