import React, { Component } from 'react';
import { Row, Col, Table } from 'reactstrap';
import { CONFIG } from '../../../Utils/config';
import {
    getFormattedFloat, getFormattedInt, contactAboutOptions, normalizeInput, PRICEOPTION, REGIONS, getDateFormat
} from '../../../Utils/utils';
import { Carousel } from 'react-responsive-carousel';
import vendor_no from '../../../Assets/Img/ventor-list.png';
import axios from "axios";
import moment from 'moment';
import { connect } from 'react-redux';
import { setSnackbar } from '../../../redux/ducks/snackbarReducer';
import './PropertyReView.css';

class PropertyReView extends Component {

    constructor(props) {
        super(props);
        let contactState = [];
        contactAboutOptions.forEach(() => {
            contactState.push(false);
        });
        this.state = {
            propertyID: 0,
            property: {},
            propertyImages: [],
            isAdmin: false,
            contactPhone: '',
            contactAbout: contactState,
            isLoan: false,
            isMap: true,
        }
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.onLoanOpen = this.onLoanOpen.bind(this);
    }

    componentDidMount() {
        if (this.props.match) {
            if (this.props.match.params.propertyId) {
                this.getPropertyDetail(this.props.match.params.propertyId);
            }
        } else {
            if (this.props.propertyId) {
                this.setState({ isAdmin: this.props.isAdmin });
                this.getPropertyDetail(this.props.propertyId);
            }
        }
    }

    getPropertyDetail(propertyId) {
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.get(CONFIG.API_URL + 'property/review/listing/' + propertyId).then(response => {
            if (response.status === 200) {
                var property = response.data;
                var propertyImages = property.PropertyImages ? Array.from(property.PropertyImages.split(',')) : [];
                //property = this.formatProperty(property);
                this.setState({ property, propertyImages });
            } else if (response.status === 204) {
                const { from } = { from: { pathname: "/pagenotfound" } };
                this.props.history.push(from);
            }
        }).catch(err => {
            if (err.response !== null && err.response !== undefined) {
                this.props.dispatch(setSnackbar(true, "error", err.response.data));
            }
            else {
                this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
            }
        });
    }

    handlePhoneChange({ target: { value } }) {
        var contactPhone = this.state.contactPhone;

        contactPhone = normalizeInput(value, contactPhone);

        this.setState({ contactPhone });
    };

    formatProperty(property) {
        property.BrokerId = property.BrokerId ? property.BrokerId : 0;
        property.NSRF = property.NSRF ? getFormattedFloat(property.NSRF) : '';
        property.GSF = property.GSF ? getFormattedFloat(property.GSF) : '';
        property.BuildingSize = property.BuildingSize ? getFormattedFloat(property.BuildingSize) : '';
        property.CCArea = property.CCArea ? getFormattedFloat(property.CCArea) : '';
        property.LotSize = property.LotSize ? getFormattedFloat(property.LotSize) : '';

        property.CeilingHeight = property.CeilingHeight ? getFormattedInt(property.CeilingHeight) : '';
        property.Price = property.Price ? getFormattedInt(property.Price.toString().replace(/,/g, '')) : '';

        return property;
    }

    onLoanOpen() {
        let isLoan = !this.state.isLoan;
        this.setState({ isLoan: isLoan })
    }

    toggle = (e) => {
        let { contactAbout } = this.state;
        const key = parseInt(e.target.name, 10);
        contactAbout[key] = !contactAbout[key];
        this.setState({ contactAbout: contactAbout });
    };

    mapClick(isMap) {
        this.setState({ isMap: isMap });
    }

    render() {
        const { property, propertyImages, isAdmin } = this.state;

        return (
            <div className="property-details">
                <div className="padding-remove container-property-condition">
                    <Row>
                        <Col md={6} className="property_img">
                            <div className="size">
                                {propertyImages.length > 0 ?
                                    <Carousel className="image-carousel-size">
                                        {propertyImages.map((image) =>
                                            <div className="listing-img-resize review-noimg">
                                                <img src={'/PropertyImages/' + image} onError={(e) => { e.target.onerror = null; e.target.src = vendor_no }} alt="Property Images"/>
                                            </div>
                                        )}
                                    </Carousel>
                                    : <div className="listing-img-resize review-noimg">
                                        {/* <img src={vendor_no} /> */}
                                    </div>
                                }
                            </div>
                        </Col>
                        
                        <Col md={6} className="property-v-description ">
                            {property.Description ?
                            <div><p className="description-heading">Listing Description</p>
                                {/* <p><pre className="myBox3 review-description">{property.Description ? property.Description : "N/A"}</pre></p> */}
                                <p className="myBox3 review-description" dangerouslySetInnerHTML={{ __html: property.Description }} />
                            </div>
                             : ''}
                        </Col>
                        <Col sm={6} className="padding-left-remove">
                            <Row>
                                <Col md={6} className="padding-left-remove table-propertydetails-condition">
                                    <h5 className="property-heading">Property Details</h5>
                                    <Table bordered>
                                        <tbody>
                                            {isAdmin ?
                                                <tr><td>Broker Name</td>
                                                    <td>{property.BrokerName} - {property.BrokerType}</td></tr>
                                                : ''}
                                            <tr><td>Property Name</td>
                                                <td>{property.PropertyName}</td></tr>
                                            <tr><td>Address</td>
                                                <td>{property.Address1}</td></tr>
                                            <tr>
                                                {CONFIG.REGION_ID === REGIONS.US ?
                                                    <td>City</td>
                                                    : CONFIG.REGION_ID === REGIONS.UK ?
                                                        <td>Town/City</td>
                                                        : ""}

                                                <td>{property.City}</td></tr>
                                            {CONFIG.REGION_ID !== REGIONS.UK ?
                                                <React.Fragment>
                                                    <tr><td>State</td>
                                                        <td>{property.StateId}</td></tr>
                                                    <tr><td>Country</td>
                                                        <td>{property.CountryCode}</td></tr>
                                                </React.Fragment>
                                                : ''}
                                            <tr><td>{CONFIG.REGION_ID === REGIONS.UK ? "Postcode" : "Zip Code"}</td>
                                                <td>{property.ZipCode}</td></tr>
                                            <tr>
                                                <td>Property Type</td>
                                                <td>{property.PropertyType}</td>
                                            </tr>
                                            <tr><td>Single Property / Portfolio</td>
                                                <td>{property.IsPortfolio ? 'Portfolio' : 'Single Property'}</td></tr>
                                            
                                            {property.Occupancy ?
                                            <tr>
                                                <td>Occupancy</td>
                                                <td>{getFormattedFloat(property.Occupancy) + "%"}</td>
                                            </tr> : ''}
                                            
                                            {property.IsRoomForExpansion ? 
                                            <tr>
                                                <td>Room For Expansion</td>
                                                <td>{property.IsRoomForExpansion ? 'Yes' : 'No'}</td>
                                            </tr>: ''}

                                            {property.Price ? 
                                            <tr>
                                                <td>
                                                    Price
                                                </td>
                                                <td>{property.Currency + getFormattedInt(property.Price)}</td>
                                            </tr> : ''}

                                            {property.AlternatePriceOptionID ?
                                            <tr>
                                                <td>
                                                    Alternate Price
                                                </td>
                                                <td>
                                                    <span>{property.AlternatePriceOptionName}{(property.AlternatePriceOptionID === 5 || property.AlternatePriceOptionID === 6) && property.AlternatePriceOptionDate !== null ? ": " + moment(property.AlternatePriceOptionDate).format(getDateFormat()) : ''}</span>                                                     
                                                </td>
                                            </tr>:''}
                                        </tbody>
                                    </Table>
                                </Col>

                            </Row>

                        </Col>
                        <Col md={6}>
                            {property.NSRF || property.GSF || property.BuildingSize || property.CCArea || property.YearBuilt
                                || property.LotSize || property.UnitCount || property.NumberofFloors || property.CeilingHeight 
                                || property.NOI || property.CapRate ? 
                            <Row>
                                <Col md={6} className="padding-left-remove table-propertydetails-condition">
                                    <h5 className="property-heading">Features Details</h5>
                                    <Table bordered>
                                        <tbody>
                                            {property.NSRF ? 
                                            <tr>
                                                <td>{CONFIG.REGION_ID === REGIONS.UK ? "Net Rentable Sq. Ft." : "NRSF"}</td>
                                                <td>{getFormattedFloat(property.NSRF) + ' SF'}</td>
                                            </tr> : ''}

                                            {property.GSF ?
                                            <tr>
                                                <td>{CONFIG.REGION_ID === REGIONS.UK ? "Gross Sq. Ft." : "GSF"}</td>
                                                <td>{getFormattedFloat(property.GSF) + ' SF'}</td>
                                            </tr> : ''}

                                            {property.BuildingSize ? 
                                            <tr>
                                                <td>Building Area</td>
                                                <td>{getFormattedFloat(property.BuildingSize) + ' SF'}</td>
                                            </tr> : ''}
                                            
                                            {property.CCArea ? 
                                            <tr>
                                                <td>{CONFIG.REGION_ID === REGIONS.UK ? "Climate Controlled Area" : "CC Area"}</td>
                                                <td>{getFormattedFloat(property.CCArea) + ' SF'}</td>
                                            </tr> : ''}
                                            
                                            {property.YearBuilt ? 
                                            <tr>
                                                <td>Year Built</td>
                                                <td>{property.YearBuilt}</td>
                                            </tr> : ''}

                                            {property.LotSize ? 
                                            <tr>
                                                <td>Lot Size</td>
                                                <td><span>{getFormattedFloat(property.LotSize)}{property.LotSizeMeasurementId === 1 ? " Acres" : " SF"}</span></td>
                                            </tr> : ''}

                                            {property.UnitCount ? 
                                            <tr>
                                                <td>Unit Count</td>
                                                <td>{getFormattedInt(property.UnitCount) + ' Unit(s)'}</td>
                                            </tr> : ''}

                                            {property.NumberofFloors ? 
                                            <tr>
                                                <td>Number of Floors</td>
                                                <td>{property.NumberofFloors}</td>
                                            </tr> : ''}

                                            {property.CeilingHeight ? 
                                            <tr>
                                                <td>Ceiling Height</td>
                                                <td>{getFormattedInt(property.CeilingHeight) + ' feet'}</td>
                                            </tr> : ''}
                                            
                                            {property.NOI ? 
                                            <tr>
                                                <td>{CONFIG.REGION_ID === REGIONS.UK ? "Net Operating Income" : "NOI"}</td>
                                                <td>{property.NOICurrencyCode + getFormattedInt(property.NOI)}</td>
                                            </tr> : ''}
                                            
                                            {property.CapRate ? 
                                            <tr>
                                                <td>Cap Rate</td>
                                                <td>{getFormattedFloat(property.CapRate) + "%"}</td>
                                            </tr> : ''}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            : ''}
                        </Col>

                    </Row>
                </div>
            </div>
        );
    }
}

export default connect()(PropertyReView);