import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import axios from "axios";
import { CONFIG } from '../../../Utils/config';
import './PropertyGeoCode.css';
import GoogleMapReact from "google-map-react";
// import { showLoader, hideLoader } from '../../../redux/ducks/application';

class PropertyGeoCode extends Component {
    constructor(props) {
        super(props);

        this.state = {
            propertyList: [],
            totalCount: 0,
        }
    }

    componentDidMount() {
        this.getPropertyList(10);
    }

    initGeocoder = (map, maps) => {
        const geocoder = new maps.Geocoder();

        this.setState({
            geocoder: geocoder,
            map: map,
            maps: maps,
        })
    };

    getPropertyList(count) {
        axios.get(CONFIG.API_URL + 'DataMigration/property/geocode/list/' + count).then(response => {
            if (response.status === 200) {
                this.setState({ propertyList: response.data.propertyList, totalCount: response.data.totalCount });
            }
        })
            .catch(err => {
            });
    }

    updatePropertyGeoCode(property) {
        axios.post(CONFIG.API_URL + 'DataMigration/update/property/geocode', property).then(response => {
            if (response.status === 200) {
            }
        })
            .catch(err => {
            });
    }

    getPropertyGeoCode(property) {
        const { maps, geocoder, propertyList } = this.state;

        geocoder.geocode({
            address: property.Address1 + ', ' + property.City + ', ' + property.StateId,
            componentRestrictions: {
                country: 'USA',
                postalCode: property.ZipCode
            }

        }, (results, status) => {
            if (status === maps.GeocoderStatus.OK) {
                const { lat, lng } = results[0].geometry.location;
                property.Latitude = lat();
                property.Longitude = lng();
                property.Status = "Done";
                this.updatePropertyGeoCode(property);
                this.setState({ propertyList });
            } else {
                console.error("GEOCODING ERROR", { results }, { status });
                if (status === "ZERO_RESULTS") {
                    // this.props.dispatch(hideLoader());
                    property.Status = "No result";
                    this.updatePropertyGeoCode(property);
                    this.setState({ propertyList });
                }
            }
        });
    }

    processGeoCode() {
        const { propertyList } = this.state;
        propertyList.map(property => {
            this.getPropertyGeoCode(property);
        })
    }

    render() {
        const { propertyList, totalCount } = this.state;

        return (
            <div className="vendor-profile-header form-back-shadow col-sm-7 marginall-auto margin-t-15">
                <div className="displaynone">
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: CONFIG.MapAPIKey }}
                        defaultCenter={{
                            lat: parseFloat(30.338745408984124),
                            lng: parseFloat(-81.75199789894742)
                        }}
                        defaultZoom={15}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => this.initGeocoder(map, maps)}
                    />
                </div>
                <div>
                    <Row>
                        <Col>
                            <p>{totalCount + " Records"}</p>
                            <Button onClick={this.processGeoCode.bind(this)}>Process Geocode</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button onClick={this.getPropertyList.bind(this)}>Get Record</Button>

                            {propertyList.length > 0 ?
                                <React.Fragment>

                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="date-width">Property Name</TableCell>
                                                <TableCell className="date-width">Latitude</TableCell>
                                                <TableCell className="date-width">Longitude</TableCell>
                                                <TableCell className="date-width">Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {propertyList.map((record) =>
                                                <TableRow key={record.PropertyId} >
                                                    <TableCell>{record.PropertyName}</TableCell>
                                                    <TableCell>{record.Latitude}</TableCell>
                                                    <TableCell>{record.Longitude}</TableCell>
                                                    <TableCell>{record.Status ? record.Status : ''}</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </React.Fragment>
                                : <TableCell>No properties found.</TableCell>
                            }</Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default connect()(PropertyGeoCode);