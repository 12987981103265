import React, { Component } from 'react';
import { BrowserRouter, Router, Switch } from 'react-router-dom';
import { HomeLayoutRoute, UserLayoutRoute, AdminLayoutRoute } from './RouteLayout';
import {
    Home, UserRegistration, UserManagement, Authendication, ForgotPassword, ResetPassword, Categories,
    AddListing, Advertise, AboutUs, VendorSearchList, VendorProfile, AdminProfile,
    SearchVendor, VendorList, DashBoard, SearchProperty, WatchList, PageNotFound, PropertyGeoCode,
    AddProperty, MyProfile, Profile, VendorProfileList, SearchResult, PropertyList, PropertyView,
    // LoanCalculator, NewsAndEvents, NewsEvents, NewsDetails, NewsSearchList
} from '../Views';
import History from '../History';

class RouteComp extends Component {
    render() {
        return (
            <BrowserRouter>
                <Router basename='/index.html' history={History}>
                    <Switch>
                        <HomeLayoutRoute path="/resetpassword/:PasswordResetToken" component={ResetPassword} />
                        {/* <HomeLayoutRoute path="/resetpassword/" component={ResetPassword} /> */}
                        <HomeLayoutRoute path="/forgotpassword" component={ForgotPassword} />
                        <HomeLayoutRoute exact path="/" component={Home} />
                        <HomeLayoutRoute exact path="/signin" component={Home} />
                        <HomeLayoutRoute exact path="/contact" component={Home} />
                        <HomeLayoutRoute exact path="/signup" component={UserRegistration} />
                        {/* <HomeLayoutRoute exact path="/valuation" component={PropertyValuation} /> */}
                        <HomeLayoutRoute exact path="/advertise" component={Advertise} />
                        <HomeLayoutRoute exact path="/aboutus" component={AboutUs} />
                        {/* <HomeLayoutRoute exact path="/loanCalculator" component={LoanCalculator} /> */}
                        <HomeLayoutRoute path="/logins/:authurization/" component={Authendication} />
                        <HomeLayoutRoute path="/login/:authurization/" component={Authendication} />

                        {/* <HomeLayoutRoute exact path="/search/:searchid/" component={SearchResult} /> */}
                        {/* <HomeLayoutRoute exact path="/newsAndEvents/:keyword/:typeId/" component={NewsSearchList} /> */}
                        {/* <HomeLayoutRoute exact path="/news/:titlename/:newsId/" component={NewsDetails} /> */}
                        {/* <HomeLayoutRoute exact path="/newsAndEvents" component={NewsEvents} /> */}
                        <HomeLayoutRoute exact path="/process/geocode" component={PropertyGeoCode} />

                        <HomeLayoutRoute exact path="/listings/" component={SearchResult} />
                        <HomeLayoutRoute exact path="/listings/:searchId/" component={SearchResult} />
                        <HomeLayoutRoute exact path="/vendors" component={SearchVendor} />
                        <HomeLayoutRoute exact path="/vendors/list/:keyword" component={VendorList} />
                        <HomeLayoutRoute exact path="/vendors/list/:parentCategoryName/:childCategoryID/" component={VendorList} />
                        <HomeLayoutRoute exact path="/vendors/list/:parentCategoryName/:childCategoryName/:childCategoryID/" component={VendorList} />
                        <HomeLayoutRoute exact path="/vendors/profile/:permalink" component={Profile} />
                        <HomeLayoutRoute exact path="/property/:propertyAddress" component={PropertyView} />
                        <HomeLayoutRoute exact path="/property/:stateId/:city/:address/:propertyName/:sourceId" component={PropertyView} />
                        <HomeLayoutRoute exact path="/pagenotfound" component={PageNotFound} />

                        <AdminLayoutRoute path="/admin/usermanagement" component={UserManagement} />
                        <AdminLayoutRoute path="/admin/editlisting/:newsId/" component={AddListing} />
                        <AdminLayoutRoute path="/admin/addlisting" component={AddListing} />
                        {/* <AdminLayoutRoute path="/admin/newsandevents" component={NewsAndEvents} /> */}
                        <AdminLayoutRoute path="/admin/vendors" component={VendorSearchList} />
                        <AdminLayoutRoute path="/admin/vendor-profile/:vendorId" component={VendorProfile} />
                        <AdminLayoutRoute path="/admin/vendor-profile" component={VendorProfile} />
                        <AdminLayoutRoute path="/admin/categories" component={Categories} />
                        <AdminLayoutRoute path="/admin/properties" component={SearchProperty} />
                        <AdminLayoutRoute path="/admin/editproperty/:propertyId/:statusId" component={AddProperty} />
                        <AdminLayoutRoute path="/admin/editproperty/:propertyId/" component={AddProperty} />
                        <AdminLayoutRoute path="/admin/addproperty" component={AddProperty} />
                        <AdminLayoutRoute path="/admin/profile" component={AdminProfile} />

                        <UserLayoutRoute exact path="/user/dashboard" component={DashBoard} />
                        <UserLayoutRoute exact path="/user/vendors" component={VendorProfileList} />
                        <UserLayoutRoute exact path="/user/profile" component={MyProfile} />
                        <UserLayoutRoute path="/user/vendor-profile/:vendorId" component={VendorProfile} />
                        <UserLayoutRoute path="/user/vendor-profile" component={VendorProfile} />
                        <UserLayoutRoute exact path="/user/profile/:permalink" component={Profile} />
                        <UserLayoutRoute exact path="/user/watchlist" component={WatchList} />
                        <UserLayoutRoute exact path="/user/properties" component={PropertyList} />
                        <UserLayoutRoute exact path="/user/addproperty" component={AddProperty} />
                        <UserLayoutRoute exact path="/user/addproperty/:checkoutStatus/:propertyId/:isPropertyAdd/:statusChangeTo" component={AddProperty} />
                        <UserLayoutRoute path="/user/editproperty/:propertyId/:statusId" component={AddProperty} />
                        <UserLayoutRoute path="/user/editproperty/:propertyId" component={AddProperty} />
                    </Switch>
                </Router>
            </BrowserRouter>
        );
    }
}

export default RouteComp;