import React, { Component } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import axios from "axios";
import { CONFIG } from '../../../Utils/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { setSnackbar } from '../../../redux/ducks/snackbarReducer';
import { showLoader, hideLoader } from '../../../redux/ducks/application';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import './EditAdminProfile.css';

class EditAdminProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            user: this.props.user,
            userEmail: this.props.user.Login,
            isProfileEdit: true,
            userType: '',
            isChangePassword: false,
            isSaveclick: 0,
        }
    }

    componentDidMount() {

    }

    onEmailChange(e) {
        let user = this.state.user;
        let errors = this.state.errors;

        user[e.target.name] = e.target.value;
        if (e.target.value.trim() !== "") {
            errors[e.target.name] = "";
        }
        this.setState({
            user, errors
        });
    }

    onEmailKeyUp(e) {
        const { user, userEmail, isSaveclick } = this.state;

        var data = {
            UserEmail: e.target.value,
            RegionID: CONFIG.REGION_ID
        }

        if (user.UserID > 0 && userEmail === data.UserEmail) {
            // do nothing
        }
        else {
            axios.post(CONFIG.API_URL + 'Account/signup/validation', data).then(
                res => {
                    var data = res.data;
                    if (data.User && (!data.User.IsLSS || data.User.IsLSS === null)) {
                        this.setState({ isProfileEdit: false });
                        this.lssRegister(data.User);

                    } else if (data.User && data.User.IsLSS) {
                        const { user } = this.state;
                        user.Login = "";
                        this.setState({ user });
                        this.props.dispatch(setSnackbar(true, "error", "User already exists"));
                    }
                    if (isSaveclick) {
                        document.getElementById('btnSave').click();
                    }
                }
            )
                .catch(err => {
                    if (err.response !== null && err.response !== undefined) {
                        this.props.dispatch(setSnackbar(true, "error", err.response.data));
                    }
                    else {
                        this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                    }
                });
        }
    }

    lssRegister(user) {
        confirmAlert({
            message: 'User Already Available in Explorer. Want to register in LSS?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let token = localStorage.getItem('accessKey');
                        if (token) {
                            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                        }

                        axios.post(CONFIG.API_URL + 'Admin/user/mvp', user).then(
                            response => {
                                if (response.status === 200) {
                                    this.props.parentMethod();
                                    this.props.dispatch(setSnackbar(true, "success", "Profile created successfully"));
                                }
                            }
                        )
                            .catch(err => {
                                if (err.response !== null && err.response !== undefined) {
                                    this.props.dispatch(setSnackbar(true, "error", err.response.data));
                                }
                                else {
                                    this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                                }
                            });

                    }
                },
                {
                    label: 'No',
                    onClick: () => { this.toggleModalClose(); }
                }
            ]
        });
    }

    onSubmit(event, errors, values) {
        const { user, isChangePassword } = this.state;
        if (errors.length === 0) {
            this.props.dispatch(showLoader());
            user.RegionId = CONFIG.REGION_ID;
            if (!isChangePassword) {
                user.Password = null;
            }

            axios.put(CONFIG.API_URL + "Account/update", user).then(
                response => {
                    if (response.status === 200) {
                        this.setState({ isProfileEdit: false });
                        this.props.parentMethod();
                        if (user.UserID > 0) {
                            this.props.dispatch(setSnackbar(true, "success", "Profile updated successfully"));
                        } else {
                            this.props.dispatch(setSnackbar(true, "success", "Profile created successfully"));
                        }
                    }
                }
            )
                .catch(err => {
                    this.props.dispatch(hideLoader());
                    if (err.response !== null && err.response !== undefined) {
                        this.props.dispatch(setSnackbar(true, "error", err.response.data));
                    }
                    else {
                        this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                    }
                });
        }
    }

    toggleModalClose() {
        this.setState({ isProfileEdit: false, errors: {} });
        this.props.parentMethod();
    }

    onKeyChange(e) {
        let user = this.state.user;
        let errors = this.state.errors;

        user[e.target.name] = e.target.value;
        if (e.target.value.trim() !== "") {
            errors[e.target.name] = "";
        }
        this.setState({
            user, errors
        });
    }

    onDDLChange(e) {
        let user = this.state.user;
        let errors = this.state.errors;
        user[e.target.name] = e.target.value;

        if (e.target.value !== "0") {
            errors[e.target.name] = "";
        }
        if (e.target.name === "BillingCountry") {
            this.setState({
                user, errors
            });
        } else {
            this.setState({
                user, errors
            });
        }
    }

    cancelChangePassword() {
        this.setState({ isChangePassword: false });
    }

    changePassword() {
        this.setState({ isChangePassword: true });
    }

    resetPassword() {
        const { user } = this.state;

        const data = {
            'UserName': user.Login,
            'RegionId': CONFIG.REGION_ID
        }

        axios.post(CONFIG.API_URL + 'admin/user/resetpassword', data)
            .then(response => {
                if (response.status === 200) {
                    this.props.dispatch(setSnackbar(true, "success", "Reset Email sent to the user"));
                }
            })
            .catch(err => {
                if (err.response !== null && err.response !== undefined) {
                    this.props.dispatch(setSnackbar(true, "error", err.response.data));
                }
                else {
                    this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                }
            });
    }

    render() {

        const { errors, user, isProfileEdit, isChangePassword, isSaveclick } = this.state;

        return (
            <div>
                <Modal size="md" id="mdSeller" name="mdSeller" backdrop="static" isOpen={isProfileEdit} toggle={this.toggleModalClose.bind(this)} className="vendor-assign-table">
                    <AvForm onSubmit={this.onSubmit.bind(this)} ref={c => (this.formUsers = c)} id="LSS_edit_admin_profile_form">
                        <ModalHeader toggle={this.toggleModalClose.bind(this)}>{user.UserID > 0 ? "Account details" : "Add Profile"}
                        </ModalHeader>
                        <ModalBody className="overflow-scroll popup-overflow">
                            <div className="filters-search user-management-form">
                                <Row>
                                    <AvField name="Login" label="Email Address:*" type="text" maxLength="100"
                                        value={user.Login} onChange={(e) => this.onEmailChange(e)} onBlur={(e) => this.onEmailKeyUp(e)}
                                        validate={{
                                            required: { value: true, errorMessage: 'Email Address is required' },
                                            pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$/, errorMessage: 'Email Address is invalid' }
                                        }}
                                    />
                                    <span className="errorMessage"> {errors.Login}</span>
                                </Row>
                                <Row>
                                    <AvField name="FirstName" label="Name:*" type="text" maxLength="100"
                                        value={user.FirstName} onChange={(e) => this.onKeyChange(e)}
                                        validate={
                                            {
                                                required: { value: true, errorMessage: 'Name is required' },
                                            }}
                                    />
                                </Row>
                                <div>
                                    {!isChangePassword ?
                                        <Button onClick={this.changePassword.bind(this)} className="change-password">Password: <span><FontAwesomeIcon icon={faEdit} /> Change&nbsp;password</span></Button> :
                                        <React.Fragment>
                                            <Row>
                                                <h2 className="account-sapce">Password</h2>
                                                <div className="box-shadow-account edit-password">

                                                    <Col sm={5} className="account-password">
                                                        <AvField name="Password" label="Create password" placeholder="Create password" type="password"
                                                            value={user.Password} onChange={(e) => this.onKeyChange(e)}
                                                            validate={{
                                                                required: { value: true, errorMessage: 'Password is required' },
                                                                pattern: { value: '^(.{0,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{4,})|(.{1,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{3,})|(.{2,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{2,})|(.{3,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{1,})|(.{4,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{0,})$', errorMessage: 'Passwords are case-sensitive, must be between 6 to 25 characters and contain at least 1 letter and 1 number or special character.' },
                                                                minLength: { value: 6, errorMessage: 'Your Password must be 6 characters' },
                                                                maxLength: { value: 25, errorMessage: 'Your Password must be 25 characters' }
                                                            }} />
                                                    </Col>
                                                    <Col sm={5}>
                                                        <AvField name="ConfirmPassword" label="Confirm password" placeholder="Confirm password" type="password"
                                                            validate={{
                                                                required: { value: true, errorMessage: 'Confirm password is required' },
                                                                match: { value: 'Password', errorMessage: 'Mismatch confirm password' }
                                                            }} />
                                                    </Col>
                                                    <Col sm={2}>
                                                        <Button className="reset-btn margin-t-20 posistion-l-20" onClick={this.cancelChangePassword.bind(this)} >Cancel</Button>
                                                    </Col>
                                                </div>
                                            </Row>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Row className="float-right">
                                <div >
                                    <Button id="btnSave" className="save-btn" type="submit" onMouseDown={() => this.setState({ isSaveclick: 1 })}>Save</Button>
                                    <Button className="reset-btn " onClick={this.toggleModalClose.bind(this)} >Cancel</Button>
                                </div>
                            </Row>
                        </ModalFooter>
                    </AvForm>
                </Modal>

            </div>
        );
    }
}

export default connect()(EditAdminProfile);