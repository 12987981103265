import React from "react";
import Head from "react-helmet";
import { CONFIG } from "./config";

export default function Helmet({
  title = CONFIG.DEFAULT_TITLE,
  description = CONFIG.DEFAULT_DESCRIPTION,
  link,
  keywords = "",
  addPostfixTitle = false,
  noIndex = false,
  children = null,
}) {

  let metaTitle
  if (addPostfixTitle) {
    metaTitle = CONFIG.PAGE_TITLE + title;
  } else {
    metaTitle = CONFIG.DEFAULT_TITLE;
  }

  const metaDesc = description ? description : CONFIG.DEFAULT_DESCRIPTION;
  const metaLink = link ? CONFIG.DOMAIN + link : CONFIG.DOMAIN;  //ex: https://www.listselfstorage.com/about ,here "about" is "link"
  const metaKeywords = keywords.length > 0 ? CONFIG.MAIN_KEYWORDS + ", " + keywords : CONFIG.MAIN_KEYWORDS;
  const metaRobots = noIndex ? "noindex, nofollow" : "index, follow"; //refer : https://seo-hacker.com/what-meta-robots-tag-are-for/#:~:text=Meta%20robots%20tag%20is%20a,to%20index%20and%20look%20at.

  return (
    <Head>
      <html lang="en" />
      <link rel="alternate" href="https://www.listselfstorage.com" hrefLang="en" />
      <title>{metaTitle}</title>
      <meta name="description" content={metaDesc} />
      <link rel="canonical" href={metaLink} />   {/*canonical is uesd to tell the search engine  which link is real and duplicate for real link put it in "href" attribute*/}
      <meta name="keywords" content={metaKeywords} />
      <meta name="robots" content={metaRobots} />
      <link rel="icon" content={CONFIG.FAVICON_SOURCE} />

      {/* https://moz.com/blog/meta-referrer-tag */}
      <meta name="referrer" content="origin-when-crossorigin" />

      {children}
    </Head>
  )
}