import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import { setSnackbar } from '../../../redux/ducks/snackbarReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import { CONFIG } from '../../../Utils/config';
import { EditAdminProfile } from '../EditAdminProfile';
import './AdminProfile.css';

class AdminProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            user: {},
            isProfileEdit: false,
        }
        this.parentMethod = this.parentMethod.bind(this);
    }

    componentDidMount() {
        this.getUserDetail();
    }

    getUserDetail() {

        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }

        axios.get(CONFIG.API_URL + 'account/' + CONFIG.REGION_ID).then(response => {
            if (response.status === 200) {
                var user = response.data.UserInfo;
                this.setState({ user });
            }
        })
            .catch(err => {
                if (err.response !== null && err.response !== undefined) {
                    this.props.dispatch(setSnackbar(true, "error", err.response.data));
                }
                else {
                    this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                }
            });
    }

    edit() {
        this.setState({ isProfileEdit: true });
    }

    parentMethod() {
        this.setState({ isProfileEdit: false });
        this.getUserDetail();
    }

    render() {
        const { user, isProfileEdit } = this.state;
        return (
            <div className="user-account margin-t-15">
                {isProfileEdit ? <EditAdminProfile user={user} parentMethod={this.parentMethod} isAdminProfile={true} /> : ''}
                <Row>
                    <Col md={12}>
                        <div className="box-shadow-account">
                            <h5 className="account-head">My Account</h5>
                            <Row>
                                <Col sm={4} className="brd-right">
                                    <h2 className="account-sapce">Account Details</h2>
                                    <div>
                                        <p className="my-account-label">Full Name</p>
                                        <p className="result-data">{user.FirstName}</p>
                                    </div>
                                    <div>
                                        <p className="my-account-label">Email</p>
                                        <p className="result-data">{user.Login}</p>
                                    </div>
                                    <div>
                                        <p className="my-account-label">Password</p>
                                        <p className="result-data">******</p>
                                    </div>
                                    <div className="right-button-account">
                                    <Button onClick={this.edit.bind(this)} className="edit-account"> <FontAwesomeIcon icon={faPen} /> Edit Account</Button>
                                </div>
                                </Col>
                              
                            </Row>
                        </div>
                    </Col>

                </Row>
            </div>
        );
    }
}

export default connect()(AdminProfile);