export const CONFIG = {
  // SEO
  PAGE_TITLE: 'List Self Storage | ',
  DOMAIN: "https://www.listselfstorage.com",
  MAIN_KEYWORDS: "listselfstorage, Self Storage, List Self Storage, Storage List, Listing, Market, self, Self-Storage, List-Self-Storage", //most searched or important words in websit
  DEFAULT_TITLE: "List Self Storage",
  DEFAULT_DESCRIPTION: "List Self Storage is uniquely qualified to serve as an outsourced acquisitions and capital deployment solution for established buyers and investors within the list self storage industry.",
  FAVICON_SOURCE: "https://www.listselfstorage.com/favicon.ico",

  // // LOCAL
  // API_URL: 'http://localhost:5001/api/',
  // ISDEV: true,
  // STORTRACK_LOGIN: 'https://staging.stortrack.com/signin.html',
  // LSS_US: 'http://staging-ai.listselfstorage.com',
  // LSS_UK: 'http://stg-uk.listselfstorage.co.uk',

  // // STAGING
  // API_URL: 'http://staging-aiapi.listselfstorage.com/api/',
  // ISDEV: false,
  // STORTRACK_LOGIN: 'https://staging.stortrack.com/signin.html',
  // LSS_US: 'http://staging-ai.listselfstorage.com',
  // LSS_UK: 'http://stg-uk.listselfstorage.co.uk',

  //LIVE
  API_URL: 'https://api.listselfstorage.com/api/',
  ISDEV: false,
  STORTRACK_LOGIN: 'https://stortrack.com/signin.html',
  LSS_US: 'https://listselfstorage.com',
  LSS_UK: 'https://listselfstorage.co.uk',

  //US
  REGION_ID: 1,
  SUPPORT_EMAIL: 'info@listselfstorage.com',
  
  // // // UK
  //  REGION_ID: 3,
  //  SUPPORT_EMAIL: 'info@listselfstorage.co.uk',
  
  sessionTimeout: 1000 * 60 * 60,
  PAGE_LENGTH: 10,
  GeolocationApiKey: 'AIzaSyAb8UR1jppXwZw1_B_4WLneVP6MxdS6PMU',
  TINY_MCE_KEY: '5frswc3jdh4z6cipxq9ytnvyfk9c25701f66n84n6pib9pw2',
  //  GoogleMapsApiKey: 'AIzaSyDmD4dBIX3AYtW4iej8okdDNKoto4fiqG4'
  GoogleMapsApiKey: 'AIzaSyBdI8IeUjbw-usya1YWTEsjytgGqEfj2KU',
  MapAPIKey: 'AIzaSyC7Pz17r14MeCOY3Hz5CFhnoxoCjmi9XWM',
  //'a4yqidfqcyw81wvs1oakorjueq6dys7j81phu3lye8xc2rdx',
  US_CountryId: '2',
  DEFAULT_RADIUS: 5,
  NewsAndEventsImageWidth: 455,
  NewsAndEventsImageHeight: 270,
  PropertyImageWidth: 662,
  PropertyImageHeight: 350,
  VendorLogoWidth: 400,
  VendorLogoHeight: 110,
  VendorAdsImageWidth: 182,
  VendorAdsImageHeight: 123,
  SponsorAdsImageWidth: 384,
  SponsorAdsImageHeight: 259,
  ListingAdsImageWidth: 260,
  ListingAdsImageHeight: 353,
  NewsAdsImageWidth: 360,
  NewsAdsImageHeight: 135
}