import React, { Component } from 'react';
import { connect } from 'react-redux';

import './FullPageLoader.css';

const mapStateToProps = function (state) {
    return {
        isLoading: state.application.isLoading
    }
}

class FullPageLoader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: props.isLoading,
        };
    }

    render() {
        const { isLoading } = this.props;
        return (
            <div>
                {isLoading ? <div className="loader-wrap"><div className="page-loading"></div></div> : ''}
            </div>
        );
    }
}

export default connect(mapStateToProps)(FullPageLoader);