import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { USERTYPES } from '../Utils/utils';
import { AdminLayout, HomeLayout, UserLayout, ResultLayout } from '../_layouts';

const AuthGaurd = {
    authenticate() {
        let token = localStorage.getItem('accessKey');
        if (token) {
            return true;
        } else {
            return false
        }
    },
    isAuthenticated() {
        return this.authenticate();
    },
    isAdmin() {
        let isauthenticated = this.authenticate();
        let role = localStorage.getItem('role');

        if (isauthenticated && role === "14") {
            return true;
        }
        return false;
    },
    isNotBroker() {
        let isauthenticated = this.authenticate();
        let role = localStorage.getItem('role');

        if (isauthenticated && role === "Broker") {
            return false;
        }
        return true;
    }
};

export const HomeLayoutRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={matchProps => (
            <HomeLayout>
                <Component {...matchProps} />
            </HomeLayout>
        )} />
    )
};
export const ResultLayoutRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={matchProps => (
            <ResultLayout>
                <Component {...matchProps} />
            </ResultLayout>
        )} />
    )
};

export const AdminLayoutRoute = ({ component: Component, ...rest }) => {
    return (
        AuthGaurd.isAdmin() ?
            <Route {...rest} render={matchprops => (
                <AdminLayout>
                    <Component {...matchprops} />
                </AdminLayout>
            )} />
            :
            <Redirect to={{ pathname: '/pagenotfound' }} />
    )
};

// export const AdminLayoutRoute = ({ component: Component, ...rest }) => {
//     return (
//         <Route {...rest} render={matchProps => (
//             <AdminLayout>
//                 <Component {...matchProps} />
//             </AdminLayout>
//         )} />
//     )
// };

export const UserLayoutRoute = ({ component: Component, ...rest }) => {
    return (
        AuthGaurd.isAuthenticated() ?
            <Route {...rest} render={matchProps => (
                <UserLayout>
                    <Component {...matchProps} />
                </UserLayout>
            )} />
            :
            <Redirect to={{ pathname: '/signin' }} />
    )
};