import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row } from 'reactstrap';
import oops from '../../Assets/Img/oops.png';
import './PageNotFound.css';

class PageNotFound extends Component {
    render() {
        return (
            <div className="oopspagenotfound">
                <Container>
                <Row>
                    <div>
                        <div className="oopsimg">
                            <img src={oops} className="width-100 height-100" alt="Page Not Found!" />
                        </div>
                <p>Oops, Page Not Found!</p>
                <Link to="/">GO HOME</Link>
                </div>
                </Row>
                </Container>
            </div>
        );
    }
}

export default PageNotFound;