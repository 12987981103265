import React, { Component } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person';
import logo_admin from './../../Assets/Img/LSS-logo.png';
import StoreIcon from '@material-ui/icons/Store';
import userimg from './../../Assets/Img/user.png';
import watchlisticon from './../../Assets/Img/watchlist.png';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AddBoxIcon from '@material-ui/icons/AddBox';
import HomeIcon from '@material-ui/icons/Home';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import axios from 'axios';
import { CONFIG } from './../../Utils/config';
import './UserLayout.css';
import { AdminHeader } from '../../Core';
import { FullPageLoader } from '../../Core/FullPageLoader';
import CustomizedSnackbars from '../../redux/snackbar';
import { Container, Row } from 'reactstrap';
import { removeLocalStorage, getName, getSecureID } from '../../Utils/localStorage';
import history from '../../History';
import { showLoader, hideLoader } from '../../redux/ducks/application';
import PaymentModel from '../../Views/Shared/PaymentModel/PaymentModel.js';
import store from '../../redux/configureStore';
import { confirmAlert } from 'react-confirm-alert';

const drawerWidth = 200;
const { dispatch } = store;

axios.interceptors.request.use(
    (conf) => {
        if (!conf.headers['Authorization']) {

        }
        dispatch(showLoader());
        return conf;
    },
    (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(response => {
    dispatch(hideLoader());
    return response;
}, error => {
    dispatch(hideLoader());
    if (!error.response) {
    }
    else if (error.response.status === 401) {
        removeLocalStorage();
        history.push('/')
    }
    else if (error.response.status === 500) {
        removeLocalStorage();
        history.push('/')
    }
    return Promise.reject(error);
});

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
}));

export default class UserLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            userMarkets: [],
            openPayment: false,
            propertyId: 0,
            userStoreId: 0
        }
        this.getMVPURL = this.getMVPURL.bind(this);
        this.closePayment = this.closePayment.bind(this);
    }

    signOut() {
        removeLocalStorage();
        this.props.children.props.history.push({
            pathname: `/`
        });
    }

    getMVPURL(userStoreId, optimizeUserStoreId, status, propertyId) {
        if (status === 1) {
            window.top.location.href = process.env.REACT_APP_MVP_URL + "logins/" + getSecureID() + "/" + userStoreId + "/" + optimizeUserStoreId;
        }
        else {
            if (propertyId === 0) {
                window.top.location.href = process.env.REACT_APP_MVP_URL + "active-lss-market/" + getSecureID() + "/" + userStoreId + "/lss";
            }
            else {
                confirmAlert({
                    message: 'This market has expired. Do you want to renew?',
                    buttons: [
                        {
                            label: 'Yes',
                            onClick: () => {
                                this.OpenPaymentModel(propertyId, userStoreId);
                            }
                        },
                        {
                            label: 'No'
                        }
                    ]
                });
            }
        }
    }

    OpenPaymentModel(propertyId, userStoreId) {
        this.setState({ openPayment: true, userStoreId, propertyId });
    }

    closePayment() {
        this.getUserMarkets();
        this.setState({ openPayment: false });
    }

    componentDidMount() {
        var name = getName();
        this.getUserMarkets();
        this.setState({ name });
    }

    getUserMarkets() {
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.get(CONFIG.API_URL + 'account/usermarket/' + CONFIG.REGION_ID)
            .then(res => {
                this.setState({ userMarkets: res.data });
            })
            .catch(err => {
            });
    }

    render() {
        const { name, userMarkets, openPayment, propertyId, userStoreId } = this.state;

        return (
            <div className={useStyles.root} id="left-nav-bar">
                <Drawer
                    className={useStyles.drawer}
                    variant="permanent"
                    usestyles={{
                        paper: useStyles.drawerPaper,
                    }}
                    anchor="left"
                >
                    <div className={useStyles.toolbar} />
                    <div className="admin-logo">
                        <Link to="/">
                            <img src={logo_admin} className="logo-adm" alt="list self Storage" />
                        </Link>
                    </div>
                    <Divider />
                    <List className="admin-menu-text">
                        <Link to="/user/profile" className={window.location.pathname === '/user/profile' ? "admin-menu active-d" : "admin-menu"}>
                            {['My Account'].map((text, index) => (
                                <ListItem button key={text}>
                                    <ListItemIcon><PersonIcon /></ListItemIcon>
                                    <ListItemText primary={text} />
                                </ListItem>
                            ))}
                        </Link>
                        <Divider />
                        <div className="admin-menu">
                            <ListItem button key={'My Market'}>
                                <ListItemIcon><InsertChartIcon /></ListItemIcon>
                                <ListItemText primary={'My Markets in Explorer'} />
                            </ListItem>
                        </div>
                        <div className="sub-menu-list-wrap">
                            {
                                userMarkets ? userMarkets.map((market) => (
                                    <div onClick={this.getMVPURL.bind(this, market.UserStoreID, market.OptimizeUserStoreId, market.Status, market.LSSPropertyId)} className={market.Status === 1 ? "market-button" : "market-button-disabled"}>
                                        <ListItem button key={market.UserStoreID}>
                                            <ListItemText primary={market.StoreName} />
                                        </ListItem>
                                    </div>
                                ))
                                    : ''
                            }</div>
                        <Divider />
                        <Link to="/user/properties" className={window.location.pathname === '/user/properties' || window.location.pathname === '/user/addproperty' || window.location.pathname.indexOf('editproperty') > -1 ? "admin-menu active-d" : "admin-menu"}>
                            {['Manage Properties'].map((text, index) => (
                                <ListItem button key={text}>
                                    <ListItemIcon><AddBoxIcon /></ListItemIcon>
                                    <ListItemText primary={text} />
                                </ListItem>
                            ))}
                        </Link>
                        <Divider />
                        <Link to="/user/vendors" className={window.location.pathname === '/user/vendors' || window.location.pathname === '/admin/vendor-profile' || window.location.pathname.indexOf('vendor-profile') > -1 ? "admin-menu active-d" : "admin-menu"}>
                            {['Manage Vendor Profile'].map((text, index) => (
                                <ListItem button key={text}>
                                    <ListItemIcon><StoreIcon /></ListItemIcon>
                                    <ListItemText primary={text} />
                                </ListItem>
                            ))}
                        </Link>
                        <Divider />
                        <Link to="/user/watchlist" className={window.location.pathname === '/user/watchlist' ? "admin-menu active-d" : "admin-menu"}>
                            {['My WatchList'].map((text, index) => (
                                <ListItem button key={text}>
                                    <img src={watchlisticon} className="icon-admin" alt="My WatchList" />
                                    <ListItemText primary={text} />
                                </ListItem>
                            ))}
                        </Link>
                        <Divider />
                        <div onClick={this.signOut.bind(this)} className="admin-menu">
                            {['Logout'].map((text, index) => (
                                <ListItem button key={text}>
                                    <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                                    <ListItemText primary={text} />
                                </ListItem>
                            ))}
                        </div>
                    </List>
                    <Divider />
                </Drawer>
                <main className={useStyles.content}>
                    <div className={useStyles.toolbar} />
                    <Container fluid className="admin-top">
                        <Row className="adminrow">
                            <div className="col-sm-8">
                                <div className="gobackhome">
                                    <Link to="/">
                                        <HomeIcon />
                                        <span>Go to Home page</span>
                                    </Link>
                                </div>
                                <FullPageLoader />
                                <CustomizedSnackbars />
                            </div>
                            <div className="col-sm-4">
                                <Row>
                                    <img src={userimg} className="usericon" alt="User Image" />
                                    <ul>
                                        <li>
                                            <div className="welcome-header">
                                                <div className="top-welcome">Welcome</div>
                                                <span className="text-uppercase">{name}</span>
                                            </div>
                                        </li>
                                    </ul>
                                </Row>
                            </div>
                        </Row>
                    </Container>
                    <AdminHeader parm={this.props} />
                    {this.props.children}
                </main>
                <div className="payment-hide">
                    {openPayment ?
                        <PaymentModel closePayment={this.closePayment} propertyId={propertyId} ispaymentRequired={true} userStoreId={userStoreId} />
                        : ''}
                </div>
            </div>
        );
    }
}

