import React, { Component } from 'react';
import { CONFIG } from '../../../Utils/config';
import { USERTYPES } from '../../../Utils/utils';
import { getToken, getUserType } from '../../../Utils/localStorage';
import axios from 'axios';
import './Authendication.css';

export default class Authendication extends Component {
    componentDidMount() {
        const loginIp = {
            "SecretKey": this.props.match.params.authurization,
            "RegionId": CONFIG.REGION_ID
        }

        axios.post(CONFIG.API_URL + 'Account/secretlogin/', loginIp)
            .then(response => {
                if (response.status === 200) {
                    const getTokvalues = response.data.Token;

                    axios.defaults.headers.common["Authorization"] = 'Bearer ' + getTokvalues.Token;
                    localStorage.setItem('accessKey', response.data.Token);
                    localStorage.setItem('name', response.data.FirstName + (response.data.LastName != null ? ' ' + response.data.LastName : ''));
                    localStorage.setItem('email', response.data.Login);
                    localStorage.setItem('role', response.data.UserType);
                    localStorage.setItem('id', response.data.UserID);
                    localStorage.setItem('secureid', response.data.SecureID);
                    localStorage.setItem('stuserstatus', response.data.Status);
                    localStorage.setItem('brokertype', response.data.BrokerTypeId);

                    var token = getToken();
                    var userType = getUserType();

                    this.setState({ token, userType });
                    if (this.props.match.path === "/login/:authurization/") {
                        if (response.data.UserType === USERTYPES.Admin) {
                            const { from } = { from: { pathname: "/admin/addproperty" } };
                            this.props.history.push(from);
                        }
                        else {
                            const { from } = { from: { pathname: "/user/addproperty" } };
                            this.props.history.push(from);
                        }
                    } else {
                        if (response.data.UserType === USERTYPES.Admin) {
                            const { from } = { from: { pathname: "/admin/usermanagement" } };
                            this.props.history.push(from);
                        }
                        else {
                            const { from } = { from: { pathname: "/user/dashboard" } };
                            this.props.history.push(from);
                        }
                    }
                }
            })
            .catch(err => {
                if (err.response != null && err.response.status === 400) {
                    const signinError = err.response.data;
                    this.setState({ signinError });
                }
                else {
                    const signinError = "Something went wrong.";
                    this.setState({ signinError });
                }
            });
    }

    render() {
        return (
            <div className="">
                <div className="secure-logding">Please wait while we sign you in..</div>
            </div>
        );
    }
}