import React, { Component } from 'react';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from "@material-ui/core/styles";
import { setSnackbar } from './ducks/snackbarReducer';

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2)
        }
    }
}));

const mapStateToProps = function (state) {
    return {
        snackbarMessage: state.snackbar.snackbarMessage,
        snackbarTime: state.snackbar.snackbarTime,
        snackbarType: state.snackbar.snackbarType,
        snackbarOpen: state.snackbar.snackbarOpen
    }
}

class CustomizedSnackbars extends Component {
    constructor(props) {
        super(props);

        this.state = {
            snackbarMessage: props.snackbarMessage,
            snackbarTime: props.snackbarTime,
            snackbarType: props.snackbarType,
            snackbarOpen: props.snackbarOpen
        };
        this.handleClose = this.handleClose.bind(this);
    }
    handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.props.dispatch(setSnackbar(false, this.state.snackbarType, this.state.snackbarMessage, this.state.snackbarTime));
    }
    render() {
        return (
            <div className={useStyles.root} >
                {
                    this.props.snackbarMessage !== null && this.props.snackbarMessage !== '' ?
                        <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            open={this.props.snackbarOpen}
                            autoHideDuration={this.props.snackbarTime !== "noTime" ? 3000 : null}
                            onClose={this.handleClose}
                        >
                            <Alert
                                elevation={6}
                                variant="filled"
                                onClose={this.handleClose}
                                color={this.props.snackbarType}
                            >
                                {this.props.snackbarMessage}
                            </Alert>
                        </Snackbar>
                        : ''
                }
            </div>
        )
    };
}
export default connect(mapStateToProps)(CustomizedSnackbars);


