export const Set_SNACKBAR = "SET_SNACKBAR";

const initialState = {
    snackbarOpen: false,
    snackbarType: "success",
    snackbarMessage: "",
    snackbarTime: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case Set_SNACKBAR:

            const { snackbarOpen, snackbarType, snackbarMessage, snackbarTime } = action;
            return {
                ...state,
                snackbarOpen,
                snackbarType,
                snackbarMessage,
                snackbarTime
            };
        default:
            return state;
    }
};

export const setSnackbar = (
    snackbarOpen,
    snackbarType = "success",
    snackbarMessage = "",
    snackbarTime = null
) => ({
    type: Set_SNACKBAR,
    snackbarOpen,
    snackbarType,
    snackbarMessage,
    snackbarTime
});