import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Button, Label, Container, Table, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import axios from "axios";
import { CONFIG } from '../../../Utils/config';
import { getToken, getUserType } from '../../../Utils/localStorage';
import {
    CURRENCY, getFormattedFloat, getFormattedInt, getPropertyTypeClass,
    PROPERTYSTATUS, USERTYPES, contactAboutOptions, normalizeInput, getPropertyStatus,
    getDateFormat, REGIONS, PROPERTYTYPES
} from '../../../Utils/utils';
import Collapse from '@material-ui/core/Collapse';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Carousel } from 'react-responsive-carousel';
import vendor_no from '../../../Assets/Img/ventor-list.png';
import bannerthree from '../../../Assets/Img/banner-three.png';
import no_img from '../../../Assets/Img/no-img.png';
import mvp from '../../../Assets/Img/mvp.png';
import pdf from '../../../Assets/Img/pdf.png';
import moment from 'moment';
import { connect } from 'react-redux';
import { setSnackbar } from '../../../redux/ducks/snackbarReducer';
import { showLoader, hideLoader } from '../../../redux/ducks/application';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import './PropertyView.css';
import { confirmAlert } from 'react-confirm-alert';
import pheart from '../../../Assets/Img/heart.png';
import fheart from '../../../Assets/Img/heart-filled.png';
import { Map } from '../../Map';
import { MapMarker } from '../../MapMarker';
import PaymentModel from '../PaymentModel/PaymentModel.js';
import ListingTile from '../ListingTile/ListingTile';
import ListingMarketData from '../ListingMarketData/ListingMarketData';
import { isDesktop, isTablet } from 'react-device-detect';
import Helmet from '../../../Utils/Helmet';
import MapGL, { FullscreenControl, Layer, NavigationControl, Source, WebMercatorViewport } from 'react-map-gl';

var circleLineData = [];
var circleData = [];
const layerStyle = {
    id: "point",
    type: "circle",
    paint: {
        "circle-radius": 5,
        "circle-color": ["get", "PointColor"],
    },
};

class PropertyView extends Component {
    static defaultProps = {
        center: {
            lat: CONFIG.REGION_ID === REGIONS.UK ? 51.509865 : 41.850033,
            lng: CONFIG.REGION_ID === REGIONS.UK ? -0.118092 : -87.6500523
        },
        zoom: 11
    }

    constructor(props) {
        super(props);
        let contactState = [];
        this.pano = React.createRef();
        this.scrollMap = React.createRef();
        this.scrollContact = React.createRef();

        contactAboutOptions.forEach(() => {
            contactState.push(false);
        });

        this.state = {
            propertyID: 0,
            property: {},
            propertyMI: {},
            propertyPins: [],
            propertyTaxes: {},
            propertyImages: [],
            center: [],
            zoom: 11,
            miRadiusList: [],
            mvpRadius: null,
            contactPhone: '',
            contactAbout: contactState,
            showContactAbout: false,
            mvpReport: {},
            token: '',
            panoramaError: '',
            isLoading: false,
            isPropertyLoaded: false,
            isFAQ: false,
            isMapLoaded: false,
            legendData: [],
            detailSec: isDesktop || isTablet ? true : false,
            descriptionSec: isDesktop || isTablet ? true : false,
            marketSec: isDesktop || isTablet ? true : false,
            RVParksData: {},
            viewport: {
                width: "100%",
                height: "100%",
                longitude: CONFIG.REGION_ID === REGIONS.UK ? -5.440458 : -87.6500523,
                latitude: CONFIG.REGION_ID === REGIONS.UK ? 53.838006 : 41.850033,
                zoom: CONFIG.REGION_ID === REGIONS.UK ? 5 : 3,
            },
            mapSize: { width: null, height: null },
            invalidPropertyRequest: false,
            propertyDocument: [],
        }
        this.mapRef = React.createRef();
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.signInPopup = this.signInPopup.bind(this);
        this.FAQModel = this.FAQModel.bind(this);
        this.closePayment = this.closePayment.bind(this);
        this.doListingSearch = this.doListingSearch.bind(this);
    }

    componentDidMount() {
        this.commonAction(this.props);
    }

    componentWillReceiveProps(newprops) {
        var props = this.props;
        if (newprops.match) {
            if (newprops.match.params.propertyAddress !== props.match.params.propertyAddress) {
                this.commonAction(newprops);
            }
        }
    }

    commonAction(newprops) {
        window.scrollTo(0, 0);
        var token = getToken();
        var userType = getUserType();
        this.setState({ token, userType, isPropertyLoaded: false }, () => {
            if (newprops.match) {
                if (newprops.match.params.propertyAddress) {
                    this.getPropertyDetail(newprops.match.params.propertyAddress);
                }
            }
        });
        this.getFeaturedListings();
        this.getLookupMIRadius();
    }

    doListingSearch(keyword, propertyTypeId) {
        const data = {
            SearchKeyword: keyword,
            PropertyTypeIds: propertyTypeId,
            RegionId: CONFIG.REGION_ID
        }

        axios.post(CONFIG.API_URL + 'search/criteria', data)
            .then(response => {
                if (response.status === 200) {
                    let searchId = response.data;
                    const { from } = { from: { pathname: "/listings/" + searchId } };
                    this.props.history.push(from);
                }
            })
            .catch(err => {
            });
    }

    getLookupMIRadius() {
        axios.get(CONFIG.API_URL + 'lookup/mi/radius').then(response => {
            if (response.status === 200) {
                this.setState({ miRadiusList: response.data });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
                if (err.response !== null && err.response !== undefined) {
                    this.props.dispatch(setSnackbar(true, "error", err.response.data));
                }
                else {
                    this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                }
            });
    }

    getFeaturedListings() {
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.get(CONFIG.API_URL + 'home/featured/listings/' + CONFIG.REGION_ID).then(response => {
            if (response.status === 200) {
                var property = response.data;

                this.setState({ featuredListings: property });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    getPropertyDetail(propertyAddress) {
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.get(CONFIG.API_URL + 'property/permalink/' + propertyAddress + '/' + CONFIG.REGION_ID).then(response => {
            if (response.status === 200) {
                if (response.data.Property != null) {
                    var propertyImages = response.data.Property.PropertyImages ? Array.from(response.data.Property.PropertyImages.split(',')) : [];
                    var property = response.data.Property;
                    var propertyPins = response.data.Pins;
                    var filterData = [];
                    this.getPropertyDocument(property.PropertyId);

                    if (property.Latitude && property.Latitude !== "0" && property.Longitude && property.Longitude !== "0") {
                        this.setState({
                            viewport: {
                                ...this.state.viewport,
                                latitude: Number(property.Latitude),
                                longitude: Number(property.Longitude),
                                zoom: 10,
                            }
                        })
                        filterData.push({ id: 1, class: getPropertyTypeClass(property.PropertyTypeId, true), distance: "This Property" });
                    }

                    if (propertyPins && propertyPins.length > 0) {
                        filterData.push({ id: 2, class: "store-label", distance: "Self Storage Facilities" });
                    }

                    this.setState({
                        property: property, propertyImages, propertyMI: response.data.MI, propertyPins: propertyPins
                        , propertyTaxes: response.data.Taxes, mvpRadius: response.data.MI.MVPRadius, isPropertyLoaded: true
                        , legendData: filterData, center: { lat: parseFloat(property.Latitude), lng: parseFloat(property.Longitude) }
                    }, () => { });
                } else {
                    this.setState({ invalidPropertyRequest: true });
                }
            } else if (response.status === 204) {
                const { from } = { from: { pathname: "/pagenotfound" } };
                this.props.history.push(from);
            }
        }).catch(err => {
            // if (err.response !== null && err.response !== undefined) {
            //     this.props.dispatch(setSnackbar(true, "error", "Invalid Request! Please contact administrator!"));
            // }
            // else {
            //     this.props.dispatch(setSnackbar(true, "error", "Something wrong happend, Please contact administrator!"));
            // }
            this.setState({ invalidPropertyRequest: true });
        });
    }

    getPropertyDocument(propertyId) {
        axios.get(CONFIG.API_URL + 'property/document/' + propertyId).then(response => {
            if (response.status === 200) {
                this.setState({ propertyDocument: response.data });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    handlePhoneChange({ target: { value } }) {
        var contactPhone = this.state.contactPhone;
        contactPhone = normalizeInput(value, contactPhone);
        this.setState({ contactPhone });
    };

    onContactSubmit(event, errors, values) {
        const { property } = this.state;
        this.props.dispatch(showLoader());
        if (errors.length === 0) {
            let contactAbout = [];
            this.state.contactAbout.forEach((item, index) => {
                if (item === true) {
                    contactAbout.push(contactAboutOptions[index]);
                }
            });

            const data = {
                PropertyName: property.PropertyName,
                PropertyId: property.PropertyId,
                Name: values.Name,
                Email: values.Email,
                Phone: values.Phone,
                Question: values.Question,
                ContactMeAbout: contactAbout.toString(),
                RegionId: CONFIG.REGION_ID
            }

            axios.post(CONFIG.API_URL + 'home/contact', data)
                .then(response => {
                    if (response.status === 200) {
                        this.form && this.form.reset();
                        let contactState = [];
                        contactAboutOptions.forEach(() => {
                            contactState.push(false);
                        });
                        this.setState({ contactState, contactPhone: '' });
                        this.hideContactAbout();
                        this.props.dispatch(setSnackbar(true, "success", "Thank you for contacting us"));
                    }
                }).catch(err => {
                    if (err.response !== null && err.response !== undefined) {
                        this.props.dispatch(setSnackbar(true, "error", err.response.data));
                    }
                    else {
                        this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                    }
                });
        }
        else
            this.props.dispatch(hideLoader());
    }

    showContactAbout = () => {
        this.setState({ showContactAbout: true });
    };

    hideContactAbout = () => {
        this.setState({ showContactAbout: false });
    };

    toggle = (e) => {
        let { contactAbout } = this.state;
        const key = parseInt(e.target.name, 10);
        contactAbout[key] = !contactAbout[key];
        this.setState({ contactAbout: contactAbout });
    };

    signInPopup() {
        document.getElementById("loginclick").click();
    }

    updateWatchListIcon(isAdded) {
        this.setState(() => ({ property: { ...this.state.property, IsWishlisted: isAdded } }))
    }

    addWishList(propertyId) {
        let token = localStorage.getItem('accessKey');
        if (token) {
            confirmAlert({
                message: 'Are you sure to add this property to your WatchList?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {

                            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                            axios.post(CONFIG.API_URL + 'property/watchlist/' + parseInt(propertyId, 10))
                                .then(response => {
                                    this.updateWatchListIcon(true);
                                })
                                .catch(err => {
                                    this.setState({ isLoading: false });
                                });
                        }
                    },
                    {
                        label: 'No'
                    }
                ]
            });
        } else {
            this.signInPopup();
        }
    }

    removeWishList(propertyId) {
        let token = localStorage.getItem('accessKey');
        if (token) {
            confirmAlert({
                message: 'Are you sure to remove this property from your WatchList?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {

                            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                            axios.delete(CONFIG.API_URL + 'property/watchlist/' + parseInt(propertyId, 10))
                                .then(response => {
                                    this.updateWatchListIcon(false);
                                })
                                .catch(err => {
                                    this.setState({ isLoading: false });
                                });
                        }
                    },
                    {
                        label: 'No'
                    }
                ]
            });
        } else {
            this.signInPopup();
        }
    }

    closePayment() {
        this.setState({ openPayment: false });
    }

    toggleModalClose() {
        this.setState({ isFAQ: false });
    }

    FAQModel() {
        this.setState({ isFAQ: true });
    }

    expandSection = (section) => {
        switch (section) {
            case 'detail':
                this.setState(state => ({ detailSec: !state.detailSec }));
                break;

            case 'description':
                this.setState(state => ({ descriptionSec: !state.descriptionSec }));
                break;

            case 'market':
                this.setState(state => ({ marketSec: !state.marketSec }));
                break;
        }
    }

    scrollToSection = (section) => {
        switch (section) {
            case 'map':
                window.scrollTo(0, isDesktop || isTablet ? (this.scrollMap.current.getBoundingClientRect().top - 130) : this.scrollMap.current.getBoundingClientRect().top)
                break;

            case 'contact':
                window.scrollTo(0, isDesktop || isTablet ? (this.scrollContact.current.getBoundingClientRect().top - 130) : this.scrollContact.current.getBoundingClientRect().top)
                break;
        }
    }

    onMIRadiusChange = (e, record) => {
        var propertyId = record.PropertyId;
        var id = e.target.value;

        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        this.setState({ isLoading: true, mvpRadius: id, center: [], zoom: null });

        axios.get(CONFIG.API_URL + 'property/' + propertyId + '/' + CONFIG.REGION_ID + '/' + id).then(response => {
            if (response.status === 200) {
                var filterData = this.state.legendData;

                if (response.data.Pins) {
                    if (response.data.Pins.length > 0 && filterData.length === 1) {
                        this.state.legendData.push({ id: 2, class: "store-label", distance: "Self Storage Facilities" });
                    }
                }
                else {
                    if (filterData.length === 2) {
                        this.state.legendData.splice(this.state.legendData.indexOf(2), 1);
                    }
                }

                this.setState({
                    propertyMI: response.data.MI, propertyPins: response.data.Pins, isLoading: false, zoom: 11,
                    center: { lat: parseFloat(response.data.Property.Latitude), lng: parseFloat(response.data.Property.Longitude) }
                });
            }
        }).catch(err => {
            this.setState({ isLoading: false });
            if (err.response !== null && err.response !== undefined) {
                this.props.dispatch(setSnackbar(true, "error", err.response.data));
            }
            else {
                this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
            }
        });
    }

    handleOnMapLoad = (data) => {
        let width = data.target._containerWidth;
        let height = data.target._containerHeight;
        this.setState({ mapSize: { height, width } });
        this.getCircle();
    }

    handleViewportChange = (viewport) => {
        let { width, height } = this.state.mapSize;
        if (width && height) viewport = { ...viewport, height, width };
        this.setState({ viewport });
    }

    // calculate bounding box value for circle default adjustment
    getBoundingValues = (points) => {
        if (points) {
            const applyToArray = (func, array) => func.apply(Math, array);
            const pointsLong = points.geometry.coordinates.map((point) => point[0]);
            const pointsLat = points.geometry.coordinates.map((point) => point[1]);
            const cornersLongLat = [
                [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
                [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
            ];
            const viewport = new WebMercatorViewport({
                width: window.innerWidth,
                height: window.innerHeight,
            }).fitBounds(cornersLongLat, {
                padding: { top: 100, bottom: 100, left: 100, right: 100 },
            });
            const { longitude, latitude, zoom } = viewport;
            this.setState({
                viewport: {
                    ...viewport,
                    latitude: latitude,
                    longitude: longitude,
                    zoom: zoom - 1,
                }
            });
        }
    };

    // draw circle
    getCircle = () => {
        let colorCode = ["#000000", "#f37864", "#38a83c"];
        let { property, miRadiusList } = this.state;
        circleLineData = [];
        for (let index = 0; index < miRadiusList.length; index++) {
            let radiusCal = miRadiusList[index].ID;
            let lat = Number(property.Latitude);
            let lng = Number(property.Longitude);
            let dir = 1;
            let d2r = Math.PI / 180;
            let r2d = 180 / Math.PI;
            let earthsradius = 3963;
            let points = 64;
            // find the raidus in lat/lon
            let rlat = (radiusCal / earthsradius) * r2d;
            let rlng = rlat / Math.cos(lat * d2r);

            circleData = [];
            let start = 0, end = 0;
            if (dir === 1) end = points + 1;
            else start = points + 1;

            for (let i = start; dir === 1 ? i < end : i > end; i = i + dir) {
                let theta = Math.PI * (i / (points / 2));
                let ey = lng + rlng * Math.cos(theta);
                let ex = lat + rlat * Math.sin(theta);
                circleData.push([ey, ex]);
            }
            let circleLine = {
                type: "Feature",
                geometry: {
                    type: "LineString",
                    coordinates: circleData,
                },
            }
            circleLineData.push({
                id: miRadiusList[index].Name.replace(' ', '-'),
                colorCode: colorCode[index],
                data: circleLine
            });
            this.getBoundingValues(circleLine);
        }
    };

    propertyColor = (PropertyTypeId) => {
        let color = PropertyTypeId === PROPERTYTYPES.TraditionalSite ? "#f15d47" :
            PropertyTypeId === PROPERTYTYPES.Development ? "#9660c9" :
                PropertyTypeId === PROPERTYTYPES.Conversion ? "#38a83c" :
                    PropertyTypeId === PROPERTYTYPES.LandOnly ? "#cc156b" :
                        PropertyTypeId === PROPERTYTYPES.RVandBoatStorage ? "#430592" : "";

        return color;
    }

    render() {
        const { property, propertyMI, propertyPins, propertyTaxes, propertyImages, miRadiusList, mvpRadius, contactPhone, showContactAbout, userStoreId,
            legendData, featuredListings, userType, openPayment, ispaymentRequired, propertyDocument
            , isFAQ, marketSec, viewport
        } = this.state;
        // document.title = CONFIG.PAGE_TITLE + (property.Address1 ? property.Address1 : '');
        let fullAddress = property.Address1 ? property.Address1 : '';
        return (
            <div>
                {!this.state.invalidPropertyRequest ?
                    <div id='propertyfound' className="property-details">
                        <Helmet
                            title={fullAddress}
                            addPostfixTitle={property.Address1 ? true : false}
                            keywords="Property Property Details"
                        />
                        <React.Fragment>
                            <div className="table-property-tag">

                                <div className="property-name">{property.PropertyName} | {property.Address1}</div>
                                <div className="property-type">
                                    {property.NSRF ? getFormattedInt(property.NSRF) + ' SF | ' : ''} {property.PropertyType}
                                    {' | ' + getPropertyStatus(property.PropertyStatusId)} | {property.City}{CONFIG.REGION_ID === REGIONS.UK ? '' : property.StateId ? ', ' + property.StateId : ''} {' | '}
                                    {property.AlternatePriceOptionID ?
                                        <span> {property.AlternatePriceOptionName}{(property.AlternatePriceOptionID === 5 || property.AlternatePriceOptionID === 6) && property.AlternatePriceOptionDate !== null ? ": " + moment(property.AlternatePriceOptionDate).format(getDateFormat()) : ''}</span>
                                        :
                                        <span>{property.Currency}{getFormattedInt(property.Price)}</span>
                                    }
                                </div>
                                <div className="property-breadcrumb">
                                    <Link onClick={() => this.doListingSearch(null, property.PropertyTypeId)}>{property.PropertyType}</Link>
                                    {CONFIG.REGION_ID === REGIONS.UK ? '' : property.StateName ? <FontAwesomeIcon icon={faCaretRight} /> : ''}
                                    {CONFIG.REGION_ID === REGIONS.UK ? '' : <Link onClick={() => this.doListingSearch(property.StateName, null)}>{property.StateName ? property.StateName : ''}</Link>}
                                    <FontAwesomeIcon icon={faCaretRight} />
                                    <Link onClick={() => this.doListingSearch(property.City, null)}>{property.City}</Link>
                                    <FontAwesomeIcon icon={faCaretRight} />
                                    {property.Address1} - {property.City} {CONFIG.REGION_ID === REGIONS.UK ? '' : property.StateId} {property.ZipCode}
                                </div>

                            </div>
                            <div className="padding-remove container-property">
                                <Row>
                                    <Col md={7} className="p-0">
                                        <Row>
                                            <Col md={12} className="property_img pl-0">
                                                <div className="size">
                                                    {propertyImages.length > 0 ?
                                                        <div>
                                                            <Carousel className="image-carousel-size" autoPlay={true} infiniteLoop={true} interval={3000} transitionTime={60}>
                                                                {propertyImages.map((image) =>
                                                                    <div className="listing-img-resize">
                                                                        <img src={'/PropertyImages/' + image} onError={(e) => { e.target.onerror = null; e.target.src = vendor_no }} className="width-100 height-100" alt="Property Images" />
                                                                    </div>
                                                                )}
                                                            </Carousel>
                                                            <div>
                                                                {userType !== USERTYPES.Admin ?
                                                                    !property.IsWishlisted ?
                                                                        <div className="tag-wishlist-propertyv">
                                                                            <Button onClick={() => this.addWishList(property.PropertyId)} title="Save property to WatchList"><img src={pheart} alt="Save Property" /></Button>
                                                                        </div>
                                                                        : <div className="tag-wishlist-propertyv">
                                                                            <Button onClick={() => this.removeWishList(property.PropertyId)} title="Remove property from WatchList"> <img src={fheart} alt="Remove Property" />
                                                                            </Button>
                                                                        </div>
                                                                    : ''}
                                                            </div>
                                                        </div>
                                                        :
                                                        ''
                                                    }
                                                </div>
                                            </Col>
                                            <div className="mobile-contact">
                                                <Link className="contact-broker" onClick={() => this.scrollToSection('contact')}>Contact Listing Broker</Link>
                                            </div>
                                            {isDesktop || isTablet ?
                                                <React.Fragment>
                                                    <div className="insight-details desktop-insight-details col-md-12 pl-0">
                                                        <ListingMarketData key="lmdDesktop" property={property} propertyMI={propertyMI} marketSec={marketSec} mvpRadius={mvpRadius} miRadiusList={miRadiusList}
                                                            onMIRadiusChange={this.onMIRadiusChange} FAQModel={this.FAQModel} expandSection={this.expandSection} />
                                                    </div>
                                                    <Col md={12} className="pl-0 mt-2 mobile-right-0 desktop-map">
                                                        <div ref={this.scrollMap} className="map-property">
                                                            <Row className="property-serach-map">
                                                                <div className="map-filter-by p-0">
                                                                    <span className="black">1 Mile</span>
                                                                    <span className="red">3 Miles</span>
                                                                    <span className="green">5 Miles</span>
                                                                </div>
                                                                <div className="property-map-size">
                                                                    {property?.Latitude && property?.Longitude && property.Latitude !== "0" && property.Longitude && property.Longitude !== "0" ?
                                                                        <div style={{ height: "480px" }}>
                                                                            <div className="fixed-legend">
                                                                                <div id="legend">
                                                                                    <div className="check map-label-box">
                                                                                        <div className="map-legends">
                                                                                            {legendData && legendData.length > 0 ? legendData.map(data => {
                                                                                                return (
                                                                                                    <div className="set-miles"><span className={data.class}></span> {data.distance}</div>);
                                                                                            })
                                                                                                : ''
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <MapGL
                                                                                {...viewport}
                                                                                ref={this.mapRef}
                                                                                onLoad={this.handleOnMapLoad}
                                                                                onViewportChange={this.handleViewportChange}
                                                                                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                                                                                mapStyle={"mapbox://styles/mapbox/streets-v11"}
                                                                                attributionControl={false}
                                                                            >
                                                                                <NavigationControl className='zoom-plus-minus' showCompass={false} />
                                                                                <FullscreenControl className='mapbox-full-zoom' />
                                                                                <Source id='storage-data' type='geojson' data={{ type: 'FeatureCollection', features: propertyPins }}>
                                                                                    <Layer {...layerStyle} />
                                                                                </Source>
                                                                                {circleLineData?.length > 0 && circleLineData.map(cd =>
                                                                                    <>
                                                                                        <Source id={cd.id} type="geojson" data={cd.data} />
                                                                                        <Layer
                                                                                            id={cd.id}
                                                                                            type="line"
                                                                                            source={cd.id}
                                                                                            layout={{
                                                                                                "line-join": "round",
                                                                                                "line-cap": "round",
                                                                                            }}
                                                                                            paint={{
                                                                                                "line-color": cd.colorCode,
                                                                                                "line-width": 2,
                                                                                            }}
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                                <Source id='property' type='geojson' data={{
                                                                                    type: 'FeatureCollection',
                                                                                    features: [
                                                                                        { type: 'Feature', geometry: { type: 'Point', coordinates: [Number(property.Longitude), Number(property.Latitude)] } }
                                                                                    ]
                                                                                }}>
                                                                                    <Layer
                                                                                        id='property'
                                                                                        type='circle'
                                                                                        source='property'
                                                                                        paint={{ 'circle-radius': 5, 'circle-color': this.propertyColor(property.PropertyTypeId) }}
                                                                                    />
                                                                                </Source>
                                                                            </MapGL>
                                                                        </div>
                                                                        :
                                                                        <div style={{ height: "480px" }}>

                                                                        </div>
                                                                    }
                                                                </div>
                                                            </Row>
                                                        </div>
                                                        <div className="market-explorer-logo">
                                                            <img src={mvp} alt="Market Explorer Logo" />
                                                            <span>Everything you need for effective market analysis</span>
                                                        </div>
                                                    </Col>
                                                </React.Fragment>
                                                : ''}
                                        </Row>
                                    </Col>

                                    <Col sm={12} md={5} className="lisiting-page-mobile">
                                        <div className="property-address-right">
                                            <h5 className="property-heading">Property Address</h5>
                                            <Row className="m-0">
                                                <Col md={8} className="pl-0 pr-0">
                                                    <div className="address-show">
                                                        {property.Address1} - {property.City} {CONFIG.REGION_ID === REGIONS.UK ? '' : property.StateId} {property.ZipCode}
                                                        <Link onClick={() => this.scrollToSection('map')}>Show Detail Map</Link>
                                                    </div>
                                                    <Table>
                                                        <tbody>
                                                            <tr>
                                                                <td>Days Listed</td>
                                                                <td>{property.DaysListed <= 0 ? 1 : getFormattedInt(property.DaysListed)} Day(s)</td>
                                                                <td className="updated-col">Updated</td>
                                                                <td>{property.ModifiedDate ? moment(property.ModifiedDate).format(getDateFormat()) : moment(property.CreatedDate).format(getDateFormat())}</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                                <Col md={4} className="p-0 pl-3 desktop-view-contact">
                                                    <Link className="contact-broker" onClick={() => this.scrollToSection('contact')}>Contact Listing Broker</Link>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="property-details-right">
                                            <h5 className="property-heading" onClick={() => this.expandSection('detail')}>Property Details {this.state.detailSec ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}</h5>
                                            <Collapse in={this.state.detailSec} timeout="auto" unmountOnExit>
                                                <div className="property-table-layout">
                                                    <Row>
                                                        <Col md={6} className="p-0">
                                                            <Row>
                                                                <Col>Property Type</Col>
                                                                <Col>{property.PropertyType}</Col>
                                                            </Row>
                                                        </Col>
                                                        {property.PropertyStatusId === PROPERTYSTATUS.Approved ?
                                                            <Col md={6} className="p-0">
                                                                <Row>
                                                                    <Col>Status</Col>
                                                                    <Col>Sale</Col>
                                                                </Row>
                                                            </Col>
                                                            : property.PropertyStatusId === PROPERTYSTATUS.SubscriptionCancelled ?
                                                                <Col md={6} className="p-0">
                                                                    <Row>
                                                                        <Col>Status</Col>
                                                                        <Col>Sale</Col>
                                                                    </Row>
                                                                </Col>
                                                            : property.PropertyStatusId === PROPERTYSTATUS.SalePending ?
                                                                <Col md={6} className="p-0">
                                                                    <Row>
                                                                        <Col>Status</Col>
                                                                        <Col>Under Contract</Col>
                                                                    </Row>
                                                                </Col>
                                                                : property.PropertyStatusId === PROPERTYSTATUS.Sold ?
                                                                    <Col md={6} className="p-0">
                                                                        <Row>
                                                                            <Col>Status</Col>
                                                                            <Col>Sold</Col>
                                                                        </Row>
                                                                    </Col>
                                                                    : ''}
                                                        {property.NSRF ? <Col md={6} className="p-0">
                                                            <Row>
                                                                <Col>{CONFIG.REGION_ID === REGIONS.UK ? "Net Rentable Sq. Ft." : "Rentable Sq. Ft."}</Col>
                                                                <Col>{getFormattedFloat(property.NSRF)} SF </Col>
                                                            </Row>
                                                        </Col>
                                                            : ""}
                                                        {property.GSF ? <Col md={6} className="p-0">
                                                            <Row>
                                                                <Col>{CONFIG.REGION_ID === REGIONS.UK ? "Gross Sq. Ft." : "GSF"}</Col>
                                                                <Col>{getFormattedFloat(property.GSF)} SF </Col>
                                                            </Row>
                                                        </Col>
                                                            : ""}
                                                        {property.BuildingSize ? <Col md={6} className="p-0">
                                                            <Row>
                                                                <Col>Building Area</Col>
                                                                <Col>{getFormattedFloat(property.BuildingSize)} SF </Col>
                                                            </Row>
                                                        </Col>
                                                            : ""}
                                                        {property.CCArea ? <Col md={6} className="p-0">
                                                            <Row>
                                                                <Col>{CONFIG.REGION_ID === REGIONS.UK ? "Climate Controlled Area" : "CC Area"}</Col>
                                                                <Col> {getFormattedFloat(property.CCArea)} SF</Col>
                                                            </Row>
                                                        </Col>
                                                            : ""}
                                                        {property.YearBuilt ? <Col md={6} className="p-0">
                                                            <Row>
                                                                <Col>Year Built</Col>
                                                                <Col>{property.YearBuilt}</Col>
                                                            </Row>
                                                        </Col>
                                                            : ''}
                                                        {property.LotSize ? <Col md={6} className="p-0">
                                                            <Row>
                                                                <Col>Lot Size</Col>
                                                                <Col><span>{getFormattedFloat(property.LotSize)}{property.LotSizeMeasurementId === 1 ? " Acres" : " SF"}</span> </Col>
                                                            </Row>
                                                        </Col>
                                                            : ''}
                                                        {property.UnitCount ? <Col md={6} className="p-0">
                                                            <Row>
                                                                <Col>Unit Count</Col>
                                                                <Col> {getFormattedInt(property.UnitCount)}  Unit(s)</Col>
                                                            </Row>
                                                        </Col>
                                                            : ''}
                                                        {property.NumberofFloors ? <Col md={6} className="p-0">
                                                            <Row>
                                                                <Col>Number of Floors</Col>
                                                                <Col>{getFormattedInt(property.NumberofFloors)}</Col>
                                                            </Row>
                                                        </Col>
                                                            : ''}
                                                        {property.CeilingHeight ? <Col md={6} className="p-0">
                                                            <Row>
                                                                <Col>Ceiling Height</Col>
                                                                <Col> {getFormattedInt(property.CeilingHeight)} feet</Col>
                                                            </Row>
                                                        </Col>
                                                            : ''}
                                                        {property.NOI ? <Col md={6} className="p-0">
                                                            <Row>
                                                                <Col>{CONFIG.REGION_ID === REGIONS.UK ? "Net Operating Income" : "NOI"}</Col>
                                                                <Col> {property.NOICurrencyCode + getFormattedInt(property.NOI)}</Col>
                                                            </Row>
                                                        </Col>
                                                            : ''}
                                                        {property.CapRate ? <Col md={6} className="p-0">
                                                            <Row>
                                                                <Col>Cap Rate</Col>
                                                                <Col>{getFormattedFloat(property.CapRate)}%</Col>
                                                            </Row>
                                                        </Col>
                                                            : ''}
                                                        {property.Occupancy ? <Col md={6} className="p-0">
                                                            <Row>
                                                                <Col>Occupancy</Col>
                                                                <Col>{getFormattedFloat(property.Occupancy)}% </Col>
                                                            </Row>
                                                        </Col>
                                                            : ''}
                                                        {property.IsRoomForExpansion ? <Col md={6} className="p-0">
                                                            <Row>
                                                                <Col>Room for Expansion</Col>
                                                                <Col> {property.IsRoomForExpansion ? 'Yes' : 'No'}</Col>
                                                            </Row>
                                                        </Col>
                                                            : ''}
                                                    </Row>
                                                </div>
                                            </Collapse>
                                        </div>
                                        <div className="property-taxes-periods">
                                            <Container className="table-propertydetails p-0">
                                                {property.Description ?
                                                    <Col md={12} className="property-v-description">
                                                        <div><p className="description-heading" onClick={() => this.expandSection('description')}>Listing Description {this.state.descriptionSec ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}</p>
                                                            {/* <p><pre className="myBox3">{property.Description ? property.Description : "N/A"}</pre></p> */}
                                                            <Collapse in={this.state.descriptionSec} timeout="auto" unmountOnExit>
                                                                <p className="myBox3" dangerouslySetInnerHTML={{ __html: property.Description }} />
                                                            </Collapse>
                                                        </div>
                                                    </Col>
                                                    : ''}

                                                {propertyTaxes.TaxYear || propertyTaxes.TaxAmount || propertyTaxes.YoYChange || propertyTaxes.AssessedYear
                                                    || propertyTaxes.AssessedLandValue || propertyTaxes.AssessedImprovementValue || propertyTaxes.TotalAssessedValue
                                                    || propertyTaxes.AppliedTaxRate || propertyTaxes.LandMarketValue || propertyTaxes.ImprovementMarketValue
                                                    || propertyTaxes.TotalMarketValue ?
                                                    <Col md={12} className="p-0">
                                                        <h5 className="property-heading">Property Taxes</h5>
                                                        <table className="table table-bordered">
                                                            <tbody>
                                                                {propertyTaxes.TaxYear ?
                                                                    <tr>
                                                                        <td>Tax Year</td>
                                                                        <td>{propertyTaxes.TaxYear}</td>
                                                                    </tr> : ''}
                                                                {propertyTaxes.TaxAmount ?
                                                                    <tr>
                                                                        <td>Tax Amount</td>
                                                                        <td>{CONFIG.REGION_ID === REGIONS.UK ? CURRENCY.UK : CURRENCY.US}{getFormattedFloat(propertyTaxes.TaxAmount)}</td>
                                                                    </tr> : ''}
                                                                {propertyTaxes.YoYChange ?
                                                                    <tr>
                                                                        <td>YOY Change in Tax Amount</td>
                                                                        <td>{propertyTaxes.YoYChange}%</td>
                                                                    </tr> : ''}
                                                                {propertyTaxes.AssessedYear ?
                                                                    <tr>
                                                                        <td>Assessed Year</td>
                                                                        <td>{propertyTaxes.AssessedYear}</td>
                                                                    </tr> : ''}
                                                                {propertyTaxes.AssessedLandValue ?
                                                                    <tr>
                                                                        <td>Assessed Land Value</td>
                                                                        <td>{CONFIG.REGION_ID === REGIONS.UK ? CURRENCY.UK : CURRENCY.US}{getFormattedFloat(propertyTaxes.AssessedLandValue)}</td>
                                                                    </tr> : ''}
                                                                {propertyTaxes.AssessedImprovementValue ?
                                                                    <tr>
                                                                        <td>Assessed Improvement Value</td>
                                                                        <td>{CONFIG.REGION_ID === REGIONS.UK ? CURRENCY.UK : CURRENCY.US}{getFormattedFloat(propertyTaxes.AssessedImprovementValue)}</td>
                                                                    </tr> : ''}
                                                                {propertyTaxes.TotalAssessedValue ?
                                                                    <tr>
                                                                        <td>Total Assessed Value</td>
                                                                        <td>{CONFIG.REGION_ID === REGIONS.UK ? CURRENCY.UK : CURRENCY.US}{getFormattedFloat(propertyTaxes.TotalAssessedValue)}</td>
                                                                    </tr> : ''}
                                                                {propertyTaxes.AppliedTaxRate ?
                                                                    <tr>
                                                                        <td>Applied Tax Rate</td>
                                                                        <td>{propertyTaxes.AppliedTaxRate}%</td>
                                                                    </tr> : ''}
                                                                {propertyTaxes.LandMarketValue ?
                                                                    <tr>
                                                                        <td>Land Market Value</td>
                                                                        <td>{CONFIG.REGION_ID === REGIONS.UK ? CURRENCY.UK : CURRENCY.US}{getFormattedFloat(propertyTaxes.LandMarketValue)}</td>
                                                                    </tr> : ''}
                                                                {propertyTaxes.ImprovementMarketValue ?
                                                                    <tr>
                                                                        <td>Improvements Market Value</td>
                                                                        <td>{CONFIG.REGION_ID === REGIONS.UK ? CURRENCY.UK : CURRENCY.US}{getFormattedFloat(propertyTaxes.ImprovementMarketValue)}</td>
                                                                    </tr> : ''}
                                                                {propertyTaxes.TotalMarketValue ?
                                                                    <tr>
                                                                        <td>Total Market Value</td>
                                                                        <td>{CONFIG.REGION_ID === REGIONS.UK ? CURRENCY.UK : CURRENCY.US}{getFormattedFloat(propertyTaxes.TotalMarketValue)}</td>
                                                                    </tr> : ''}
                                                            </tbody>
                                                        </table>
                                                    </Col>
                                                    : ''}
                                            </Container>

                                            {!isDesktop && !isTablet ?
                                                <React.Fragment>
                                                    <div className="insight-details mobile-details-insight col-md-12 pl-0">
                                                        <ListingMarketData key="lmdDesktop" property={property} propertyMI={propertyMI} marketSec={marketSec} mvpRadius={mvpRadius} miRadiusList={miRadiusList}
                                                            onMIRadiusChange={this.onMIRadiusChange} FAQModel={this.FAQModel} expandSection={this.expandSection} />
                                                    </div>
                                                    <Col md={12} className="pl-0 mt-2 mobile-right-0 mobile-map">
                                                        <div ref={this.scrollMap} className="map-property ">
                                                            <Row className="property-serach-map">
                                                                <div className="map-filter-by p-0">
                                                                    <span className="black">1 Mile</span>
                                                                    <span className="red">3 Miles</span>
                                                                    <span className="green">5 Miles</span>
                                                                </div>
                                                                <div className="property-map-size">
                                                                    {property?.Latitude && property?.Longitude && property.Latitude !== "0" && property.Longitude && property.Longitude !== "0" ?
                                                                        <div style={{ height: "480px" }}>
                                                                            <div className="fixed-legend">
                                                                                <div id="legend">
                                                                                    <div className="check map-label-box">
                                                                                        <div className="map-legends">
                                                                                            {legendData && legendData.length > 0 ? legendData.map(data => {
                                                                                                return (
                                                                                                    <div className="set-miles"><span className={data.class}></span> {data.distance}</div>);
                                                                                            })
                                                                                                : ''
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <MapGL
                                                                                {...viewport}
                                                                                ref={this.mapRef}
                                                                                onLoad={this.handleOnMapLoad}
                                                                                onViewportChange={this.handleViewportChange}
                                                                                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                                                                                mapStyle={"mapbox://styles/mapbox/streets-v11"}
                                                                                attributionControl={false}
                                                                            >
                                                                                <NavigationControl className='zoom-plus-minus' showCompass={false} />
                                                                                <FullscreenControl className='mapbox-full-zoom' />
                                                                                <Source id='storage-data' type='geojson' data={{ type: 'FeatureCollection', features: propertyPins }}>
                                                                                    <Layer {...layerStyle} />
                                                                                </Source>
                                                                                {circleLineData?.length > 0 && circleLineData.map(cd =>
                                                                                    <>
                                                                                        <Source id={cd.id} type="geojson" data={cd.data} />
                                                                                        <Layer
                                                                                            id={cd.id}
                                                                                            type="line"
                                                                                            source={cd.id}
                                                                                            layout={{
                                                                                                "line-join": "round",
                                                                                                "line-cap": "round",
                                                                                            }}
                                                                                            paint={{
                                                                                                "line-color": cd.colorCode,
                                                                                                "line-width": 2,
                                                                                            }}
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                                <Source id='property' type='geojson' data={{
                                                                                    type: 'FeatureCollection',
                                                                                    features: [
                                                                                        { type: 'Feature', geometry: { type: 'Point', coordinates: [Number(property.Longitude), Number(property.Latitude)] } }
                                                                                    ]
                                                                                }}>
                                                                                    <Layer
                                                                                        id='property'
                                                                                        type='circle'
                                                                                        source='property'
                                                                                        paint={{ 'circle-radius': 5, 'circle-color': this.propertyColor(property.PropertyTypeId) }}
                                                                                    />
                                                                                </Source>
                                                                            </MapGL>
                                                                        </div>
                                                                        :
                                                                        <div style={{ height: "480px" }}>

                                                                        </div>
                                                                    }
                                                                </div>
                                                            </Row>
                                                        </div>
                                                        <div className="market-explorer-logo">
                                                            <img src={mvp} alt="Market Explorer Logo" />
                                                            <span>Everything you need for effective market analysis</span>
                                                        </div>
                                                        <Link onClick={this.FAQModel.bind(this)} className="mobile-view-property-market">Explore Market Data for this property</Link>
                                                    </Col>
                                                </React.Fragment>
                                                : ''}
                                        </div>
                                        {propertyDocument.length !== 0 ?
                                            <div className='docbg'>
                                                <h5 className="property-heading">Documents</h5>
                                                <ul >
                                                    {propertyDocument.map((doc) =>
                                                        <li>
                                                            <a href={'/propertydocuments/' + doc.FileName} target="_blank" rel="noreferrer" className="mt-2 download-clr-btn"> <img src={pdf} alt="PDF" /> {doc.Title}</a>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                            :
                                            ""}
                                        <Row className={CONFIG.REGION_ID === REGIONS.UK ? '' : 'mt-3'}>
                                            <Col md={property.AdImageName ? 6 : 12} className="p-0">
                                                {CONFIG.REGION_ID === REGIONS.UK ?
                                                    <div className="agent-contact mt-0" ref={this.scrollContact}>
                                                        <h3 className="contact-heading">Contact Agent</h3>
                                                        <div className="label-name"><Row><Col md={6} className="p-0 first-width"><label>Name:</label></Col><Col md={6} className="p-0 sec-width">{property.BrokerName}</Col></Row></div>
                                                        <div className="label-name"><Row><Col md={6} className="p-0 first-width"><label>Company:</label></Col><Col md={6} className="p-0 sec-width">{property.BrokerCompanyName}</Col></Row></div>
                                                        <div className="label-name"><Row><Col md={6} className="p-0 first-width"><label>Phone Number:</label></Col><Col md={6} className="p-0 sec-width">{normalizeInput(property.BrokerPhone)}</Col></Row></div>
                                                    </div>
                                                    :
                                                    <div className="Property-contact-box" ref={this.scrollContact} >
                                                        <p className="contact-heading">Contact Listing Broker</p>
                                                        <AvForm onSubmit={this.onContactSubmit.bind(this)} ref={c => (this.form = c)} id="LSS_contact_listing_broker_form">
                                                            <Row>
                                                                <AvField name="Name" placeholder="Your name" type="text" maxLength="100"
                                                                    onFocus={this.showContactAbout}
                                                                    validate={
                                                                        {
                                                                            required: { value: true, errorMessage: 'Name is required' },
                                                                        }}
                                                                />
                                                            </Row>
                                                            <Row>
                                                                <AvField name="Phone" placeholder={CONFIG.REGION_ID !== REGIONS.UK ? "Your phone no:xxx-xxx-xxxx" : "Your phone no:xxx xxxx xxxx"} type="tel"
                                                                    onFocus={this.showContactAbout}
                                                                    value={contactPhone}
                                                                    maxLength={CONFIG.REGION_ID !== REGIONS.UK ? "12" : "13"}
                                                                    validate={
                                                                        {
                                                                            required: { value: true, errorMessage: 'Phone no is required' },
                                                                            tel: {
                                                                                pattern: CONFIG.REGION_ID !== REGIONS.UK ? /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/ : /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{4}[\s.-]\d{4}$/, errorMessage: 'Phone No is invalid'
                                                                            }
                                                                        }}
                                                                    onChange={(e) => this.handlePhoneChange(e)} />
                                                            </Row>
                                                            <Row>
                                                                <AvField name="Email" placeholder="Your email" type="text" maxLength="100"
                                                                    onFocus={this.showContactAbout}
                                                                    validate={{
                                                                        required: { value: true, errorMessage: 'Email is required' },
                                                                        pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$/, errorMessage: 'Email is invalid' }
                                                                    }}
                                                                />
                                                            </Row>
                                                            <Row>
                                                                <AvField name="Question" placeholder="Your question" type="textarea"
                                                                    onFocus={this.showContactAbout}
                                                                    validate={
                                                                        {
                                                                            required: { value: true, errorMessage: 'Question is required' },
                                                                        }}
                                                                />
                                                            </Row>
                                                            {showContactAbout ?
                                                                <React.Fragment>
                                                                    <p className="margin-b-9"><b>Contact me about</b></p>
                                                                    {contactAboutOptions.map((item, index) =>
                                                                        <Row className="p-contant-checkbox" >
                                                                            <div className=" margin-b-6">
                                                                                <Label>
                                                                                    <AvField onFocus={this.showContactAbout} type="checkbox" className="" name={index.toString()}
                                                                                        onChange={(e) => this.toggle(e)}
                                                                                    />
                                                                                    {item}</Label>
                                                                            </div>
                                                                        </Row>
                                                                    )}
                                                                </React.Fragment>
                                                                : ''}
                                                            <Button type="submit" className="send-contactbutton margin-t-5">Send</Button>
                                                        </AvForm>
                                                    </div>
                                                }
                                            </Col>
                                            {property.AdImageName ?
                                                <Col md={6}>
                                                    <div className="propertydetailsads">
                                                        <a href={property.AdImageURL} target="_blank" rel="noopener noreferrer">
                                                            <img src={'/ATImages/' + property.AdImageName} onError={(e) => { e.target.onerror = null; e.target.src = no_img }} className="width-100 height-100" alt="Vendor Images" />
                                                        </a>
                                                    </div>

                                                </Col>
                                                : ''}
                                        </Row>
                                    </Col>



                                </Row>
                            </div>
                        </React.Fragment>

                        {featuredListings ?
                            <div>
                                <Container className="propertyview-hotlist">
                                    <Col sm={12} className="margin-b25 mt-3">
                                        <h2 className="page-title margin-b-10">NEW LISTINGS </h2>
                                        <Row className="padding-t-0">
                                            <Col sm={4}></Col>
                                            <Col sm={4}>
                                                <div className="title-separator1"></div>
                                            </Col>
                                            <Col sm={4}></Col>
                                        </Row>
                                    </Col>
                                    <Row className="margin-b-20">
                                        {featuredListings.length > 0 ? featuredListings.map(property =>
                                            <Col sm={4} className="margin-t25 margin-b25 col-custom-padding">
                                                <ListingTile key={property.PropertyId} property={property} />
                                            </Col>
                                        ) : <p className="text-align-center no-records-found col-md-12">No Listings Found</p>}
                                    </Row>
                                </Container>
                            </div>
                            : ''}
                        <div className="payment-hide">
                            {openPayment ?
                                <PaymentModel closePayment={this.closePayment} propertyId={property.PropertyId} ispaymentRequired={ispaymentRequired} userStoreId={userStoreId} />
                                : ''}
                        </div>
                        <Modal size="md" id="mdSeller" name="mdSeller" backdrop="static" isOpen={isFAQ} toggle={this.toggleModalClose.bind(this)} className="faqpopup marketview-platform-popup modal-dialog-centered">
                            <ModalHeader toggle={this.toggleModalClose.bind(this)}>

                            </ModalHeader>
                            <ModalBody className="real-market-data">
                                <Row>
                                    <Col md={6}>
                                        <h3>Real Market Data Analysis<br />and Competitive Report</h3>
                                        <h5>Instant Report Access.</h5>
                                        <Row className="mb-3">
                                            <Col md={6}>
                                                <ul className="ten-miles">
                                                    <li><span>Market data up to a ten-mile radius</span></li>
                                                    <li><span>Detailed store information</span></li>
                                                    <li><span>Development activity information</span></li>
                                                    <li><span>Supply and demand data</span></li>
                                                </ul>
                                            </Col>
                                            <Col md={6}>
                                                <ul className="self-storage-ul">
                                                    <li><span>View all self-storage properties</span></li>
                                                    <li><span>Property ownership information</span></li>
                                                    <li><span>3 yrs historical rate [data] trends</span></li>
                                                    <li><span>Access to interactive data</span></li>
                                                </ul>
                                            </Col>
                                        </Row>
                                        <a rel="noopener noreferrer" href={process.env.REACT_APP_ST_REPORT_URL + "home/" + property.PropertyId + "/"
                                            + (property.Address1 ? property.Address1 : "empty") + "/" + (property.City ? property.City : "empty") + "/"
                                            + (property.StateId ? property.StateId : "empty") + "/" + (property.ZipCode ? property.ZipCode : "empty")
                                        } target="_blank" className="text-trans-initial view-sample-report">Get your Market Data Property Report</a>
                                        <a href={process.env.REACT_APP_MVP_PRICING_URL} target="_blank" className="view-pricing-link" rel="noopener noreferrer">View Pricing</a>
                                    </Col>
                                    <Col md={6} className="mobile-banner-three">
                                        <img src={bannerthree} alt="Banner" />
                                    </Col>
                                </Row>
                            </ModalBody>
                        </Modal>
                    </div>
                    :
                    <div id='propertynotfound' className="oopspropnotfound">
                        <Container>
                            <Row>
                                <div>
                                    <p>Oops, Page Not Found!</p>
                                    <Link to="/">GO TO HOME</Link>
                                </div>
                            </Row>
                        </Container>
                    </div>}
            </div>
        );
    }
}

export default connect()(PropertyView);