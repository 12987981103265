import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { setSnackbar } from '../../redux/ducks/snackbarReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import moment from 'moment';
import { CONFIG } from '../../Utils/config';
import { confirmAlert } from 'react-confirm-alert';
import { getDateFormat, REGIONS, PROPERTYSTATUS } from '../../Utils/utils';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Link } from "react-router-dom";
import { TableBody } from '@material-ui/core';
import TableFooter from '@material-ui/core/TableFooter';
import deleted from './../../Assets/Img/delete.png';
import heart2 from './../../Assets/Img/heart-empty.png';
import Pagination from '@material-ui/lab/Pagination';
import './WatchList.css';
import Helmet from '../../Utils/Helmet';

class WatchList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageCount: 0,
            pageNum: 0
        }

    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.InitialSearchValue();
    }

    InitialSearchValue() {
        var searchCriteria = {
            RegionID: CONFIG.REGION_ID,
            PropertyName: '',
            Page: 1,
            PageLength: CONFIG.PAGE_LENGTH,
            SortBy: '',
            SortOrder: ''
        }

        this.setState({ searchCriteria }, () => {
            this.getProperties(searchCriteria);
        });
    }

    getProperties(data) {
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        data.RegionID = CONFIG.REGION_ID;

        axios.post(CONFIG.API_URL + 'property/watchlists', data).then(response => {
            if (response.status === 200) {
                this.setState({
                    watchListData: response.data.Property, pagination: response.data.Pagination,
                    pageNum: Math.ceil(response.data.Pagination.TotalRecords / 10)
                });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
                if (err.response !== null && err.response !== undefined) {
                    this.props.dispatch(setSnackbar(true, "error", err.response.data));
                }
                else {
                    this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                }
            });
    }

    handleChangePage = (event, pageCount) => {
        const { searchCriteria } = this.state;
        searchCriteria.Page = pageCount;
        this.setState({ searchCriteria }, () => {
            this.getProperties(searchCriteria);
        });

    };

    searchResult() {
        const data = {
            RegionId: CONFIG.REGION_ID
        }
        axios.post(CONFIG.API_URL + 'search/criteria', data)
            .then(response => {
                if (response.status === 200) {
                    let searchId = response.data;
                    const { from } = { from: { pathname: "/listings/" + searchId } };
                    this.props.history.push(from);
                }
            })
            .catch(err => {
            });
    }

    deleteWishList(id) {
        confirmAlert({
            message: 'Are you sure to delete this property from your WatchList?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let token = localStorage.getItem('accessKey');
                        if (token) {
                            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                        }
                        this.setState({ isLoading: true });

                        axios.delete(CONFIG.API_URL + 'property/watchlist/' + id)
                            .then(res => {
                                this.InitialSearchValue();
                            })
                            .catch((err) => {
                                this.setState({ isLoading: false });

                            });

                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    render() {
        const { watchListData, pageNum } = this.state;
        return (
            <div>
                <div className="header-category-box form-back-shadow margin-t-15">
                    <Helmet
                        title="My WatchList"
                        addPostfixTitle={true}
                        keywords="My WatchList, Add Property To Watchlist, Add Property, Watchlist"
                    />
                    <Row className="marign-t-8">
                        <Col sm={8}>
                            <h6>My WatchList</h6>
                        </Col>
                        <Col sm={4}>
                            <div className="add-new-btn">
                                <span onClick={this.searchResult.bind(this)} ><FontAwesomeIcon icon={faPlusSquare} /> Add Property to WatchList</span>
                            </div>
                        </Col>

                        <Row className="total-watchlist">
                            <Col md={6} className="total-numb">
                                <p className="">To add to your WatchList, simply locate the Listing and click <img src={heart2} alt="Save Property" /></p>
                            </Col>
                            <Col md={6} className="total-numb">
                            </Col>
                        </Row>
                        <Col sm={12} className="margin-t-15">
                            {watchListData ? watchListData.length > 0 ?
                                <div className="table-model parent-category">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Date Added
                                                </TableCell>
                                                <TableCell>
                                                    Property Name
                                                </TableCell>
                                                <TableCell>
                                                    Address
                                                </TableCell>
                                                {CONFIG.REGION_ID === REGIONS.US ?
                                                    <TableCell>City</TableCell>
                                                    : CONFIG.REGION_ID === REGIONS.UK ?
                                                        <TableCell>Town/City</TableCell>
                                                        : ""}
                                                {CONFIG.REGION_ID !== REGIONS.UK ? <TableCell>State</TableCell> : ''}
                                                <TableCell>
                                                    Current Status
                                                </TableCell>
                                                <TableCell>
                                                    Action
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {watchListData.map((record) =>
                                                <TableRow key={record.PropertyId} >
                                                    <TableCell>{moment(record.CreatedDate).format(getDateFormat())}</TableCell>
                                                    <TableCell>{record.PropertyStatusId === PROPERTYSTATUS.Approved || record.PropertyStatusId === PROPERTYSTATUS.SalePending ?
                                                        <Link target="_blank" rel="noopener noreferrer" to={"/property/" + record.Permalink}>{record.PropertyName}</Link>
                                                        : record.PropertyName}</TableCell>
                                                    <TableCell>{record.Address1}</TableCell>
                                                    <TableCell>{record.City}</TableCell>
                                                    {CONFIG.REGION_ID !== REGIONS.UK ? <TableCell>{record.StateId}</TableCell> : ''}
                                                    <TableCell>{record.PropertyStatusId && record.PropertyStatusId === PROPERTYSTATUS.Approved ?
                                                        'Approved' : record.PropertyStatusId === PROPERTYSTATUS.PendingApproval ? 'Pending approval' : record.PropertyStatusId === PROPERTYSTATUS.Draft ? 'Draft' : record.PropertyStatusId === PROPERTYSTATUS.Sold ? 'Sold' : record.PropertyStatusId === PROPERTYSTATUS.Closed ? 'Closed' : record.PropertyStatusId === PROPERTYSTATUS.Inactive ? 'Inactive' : record.PropertyStatusId === PROPERTYSTATUS.SalePending ? 'Under Contract' : ''}</TableCell>
                                                    <TableCell>
                                                        <span type="delete" className="button-common background-publish" onClick={() => this.deleteWishList(record.PropertyId)}><img src={deleted} title="Delete" className="cursor-pointer" alt="Remove Property" /></span>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TableCell colSpan="7">
                                                    <Pagination className="seaarch-pagination" count={pageNum} showFirstButton showLastButton onChange={this.handleChangePage} />
                                                </TableCell>
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </div> : <h6 className="no-records-found">No records found</h6>
                                : ''}
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default connect()(WatchList);