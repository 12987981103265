import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import { setSnackbar } from '../../../redux/ducks/snackbarReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import { CONFIG } from '../../../Utils/config';
import { normalizeInput } from '../../../Utils/utils';
import EditProfile from '../../Shared/EditProfile/EditProfile';
import './MyProfile.css';

class MyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      user: {},
      isProfileEdit: false,
      isPasswordEdit: false,
    }
    this.parentMethod = this.parentMethod.bind(this);
  }

  componentDidMount() {
    this.getUserDetail();
  }

  getUserDetail() {

    let token = localStorage.getItem('accessKey');
    if (token) {
      axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
    }

    axios.get(CONFIG.API_URL + 'account/' + CONFIG.REGION_ID).then(response => {
      if (response.status === 200) {
        var user = response.data.UserInfo;
        user.Phone = user.Phone ? normalizeInput(user.Phone) : '';
        this.setState({ user });
      }
    })
      .catch(err => {
        if (err.response !== null && err.response !== undefined) {
          this.props.dispatch(setSnackbar(true, "error", err.response.data));
        }
        else {
          this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
        }
      });
  }

  edit(type) {
    type !== "password" ? this.setState({ isProfileEdit: true }) : this.setState({ isPasswordEdit: true });
  }

  parentMethod() {
    this.setState({ isProfileEdit: false, isPasswordEdit: false });
    this.getUserDetail();
  }

  render() {
    const { user, isProfileEdit, isPasswordEdit } = this.state;
    return (
      <div className="user-account margin-t-15">
        {isProfileEdit ? <EditProfile user={user} parentMethod={this.parentMethod} type={"profileEdit"} /> : ''}
        {isPasswordEdit ? <EditProfile user={user} parentMethod={this.parentMethod} type={"passwordEdit"} /> : ''}
        <Row>
          <Col md={12}>
            <div className="box-shadow-account">
              <h5 className="account-head">My Account</h5>
              <Row>
                <Col sm={4} className="brd-right">
                  <h2 className="account-sapce">Account Details</h2>
                  <div>
                    <p className="my-account-label">Full Name</p>
                    <p className="result-data">{user.FirstName}</p>
                  </div>
                  <div>
                    <p className="my-account-label">Email</p>
                    <p className="result-data">{user.Login}</p>
                  </div>
                  <div>
                    <p className="my-account-label">Password</p>
                    <p className="result-data">******</p>
                  </div>
                  <div className="right-button-account">
                    <Button onClick={() => this.edit("password")} className="edit-account"> <FontAwesomeIcon icon={faPen} /> Change Password</Button>
                  </div>
                </Col>
                <Col sm={4} className="brd-right">
                  <div>
                    <p className="my-account-label">Company Name</p>
                    <p className="result-data">{user.Company}</p>
                  </div>
                  <div>
                    <p className="my-account-label">Address</p>
                    <p className="result-data">
                      {`${user.BillingAddress !== null && user.BillingAddress !== "" && user.BillingAddress !== undefined ? user.BillingAddress : ''}` + " " + `${user.BillingAddress2 !== null && user.BillingAddress2 !== "" && user.BillingAddress2 !== undefined ? ", " + user.BillingAddress2 : ''}`}
                      <br />
                      {user.BillingCity}

                      {user.BillingState ? ", " + user.BillingState + " " + user.BillingZipCode : user.BillingZipCode ? ' ' + user.BillingZipCode : ''}
                    </p>
                  </div>
                  <div>
                    <p className="my-account-label">Phone</p>
                    <p className="result-data">{user.Phone}</p>
                  </div>
                  <div className="right-button-account">
                    {/* <Button onClick={this.edit.bind(this)} className="edit-account"> <FontAwesomeIcon icon={faPen} /> Edit Account</Button> */}
                    <Button onClick={() => this.edit("edit")} className="edit-account"> <FontAwesomeIcon icon={faPen} /> Edit Account</Button>
                  </div>
                </Col>
                <Col sm={4}></Col>
              </Row>
            </div>
          </Col>

        </Row>
      </div>
    );
  }
}

export default connect()(MyProfile);