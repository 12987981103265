import React from 'react';
import { connect } from 'react-redux';
import ad_no from '../../../Assets/Img/ventor-list.png';

export class VendorAdTile extends React.Component {

    render() {
        const { ad } = this.props;
        return (
            <div className="vendor-fv-box">
                {ad.IsEmailLink ?
                    <a href={"mailto:" + ad.AdvertisementEmail}>
                        <div className="img-size-vad">
                            <img src={'/ATImages/' + ad.AdvertisementImage} onError={(e) => { e.target.onerror = null; e.target.src = ad_no }} className="width-100 height-100" alt="Vendor Images" />
                        </div>
                        <h6>{ad.Title}</h6>
                    </a>
                    :
                    <a href={ad.URL} target="blank" rel="noopener noreferrer">
                        <div className="img-size-vad">
                            <img src={'/ATImages/' + ad.AdvertisementImage} onError={(e) => { e.target.onerror = null; e.target.src = ad_no }} className="width-100 height-100" alt="Vendor Images" />
                        </div>
                        <h6>{ad.Title}</h6>
                    </a>
                }
            </div>
        );
    }
}

export default connect()(VendorAdTile);