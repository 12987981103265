import React, { Component } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, Label, ModalFooter } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import axios from "axios";
import { getUserType } from '../../../Utils/localStorage';
import { CONFIG } from '../../../Utils/config';
import { USERTYPES, normalizeInput, deNormalizeInput, REGIONS, COUNTRYID } from '../../../Utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { setSnackbar } from '../../../redux/ducks/snackbarReducer';
import { showLoader, hideLoader } from '../../../redux/ducks/application';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import './EditProfile.css';
class EditProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            user: this.props.user,
            userEmail: this.props.user.Login,
            editType: this.props.type,
            isProfileEdit: true,
            userType: '',
            isChangePassword: false,
            userTypes: [],
            filteredState: [],
            stateData: [],
            countryData: [],
            showState: true,
            countryId: CONFIG.REGION_ID === REGIONS.US ? COUNTRYID.US : CONFIG.REGION_ID === REGIONS.UK ? COUNTRYID.UK : 0,
            isSaveclick: 0,
        }
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
    }

    componentDidMount() {
        const { user } = this.state;
        this.getLookupData();
        this.getLookupBrokerTypes();
        var userType = getUserType();
        user.Phone = normalizeInput(user.Phone);

        this.setState({ userType, user });
    }

    getLookupBrokerTypes() {
        axios.get(CONFIG.API_URL + 'lookup/broker/types').then(response => {
            if (response.status === 200) {
                this.setState({ userTypes: response.data });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    getLookupData() {
        //States and Country
        let stateValue = 1;//CONFIG.REGION_ID === 3 ? 1 : 0;
        axios.get(CONFIG.API_URL + 'marketreport/lookupdata/' + CONFIG.REGION_ID + '/' + stateValue)
            .then(response => {
                let stateData = response.data.lookups.StatesList;
                let countryData = response.data.lookups.CountryList;
                this.state.user['BillingCountry'] = this.state.user.UserID > 0 ? this.props.user.BillingCountry : countryData.filter(x => x.CountryID === this.state.countryId).map(x => x.CountryID)[0];
                this.state.user['BillingCountryCode'] = this.state.user.UserID > 0 ? this.props.user.BillingCountryCode === 'England' ? "GB" : this.props.user.BillingCountryCode : countryData.filter(x => x.CountryID === this.state.countryId).map(x => x.CountryCode)[0];
                let countryCode = this.state.user.UserID > 0 ? this.props.user.BillingCountryCode : this.state.user.BillingCountryCode;
                let stateDetails = [];
                if (countryCode !== undefined && countryCode !== null && countryCode !== '') {
                    // State's country code based country details
                    stateDetails = stateData.filter(function (data) {
                        return data.CountryCode === countryCode;
                    });
                }
                this.setState({ stateData, countryData, countryCode, filteredState: stateDetails, showState: stateDetails && stateDetails.length > 0 ? countryCode === 'England' || countryCode === 'Scotland' || countryCode === 'Northern Ireland' || countryCode === 'Wales' ? false : true : false });
            })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    handlePhoneChange({ target: { value } }) {
        const { user } = this.state;
        var phoneError = "";

        if (user.Phone === "") {
            phoneError = "Phone is required";
        }

        user.Phone = normalizeInput(value, user.Phone);
        this.setState({ user, phoneError });
    };

    onEmailChange(e) {
        let user = this.state.user;
        let errors = this.state.errors;

        user[e.target.name] = e.target.value;
        if (e.target.value.trim() !== "") {
            errors[e.target.name] = "";
        }
        this.setState({
            user, errors
        });
    }

    onEmailKeyUp(e) {
        const { user, userEmail, isSaveclick } = this.state;

        var data = {
            UserEmail: e.target.value,
            RegionID: CONFIG.REGION_ID
        }

        if (user.UserID > 0 && userEmail === data.UserEmail) {
            // do nothing
        }
        else {
            axios.post(CONFIG.API_URL + 'Account/signup/validation', data).then(
                res => {
                    var data = res.data;
                    if (data.User && (!data.User.IsLSS || data.User.IsLSS === null)) {
                        this.setState({ isProfileEdit: false });
                        this.lssRegister(data.User);

                    } else if (data.User && data.User.IsLSS) {
                        const { user } = this.state;
                        user.Login = "";
                        this.setState({ user });
                        this.props.dispatch(setSnackbar(true, "error", "User already exists"));
                    }
                    if (isSaveclick) {
                        document.getElementById('btnSave').click();
                    }
                }

            )
                .catch(err => {
                    if (err.response !== null && err.response !== undefined) {
                        this.props.dispatch(setSnackbar(true, "error", err.response.data));
                    }
                    else {
                        this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                    }
                });
        }
    }

    lssRegister(user) {
        user.RegionId = CONFIG.REGION_ID;

        confirmAlert({
            message: 'User Already Available in Explorer. Want to register in LSS?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let token = localStorage.getItem('accessKey');
                        if (token) {
                            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
                        }
                        axios.post(CONFIG.API_URL + 'Admin/user/mvp', user).then(
                            response => {
                                if (response.status === 200) {
                                    this.props.parentMethod();
                                    this.props.dispatch(setSnackbar(true, "success", "Profile created successfully"));
                                }
                            }
                        )
                            .catch(err => {
                                if (err.response !== null && err.response !== undefined) {
                                    this.props.dispatch(setSnackbar(true, "error", err.response.data));
                                }
                                else {
                                    this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                                }
                            });

                    }
                },
                {
                    label: 'No',
                    onClick: () => { this.toggleModalClose(); }
                }
            ]
        });
    }

    onSubmit(event, errors, values) {
        const { user, userType, isChangePassword, editType } = this.state;
        var result = this.validateUser(user);
        if (errors.length === 0 && result) {
            this.props.dispatch(showLoader());

            user.RegionId = CONFIG.REGION_ID;
            user.Phone = deNormalizeInput(user.Phone);

            if (userType === USERTYPES.Admin) {
                user.StateCode = values.StateCode !== undefined ? values.StateCode : ''
                var sc_code = user.StateCode.split(',')[0].trim()
                if (sc_code !== undefined && sc_code !== '' && sc_code) {
                    user['BillingState'] = sc_code;
                }
                axios.post(CONFIG.API_URL + "Admin/user", user).then(
                    response => {
                        if (response.status === 200) {

                            if (user.UserID > 0) {
                                this.props.dispatch(setSnackbar(true, "success", "Profile updated successfully"));
                            } else {
                                this.props.dispatch(setSnackbar(true, "success", "Profile created successfully"));
                            }
                            this.setState({ isProfileEdit: false });
                            this.props.parentMethod();
                        }
                    }
                )
                    .catch(err => {
                        user.Phone = normalizeInput(user.Phone);
                        if (err.response !== null && err.response !== undefined) {
                            this.props.dispatch(setSnackbar(true, "error", err.response.data));
                        }
                        else {
                            this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                        }
                    });
            } else {

                if (editType !== "passwordEdit") {
                    user.Password = null;
                }
                // if (!isChangePassword) {
                //     user.Password = null;
                // }
                user.StateCode = values.StateCode !== undefined ? values.StateCode : ''
                var sc_code = user.StateCode
                if (sc_code !== undefined && sc_code !== '' && sc_code) {
                    user['BillingState'] = sc_code;
                }
                axios.put(CONFIG.API_URL + "Account/update", user).then(
                    response => {
                        if (response.status === 200) {
                            this.setState({ isProfileEdit: false });
                            this.props.parentMethod();
                            if (user.UserID > 0) {
                                editType !== "passwordEdit" ? this.props.dispatch(setSnackbar(true, "success", "Profile updated successfully")) : this.props.dispatch(setSnackbar(true, "success", "Password updated successfully"));
                            } else {
                                this.props.dispatch(setSnackbar(true, "success", "Profile created successfully"));
                            }
                        }
                    }
                )
                    .catch(err => {
                        user.Phone = normalizeInput(user.Phone);
                        if (err.response !== null && err.response !== undefined) {
                            this.props.dispatch(setSnackbar(true, "error", err.response.data));
                        }
                        else {
                            this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                        }
                    });
            }
        }
        else {
            this.props.dispatch(hideLoader());
        }
    }

    toggleModalClose() {
        this.setState({ isProfileEdit: false, errors: {} });
        this.props.parentMethod();
    }

    validateUser(user) {
        const { errors, userType } = this.state;
        var result = true;
        if (CONFIG.REGION_ID !== REGIONS.UK) {
            if (user.BillingCountry === null) {
                errors["BillingCountry"] = "Country is required";
                result = false;
            } else if (user.BillingCountry === "0") {
                errors["BillingCountry"] = "Country is required";
                result = false;
            }
        }

        if (userType !== USERTYPES.Admin) {

            if (user.BillingAddress === null) {
                errors["BillingAddress"] = "Address is required";
                result = false;
            } else if (user.BillingAddress.trim() === "") {
                errors["BillingAddress"] = "Address is required";
                result = false;
            }

            if (user.BillingCity === null) {
                errors["BillingCity"] = CONFIG.REGION_ID === REGIONS.UK ? "Town/City is required" : "City is required";
                result = false;
            } else if (user.BillingCity.trim() === "") {
                errors["BillingCity"] = CONFIG.REGION_ID === REGIONS.UK ? "Town/City is required" : "City is required";
                result = false;
            }

            if (user.BillingZipCode === null) {
                errors["BillingZipCode"] = CONFIG.REGION_ID === REGIONS.UK ? "Postcode is required" : "Zip Code is required";
                result = false;
            } else if (user.BillingZipCode.trim() === "") {
                errors["BillingZipCode"] = CONFIG.REGION_ID === REGIONS.UK ? "Postcode is required" : "Zip Code is required";
                result = false;
            }
            this.setState({ errors });

        } else {
            if (user.BrokerTypeId === "0") {
                errors["BrokerTypeId"] = "User Type is required";
                result = false;
            }
        }
        this.setState({ errors });
        return result;
    }

    onKeyChange(e) {
        let user = this.state.user;
        let errors = this.state.errors;

        user[e.target.name] = e.target.value;
        if (e.target.value.trim() !== "") {
            errors[e.target.name] = "";
        }
        this.setState({
            user, errors
        });
    }

    onDDLChange(e) {
        let user = this.state.user;
        let errors = this.state.errors;
        user[e.target.name] = e.target.value;

        if (e.target.value !== "0") {
            errors[e.target.name] = "";
        }
        if ((e.target.value !== '' && e.target.value !== undefined) && e.target.name === "BillingCountry") {
            var sc_code = e.target.value;
            let countryDetails = this.state.countryData.filter(function (data) {
                return data.CountryCode === sc_code
            });

            // State's country code based country details
            let stateDetails = this.state.stateData.filter(function (data) {
                return data.CountryCode === sc_code
            });
            user['BillingCountry'] = stateDetails.length > 0 ? countryDetails[0].CountryID : '';
            user['BillingCountryCode'] = countryDetails[0] !== undefined && countryDetails.length > 0 ? countryDetails[0].CountryCode : '';
            if (user.BillingCountryCode === 'England') {
                this.props.user.BillingState = '';
                this.props.user.BillingStateName = '';
                this.props.user.StateCode = '';

            }

            this.setState({
                user, errors, filteredState: stateDetails, showState: countryDetails[0] !== undefined && countryDetails.length > 0 ? countryDetails[0].CountryCode === 'England' || countryDetails[0].CountryCode === 'Scotland' || countryDetails[0].CountryCode === 'Northern Ireland' || countryDetails[0].CountryCode === 'Wales' ? false : true : false, CountryId: countryDetails[0] !== undefined ? countryDetails[0].CountryID : null,
                BillingCountry: countryDetails[0] !== undefined && countryDetails.length > 0 ? countryDetails[0].CountryCode : null
            });
        } else {

            this.setState({
                user, errors
            });
        }
    }

    // cancelChangePassword() {
    //     this.setState({ isChangePassword: false });
    // }

    // changePassword() {
    //     this.setState({ isChangePassword: true });
    // }

    resetPassword() {
        const { user } = this.state;

        const data = {
            'UserName': user.Login,
            'RegionId': CONFIG.REGION_ID
        }

        // axios.post(CONFIG.API_URL + 'admin/user/resetpassword', data)
        axios.post(CONFIG.API_URL + 'Account/forgotpassword', data)
            .then(response => {
                if (response.status === 200) {
                    this.props.dispatch(setSnackbar(true, "success", "Reset Email sent to the user"));
                }
            })
            .catch(err => {
                if (err.response !== null && err.response !== undefined) {
                    this.props.dispatch(setSnackbar(true, "error", err.response.data));
                }
                else {
                    this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                }
            });
    }

    render() {

        const { errors, user, isProfileEdit, isChangePassword, countryList, stateList, userType, userTypes, countryData, filteredState, showState, stateData, isSaveclick, editType } = this.state;
        let billingCountry = user && user.BillingCountry != null ? countryData.filter((data) => { return data.CountryID === user.BillingCountry }).map(data => data.CountryCode) : '';
        return (
            <div>
                {editType !== "passwordEdit" ?
                    <Modal size="md" id="mdSeller" name="mdSeller" backdrop="static" isOpen={isProfileEdit} toggle={this.toggleModalClose.bind(this)} className="vendor-assign-table">
                        <AvForm onSubmit={this.onSubmit.bind(this)} ref={c => (this.formUsers = c)} id="LSS_add_edit_form">
                            <ModalHeader toggle={this.toggleModalClose.bind(this)}>{user.UserID > 0 ? "Account details" : "Add Profile"}
                            </ModalHeader>
                            <ModalBody className="overflow-scroll popup-overflow">
                                <div className="filters-search user-management-form">
                                    <Row>

                                        <AvField name="Login" label="Email Address:*" type="text" maxLength="100"
                                            value={user.Login} onChange={(e) => this.onEmailChange(e)} onBlur={(e) => this.onEmailKeyUp(e)}
                                            validate={{
                                                required: { value: true, errorMessage: 'Email Address is required' },
                                                pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$/, errorMessage: 'Email Address is invalid' }
                                            }}
                                        />
                                        <span className="errorMessage"> {errors.Login}</span>
                                    </Row>
                                    <Row>
                                        <AvField name="FirstName" label="Name:*" type="text" maxLength="100"
                                            value={user.FirstName} onChange={(e) => this.onKeyChange(e)}
                                            validate={
                                                {
                                                    required: { value: true, errorMessage: 'Name is required' },
                                                    pattern: { value: '^[A-Za-z ]+$', errorMessage: 'Name is invalid' }
                                                }}
                                        />
                                    </Row>
                                    <Row>
                                        <AvField name="Company" label="Company Name:" type="text" maxLength="200"
                                            value={user.Company} onChange={(e) => this.onKeyChange(e)}
                                        />
                                    </Row>
                                    <Row>
                                        <Col sm={6} className="padding-left-remove">
                                            <AvField name="BillingAddress" label="Address1:" type="text" maxLength="300"
                                                value={user.BillingAddress} onChange={(e) => this.onKeyChange(e)}
                                                validate={
                                                    {
                                                        pattern: { value: '^[A-Za-z0-9,. ]+$', errorMessage: 'Address1 is invalid' }
                                                    }} />
                                            <span className="errorMessage rtop-50"> {errors.BillingAddress}</span>
                                        </Col>
                                        <Col md={6} className="padding-right-remove">
                                            <AvField name="BillingAddress2" label="Address2:" type="text" maxLength="300"
                                                value={user.BillingAddress2} onChange={(e) => this.onKeyChange(e)}
                                                validate={
                                                    {
                                                        pattern: { value: '^[A-Za-z0-9,. ]+$', errorMessage: 'Address2 is invalid' }
                                                    }} />
                                            <span className="errorMessage rtop-50"> {errors.BillingAddress}</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6} className="padding-left-remove">
                                            <AvField name="BillingCity" label={CONFIG.REGION_ID !== REGIONS.UK ? "City:" : "Town/City:"} type="text" maxLength="300"
                                                value={user.BillingCity} onChange={(e) => this.onKeyChange(e)}
                                                validate={
                                                    {
                                                        pattern: { value: '^[A-Za-z ]+$', errorMessage: CONFIG.REGION_ID !== REGIONS.UK ? "City is invalid" : "Town/City is invalid" }
                                                    }} />
                                            <span className="errorMessage top-50"> {errors.BillingCity}</span>
                                        </Col>
                                        {/* {CONFIG.REGION_ID !== REGIONS.UK ?
                                        <React.Fragment>
                                            <Col md={6} className="padding-right-remove"><Label>State:*</Label>
                                                <AvField type="select" name="BillingState" value={user.BillingState}
                                                    onChange={(e) => this.onDDLChange(e)}
                                                >
                                                    <option value="0">--Select--</option>
                                                    {stateList ? stateList.map(n => {
                                                        return (
                                                            <option key={n.Id} className={n.Id === '' ? "optHead" : ''}
                                                                disabled={n.Id === '' ? true : false}
                                                                value={n.Id}>
                                                                {n.Id}
                                                            </option>
                                                        );
                                                    }) : ''}
                                                </AvField>
                                                <span className="errorMessage error-block-48"> {errors.BillingState}</span>
                                            </Col>
                                            <Col sm={6} className="padding-left-remove">
                                                <Label>Country:</Label>
                                                <AvField type="select" name="BillingCountry" value={user.BillingCountry}
                                                    onChange={(e) => this.onDDLChange(e)} disabled
                                                >
                                                    <option value="0">--Select--</option>
                                                    {countryList ? countryList.map(n => {
                                                        return (
                                                            <option key={n.Id} className={n.Id === '' ? "optHead" : ''}
                                                                disabled={n.Id === '' ? true : false}
                                                                value={n.Id}>
                                                                {n.Code}
                                                            </option>
                                                        );
                                                    }) : ''}
                                                </AvField>
                                                <span className="errorMessage error-block-48"> {errors.BillingCountry}</span>
                                            </Col>
                                        </React.Fragment>
                                        : ''} */}

                                        <Col md={6} className="padding-right-remove">
                                            <Label>Country:</Label>
                                            <AvField type="select" name="BillingCountry" className="bs" validate={{
                                                required: { value: true, errorMessage: 'Country is required' }
                                            }} disabled onChange={(e) => this.onDDLChange(e)} //value ={user.BillingCountry}
                                                value={billingCountry}
                                            >
                                                <option value='' >--Select Country--</option>
                                                {countryData && countryData.length > 0 ? countryData.map((state) => {
                                                    if (state.Country !== "Scotland" && state.Country !== "Wales" && state.Country !== "Northern Ireland")
                                                        return (
                                                            <option key={state.Country} value={state.CountryCode}>{state.CountryCode === "England" ? 'UK' : state.CountryCode}</option>
                                                        )
                                                }) : ''}
                                            </AvField>
                                        </Col>
                                        {filteredState && filteredState.length > 0 && showState ?
                                            <Col md={6} className="padding-left-remove">
                                                <Label>State:*</Label>
                                                <AvField type="select" name="StateCode" className="bs" validate={{
                                                    required: { value: true, errorMessage: CONFIG.REGION_ID === REGIONS.US ? 'State/Province is required' : 'State is required' }
                                                }} onChange={(e) => this.onDDLChange(e)} value={user.StateCode}>
                                                    <option value='' >{CONFIG.REGION_ID === REGIONS.US ? "--Select State/Province--" : "--Select State--"}</option>
                                                    {filteredState ? filteredState.filter(state => state.StateID !== '').map((state) => {
                                                        return (
                                                            <option key={state.StateID} className={state.StateID === '' ? "optHead disabled-states" : ''} disabled={state.StateID === '' ? true : false} value={state.StateID}>{state.StateCode}</option>
                                                        )
                                                    }) : ''}
                                                </AvField>
                                            </Col>
                                            : ''}
                                        <Col md={6} className={filteredState && filteredState.length > 0 && showState ? 'padding-right-remove' : 'padding-left-remove'}>
                                            <AvField name="BillingZipCode" label={CONFIG.REGION_ID === REGIONS.UK ? "Postcode:" : "Zip Code:"} type="text" maxLength="9"
                                                value={user.BillingZipCode} onChange={(e) => this.onKeyChange(e)}
                                                validate={
                                                    {
                                                        pattern: { value: '^[A-Za-z0-9 ]+$', errorMessage: CONFIG.REGION_ID === REGIONS.UK ? "Postcode is invalid" : "Zip Code is invalid" }
                                                    }
                                                }
                                            />
                                            <span className="errorMessage top-50"> {errors.BillingZipCode}</span>
                                        </Col>
                                        <Col sm={6} className={filteredState && filteredState.length > 0 && showState ? 'padding-left-remove' : 'padding-right-remove'}>
                                            <AvField name="Phone" label="Phone No:*" placeholder={CONFIG.REGION_ID !== REGIONS.UK ? "xxx-xxx-xxxx" : "xxx xxxx xxxx"} type="tel"
                                                value={user.Phone}
                                                maxLength={CONFIG.REGION_ID !== REGIONS.UK ? "12" : "13"}
                                                validate={
                                                    {
                                                        required: { value: true, errorMessage: 'Phone No is required' },
                                                        tel: {
                                                            pattern: CONFIG.REGION_ID !== REGIONS.UK ? /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/ : /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{4}[\s.-]\d{4}$/, errorMessage: 'Phone No is invalid'
                                                        }
                                                    }}
                                                onChange={(e) => this.handlePhoneChange(e)} />
                                        </Col>
                                        {userType === USERTYPES.Admin ?
                                            <Col sm={6} className={filteredState && filteredState.length > 0 && showState ? 'padding-right-remove' : 'padding-left-remove'}>
                                                <AvField type="select" name="BrokerTypeId" value={user.BrokerTypeId}
                                                    onChange={(e) => this.onKeyChange(e)}
                                                    label="User Type:*">
                                                    <option value="0">--Select--</option>
                                                    {userTypes ? userTypes.map(n => {
                                                        return (
                                                            <option key={n.ID} className={n.ID === '' ? "optHead" : ''}
                                                                disabled={n.ID === '' ? true : false}
                                                                value={n.ID}>
                                                                {n.Name}
                                                            </option>
                                                        );
                                                    }) : ''}
                                                </AvField>
                                                <span className="errorMessage error-block-48"> {errors.BrokerTypeId}</span>
                                            </Col> : ''
                                        }
                                    </Row>
                                    {userType === USERTYPES.Admin && user.UserID > 0 ? <Button className="cancle-btn" onClick={this.resetPassword.bind(this)} >Reset password</Button> : ''}
                                    {/* <div>
                                    {userType !== USERTYPES.Admin ? !isChangePassword ? <Button onClick={this.changePassword.bind(this)} className="change-password">Password: <span><FontAwesomeIcon icon={faEdit} /> Change&nbsp;password</span></Button> : '' : user.UserID > 0 ? <Button className="cancle-btn" onClick={this.resetPassword.bind(this)} >Reset password</Button> : ''}
                                    {userType !== USERTYPES.Admin && isChangePassword ?
                                        <React.Fragment>
                                            <Row>
                                                <h2 className="account-sapce">Password</h2>
                                                <div className="box-shadow-account edit-password">

                                                    <Col sm={5} className="account-password">
                                                        <AvField name="Password" label="Create password" placeholder="Create password" type="password"
                                                            value={user.Password} onChange={(e) => this.onKeyChange(e)}
                                                            validate={{
                                                                required: { value: true, errorMessage: 'Password is required' },
                                                                pattern: { value: '^(.{0,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{4,})|(.{1,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{3,})|(.{2,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{2,})|(.{3,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{1,})|(.{4,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{0,})$', errorMessage: 'Passwords are case-sensitive, must be between 6 to 25 characters and contain at least 1 letter and 1 number or special character.' },
                                                                minLength: { value: 6, errorMessage: 'Your Password must be 6 characters' },
                                                                maxLength: { value: 25, errorMessage: 'Your Password must be 25 characters' }
                                                            }} />
                                                    </Col>
                                                    <Col sm={5}>
                                                        <AvField name="ConfirmPassword" label="Confirm password" placeholder="Confirm password" type="password"
                                                            validate={{
                                                                required: { value: true, errorMessage: 'Confirm password is required' },
                                                                match: { value: 'Password', errorMessage: 'Mismatch confirm password' }
                                                            }} />
                                                    </Col>
                                                    <Col sm={2}>
                                                        <Button className="reset-btn margin-t-20 posistion-l-20" onClick={this.cancelChangePassword.bind(this)} >Cancel</Button>
                                                    </Col>
                                                </div>
                                            </Row>
                                        </React.Fragment>
                                        : ''}
                                </div> */}
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Row className="float-right">
                                    <div >
                                        <Button id="btnSave" className="save-btn" type="submit" onMouseDown={() => this.setState({ isSaveclick: 1 })}>Save</Button>
                                        <Button className="reset-btn " onClick={this.toggleModalClose.bind(this)} >Cancel</Button>
                                    </div>
                                </Row>
                            </ModalFooter>
                        </AvForm>
                    </Modal>
                    :
                    <Modal size="md" id="mdSeller" name="mdSeller" backdrop="static" isOpen={isProfileEdit} toggle={this.toggleModalClose.bind(this)} className="vendor-assign-table">
                        <AvForm onSubmit={this.onSubmit.bind(this)} ref={c => (this.formUsers = c)} id="LSS_admin_change_pwd_form">
                            <ModalHeader toggle={this.toggleModalClose.bind(this)}>{user.UserID > 0 ? "Change Password" : ""}</ModalHeader>
                            <ModalBody className="overflow-scroll popup-overflow">
                                <div className="filters-search user-management-form">
                                    <div>
                                        {userType !== USERTYPES.Admin ?
                                            <React.Fragment>
                                                <Row>
                                                    <h2 className="account-sapce">Password</h2>
                                                    <div className="box-shadow-account edit-password">
                                                        <Col sm={5} className="account-password">
                                                            <AvField name="Password" label="Create password" placeholder="Create password" type="password"
                                                                value={user.Password} onChange={(e) => this.onKeyChange(e)}
                                                                validate={{
                                                                    required: { value: true, errorMessage: 'Password is required' },
                                                                    pattern: { value: '^(.{0,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{4,})|(.{1,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{3,})|(.{2,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{2,})|(.{3,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{1,})|(.{4,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{0,})$', errorMessage: 'Passwords are case-sensitive, must be between 6 to 25 characters and contain at least 1 letter and 1 number or special character.' },
                                                                    minLength: { value: 6, errorMessage: 'Your Password must be 6 characters' },
                                                                    maxLength: { value: 25, errorMessage: 'Your Password must be 25 characters' }
                                                                }} />
                                                        </Col>
                                                        <Col sm={5}>
                                                            <AvField name="ConfirmPassword" label="Confirm password" placeholder="Confirm password" type="password"
                                                                validate={{
                                                                    required: { value: true, errorMessage: 'Confirm password is required' },
                                                                    match: { value: 'Password', errorMessage: 'Mismatch confirm password' }
                                                                }} />
                                                        </Col>
                                                    </div>
                                                </Row>
                                            </React.Fragment>
                                            : ''}
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Row className="float-right">
                                    <div >
                                        <Button id="btnSave" className="save-btn" type="submit" onMouseDown={() => this.setState({ isSaveclick: 1 })}>Save</Button>
                                        <Button className="reset-btn " onClick={this.toggleModalClose.bind(this)} >Cancel</Button>
                                    </div>
                                </Row>
                            </ModalFooter>
                        </AvForm>
                    </Modal>
                }
            </div>
        );
    }
}

export default connect()(EditProfile);