import React, { Component } from 'react';
import './FileUpload.css';
import { Button } from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CONFIG } from '../../../Utils/config';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginFileValidateSize);


class FileUpload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showUpload: false,
            file: '',
            PropertyID: props.PropertyID,
            documentId: props.documentId,
            errors: {},
        };
    }

    showPopup = (isshow) => {
        this.setState({ showUpload: isshow });
    }

    processHandler = (fieldName, file, metadata, load, error, progress, abort) => {
        const formData = new FormData();
        formData.append('File', file, file.name);
        formData.append('RegionID', CONFIG.REGION_ID);
        formData.append('PropertyID', this.state.PropertyID);
        if (this.state.documentId !== undefined) {
            formData.append('ID', this.state.documentId);
        }

        const request = new XMLHttpRequest();
        request.responseType = 'json';
        let token = localStorage.getItem('accessKey');
        let reqUrl = 'property/upload/document';
        request.open('POST', CONFIG.API_URL + reqUrl);

        request.upload.onprogress = (e) => {
            progress(e.lengthComputable, e.loaded, e.total);
        };

        request.onload = (e) => {
            if (request.status === 200) {
                load("success");
                this.props.parentMethod(request.response);
            }
            else {
                error('oh no');
            }
        };
        request.onerror = () => {
            error('oh no');
        }

        request.setRequestHeader('Authorization', 'Bearer ' + token);
        request.send(formData);
        return {
            abort: () => {
                request.abort();
                abort();
            }
        };
    }

    handleWarning = (error) => {
        if (error.body === "Max files") {
            alert('Only three files are allowed at one upload');
        }
    };

    render() {
        const { showUpload } = this.state;
        return (
            <div className='reuploaddeletbtn'>
                <Button className={this.props.isNewDoc ? " noopadd desuploadbtn " : "noopadd desreuploadbtn ml-0"} onClick={() => this.showPopup(true)}>{this.props.isNewDoc ? "Upload" : "Reupload"}</Button>
                <div >
                <Dialog
                    open={showUpload}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <div className="dialog-box-image-upload">
                        <DialogTitle id="alert-dialog-title" className="title-popup">
                            Upload
                            <button onClick={() => this.showPopup(false)}>X</button>
                        </DialogTitle>
                    </div>
                    <DialogContent>
                        
                        <DialogContentText id="alert-dialog-description">
                            <div className="previewComponent">

                                <FilePond ref={ref => this.pond = ref}
                                    allowMultiple={this.props.isNewDoc ? true : false}
                                    allowFileTypeValidation={true}
                                    allowImagePreview={false}
                                    allowFileSizeValidation={true}
                                    credits={false}
                                    maxFileSize='15MB'
                                    maxTotalFileSize='45MB'
                                    maxParallelUploads={10}
                                    allowRevert={false}
                                    onwarning={(fileCount) => this.handleWarning(fileCount)}
                                    labelMaxFileSizeExceeded='File is too large'
                                    labelMaxFileSize='Maximum file size is 15MB'
                                    acceptedFileTypes='application/pdf'
                                    labelIdle='  <div class="dragimg"> Drag & Drop your Document <div/> <p class="or"> OR <p/> <span class="browse-link">Browse</span>'
                                    //accept="*"
                                    maxFiles={this.props.isNewDoc ? 3 : 1}
                                    server={{
                                        url: CONFIG.API_URL + 'property/upload/document',
                                        process: this.processHandler
                                    }}
                                >
                                </FilePond>
                                <div className="clear"></div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div >
            </div >
        );
    }
}


export default FileUpload;