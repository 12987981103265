import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Container } from 'reactstrap';
import { Link } from "react-router-dom";
import { CONFIG } from '../../../Utils/config';
import { prepareStringForUrl } from '../../../Utils/utils';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import rightarw from '../../../Assets/Img/vendor-arrow.png';
import VendorAdTile from '../../Shared/VendorAdTile/VendorAdTile';
import { showLoader, hideLoader } from '../../../redux/ducks/application';

import './SearchVendor.css';
import Helmet from '../../../Utils/Helmet';

class SearchVendor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            categoryList: [],
            pageCount: 0,
            expanded: false,
            userInput: '',
            adsList: []
        };
        this.getCategoryList = this.getCategoryList.bind(this);
        this.handleKeyChange = this.handleKeyChange.bind(this);
        this.handleChangeCategory = this.handleChangeCategory.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getCategoryList(0);
        this.getAdsList();
    }

    getAdsList() {
        this.setState({ isLoading: true, error: '' });
        this.props.dispatch(showLoader());

        axios.get(CONFIG.API_URL + 'vendor/ads/' + CONFIG.REGION_ID)
            .then(response => {
                if (response.status === 200) {
                    this.props.dispatch(hideLoader());

                    this.setState({ isLoading: false, adsList: response.data });
                }
            })
            .catch(err => {
                if (err.response != null && err.response.status === 400) {
                    const error = err.response.data;
                    this.setState({ error, isLoading: false });
                }
                else {
                    const error = "Something went wrong.";
                    this.setState({ error, isLoading: false });
                }
            });
    }

    getCategoryList(primaryCategoryId) {
        this.setState({ isLoading: true, error: '' });
        this.props.dispatch(showLoader());

        axios.get(CONFIG.API_URL + 'vendor/category/list/' + primaryCategoryId + '/' + CONFIG.REGION_ID)
            .then(response => {
                if (response.status === 200) {
                    let data = response.data;
                    this.props.dispatch(hideLoader());
                    if (primaryCategoryId === 0) {
                        this.setState({ isLoading: false, categoryList: data, expanded: false });

                    } else {
                        this.setState({ isLoading: false, childCategoryList: data });
                    }
                }
            })
            .catch(err => {
                if (err.response != null && err.response.status === 400) {
                    const error = err.response.data;
                    this.setState({ error, isLoading: false });
                }
                else {
                    const error = "Something went wrong.";
                    this.setState({ error, isLoading: false });
                }

            });
    }

    handleChangeCategory(categoryID) {
        if (categoryID !== this.state.expanded) {
            this.setState({ expanded: categoryID, childCategoryList: undefined });
            this.getCategoryList(categoryID);
        } else {
            this.setState({ expanded: false, childCategoryList: undefined });
        }
    }

    handleKeyChange(e) {
        var userInput = e.currentTarget.value;
        this.setState({ userInput: userInput });
    }

    searchClick() {
        var userInput = this.state.userInput;
        userInput = userInput.trim();

        if (userInput.length >= 2) {
            userInput = userInput.replaceAll("%", "JQ==");
            var urlEncode = encodeURIComponent(userInput.trim());
            const { from } = { from: { pathname: "/vendors/list/" + urlEncode } };
            this.props.history.push(from);
        }
    }

    onKeyPress(e) {
        if (e.key === "Enter") {
            this.searchClick();
        }
    }

    render() {
        // document.title = CONFIG.PAGE_TITLE + 'Vendor Directory';
        const { userInput, categoryList, childCategoryList, expanded, adsList } = this.state;

        return (
            <div className="section-cendordirectory">
                <Helmet
                    title='Vendor Directory'
                    addPostfixTitle={true}
                    keywords="Vendor Directory, Search, Keyword Search, Category"
                />
                <Container fluid>
                    <Row className="padding-t-25">
                        <Col sm={2} className="remove-underline">
                            {
                                adsList.length > 0 ?
                                    <VendorAdTile key={adsList[0].AdvertisementId} ad={adsList[0]} /> : ''
                            }

                            {
                                adsList.length > 1 ?
                                    <VendorAdTile key={adsList[1].AdvertisementId} ad={adsList[1]} /> : ''
                            }

                            {
                                adsList.length > 2 ?
                                    <VendorAdTile key={adsList[2].AdvertisementId} ad={adsList[2]} /> : ''
                            }

                            {
                                adsList.length > 3 ?
                                    <VendorAdTile key={adsList[3].AdvertisementId} ad={adsList[3]} /> : ''
                            }
                        </Col>
                        <Col sm={8}>
                            <Col sm={12} className="page-header margin-b-15">
                                <h2 className="page-title margin-b-15">Vendor Directory</h2>
                                <Row className="padding-t-0">
                                    <Col sm={4}></Col>
                                    <Col sm={4}>
                                        <div className="title-separator1"></div>
                                    </Col>
                                    <Col sm={4}></Col>
                                </Row>
                            </Col>
                            <div className="vendorsearch-box margin-b25">
                                <div className="box-input-search">
                                    <input type="text"
                                        onChange={this.handleKeyChange} onKeyPress={(e) => this.onKeyPress(e)}
                                        value={userInput} className="search-field business user-signin" placeholder="" />
                                    <div><Button onClick={this.searchClick.bind(this)} ><FontAwesomeIcon icon={faSearch} /> Search</Button></div>
                                </div>
                                <p>Use keyword to search or browse categories below</p>
                            </div>

                            <div className="category-vendor">
                                <div className="overflow-scroll-table br-0 category-list">
                                    <Row>
                                        {categoryList ? categoryList.map((record) =>
                                            <Col md={6}>
                                                {record.IsParent ?
                                                    <ExpansionPanel className="expand-category" key={record.CategoryId}>
                                                        <ExpansionPanelSummary className="cate-head"
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls={"panel" + record.CategoryId + "-content"}
                                                            id={"panel" + record.CategoryId + "-header"}
                                                        >
                                                            <Typography ><Link to={"/vendors/list/" + prepareStringForUrl(record.CategoryName) + "/" + record.CategoryId} className="pass-c-signin"><img src={rightarw} alt="Vendor" /> {record.CategoryName}</Link></Typography>
                                                        </ExpansionPanelSummary>
                                                    </ExpansionPanel>
                                                    :
                                                    <ExpansionPanel className="expand-category" expanded={expanded === record.CategoryId} onChange={() => this.handleChangeCategory(record.CategoryId)} key={record.CategoryId}>
                                                        <ExpansionPanelSummary className="cate-head"
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls={"panel" + record.CategoryId + "-content"}
                                                            id={"panel" + record.CategoryId + "-header"}
                                                        >
                                                            <Typography ><img src={rightarw} alt="Vendor" />{record.CategoryName}</Typography>
                                                        </ExpansionPanelSummary>
                                                        {childCategoryList ?
                                                            <ExpansionPanelDetails>
                                                                <Typography>
                                                                    {childCategoryList.length > 0 ? childCategoryList.map((child) =>
                                                                        <Table>
                                                                            <TableRow key={child.CategoryId}>
                                                                                <TableCell><Link to={"/vendors/list/" + prepareStringForUrl(record.CategoryName) + "/" + prepareStringForUrl(child.CategoryName.trim()) + "/" + child.CategoryId} className="pass-c-signin"><FontAwesomeIcon icon={faCaretRight} /> {child.CategoryName}</Link></TableCell>
                                                                            </TableRow>
                                                                        </Table>
                                                                    ) : 'No child categories found.'
                                                                    }
                                                                </Typography>
                                                            </ExpansionPanelDetails>
                                                            : ''}
                                                    </ExpansionPanel>}
                                            </Col>
                                        ) : <span className="novalue">No categories found</span>
                                        }
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col sm={2} className="remove-underline">
                            {
                                adsList.length > 4 ?
                                    <VendorAdTile key={adsList[4].AdvertisementId} ad={adsList[4]} /> : ''
                            }

                            {
                                adsList.length > 5 ?
                                    <VendorAdTile key={adsList[5].AdvertisementId} ad={adsList[5]} /> : ''
                            }

                            {
                                adsList.length > 6 ?
                                    <VendorAdTile key={adsList[6].AdvertisementId} ad={adsList[6]} /> : ''
                            }

                            {
                                adsList.length > 7 ?
                                    <VendorAdTile key={adsList[7].AdvertisementId} ad={adsList[7]} /> : ''
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
const mapStateToProps = state => ({})

export default connect(mapStateToProps)(SearchVendor);
